import classNames from 'classnames';
import {useCallback} from 'react';

export function Link(props) {
    const {
        before = false,
        children,
        className,
        dataTestId,
        icon,
        isActiveLink,
        onClick,
        url,
        ...otherProps
    } = props;

    const classes = classNames('link', className, {
        active: isActiveLink && isActiveLink(url),
        after: icon ? !before : null,
        before: icon ? before : null,
        icon: icon,
        [icon]: icon,
    });

    const onClickHandler = useCallback(
        (event) => {
            if (onClick) {
                onClick(event);
            }
        },
        [onClick]
    );

    return (
        <a
            className = {classes}
            data-test-id = {dataTestId}
            href = {url}
            onClick = {onClickHandler}
            {...otherProps}
        >
            <span>{children}</span>
        </a>
    );
}
