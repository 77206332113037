import {useEffect} from 'react';
import {useIntl} from 'react-intl';
import {
    Route,
    Switch,
    useRouteMatch,
    Redirect,
    useLocation,
} from 'react-router-dom';
import {
    Breakpoint,
    Nav,
} from '../../../denim';
import {userIsAuthenticated} from '../../auth0';
import {
    MenuContent,
    PolicyholderState,
    userHasValidState,
} from '../../common/';
import {LegalFooterSection} from '../../components';
import {usePolicyholderContext} from '../../context';
import {AboutYou} from './about-you';
import {AccountSetup} from './account-setup';
import {Eligibility} from './eligibility';
import {FamilyHistory} from './family-history';
import {GeneticTesting} from './genetic-testing';
import {MedicalHistory} from './medical-history';
import {RegistrationFooter} from './registration-footer';
import {RegistrationProgressBar} from './registration-progress-bar';
import {
    ABOUT_YOU_TOPIC_INDEX,
    RegistrationContext,
    useRegistrationContext,
    useRegistrationProgress,
} from './RegistrationContext';
import {ShippingDetails} from './shipping-details';
import {StudyConsent} from './study-consent';
import {Summary} from './summary';
import {Welcome} from './welcome';

const registrationGuard = (Component) => (props) => {
    const {state: {policyholder: {state} = {}} = {}} = usePolicyholderContext();
    const {currentTopic, currentStep} = useRegistrationProgress();
    const {state: {flow} = []} = useRegistrationContext();

    const currentTopicIndex = flow.findIndex((topic) => topic.key === currentTopic);
    let transitionToTopic = currentTopic;

    if (!flow[currentTopicIndex].completed) {
        let lastCompletedTopicIndex = -1;

        let index = flow.length - 1;
        while (index >= 0) {
            if (flow[index].completed) {
                lastCompletedTopicIndex = index;
                break;
            }
            index--;
        }

        if (lastCompletedTopicIndex < ABOUT_YOU_TOPIC_INDEX()) {
            transitionToTopic = '/registration/about-you';
        }
        else {
            // Go to the next flow step after the lastCompletedTopic
            transitionToTopic = flow[lastCompletedTopicIndex + 1].key;
        }
    }

    const isOnSecondOrderSummaryPage = currentTopic === '/registration/summary' && currentStep === 2;
    if (isOnSecondOrderSummaryPage) {
        transitionToTopic = currentTopic;
    }
    else if (state === PolicyholderState.DETAILS_SUBMITTED) {
        return (
            <Redirect
                to = {{
                    pathname: '/registration/summary',
                    state: {currentStep: 2},
                }}
            />
        );
    }

    if (currentTopic !== transitionToTopic) {
        return (
            <Redirect
                push = {true}
                to = {transitionToTopic}
            />
        );
    }

    return <Component {...props} />;
};

export function Registration(props) {
    const {location: {state: {skipUserStateCheck} = {}} = {}} = props;

    const intl = useIntl();
    const {pathname} = useLocation();
    const {currentTopic, currentStep} = useRegistrationProgress();
    const {path} = useRouteMatch();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [currentTopic, currentStep]);

    const progressBar = (
        <RegistrationProgressBar
            backLinkAriaLabel = {intl.formatMessage({id: 'registration.common.link.back.text'})}
            breakpoints = {[Breakpoint.TABLET_PORTRAIT, Breakpoint.PHONE]}
            closeLinkAriaLabel = {intl.formatMessage({id: 'common.close.label'})}
        />
    );

    return (
        <RegistrationContext>
            <header
                className = {'page-header'}
                role = {'banner'}
            >
                <Nav
                    className = {'progress-bar'}
                    closeLinkAriaLabel = {intl.formatMessage({id: 'common.close.label'})}
                    footerContent = {<LegalFooterSection />}
                    openLinkAriaLabel = {intl.formatMessage({id: 'common.open.label'})}
                    progressBar = {progressBar}
                >
                    <MenuContent />
                </Nav>
            </header>
            <section
                className = {'page-content grid registration'}
                role = {'main'}
            >
                <RegistrationProgressBar
                    breakpoints = {[Breakpoint.DESKTOP, Breakpoint.TABLET_LANDSCAPE]}
                    className = {'progress'}
                />
                <Switch>
                    <Route
                        component = {Welcome}
                        path = {`${path}/welcome`}
                    />
                    <Route
                        component = {Eligibility}
                        path = {`${path}/eligibility`}
                    />
                    <Route
                        component = {AccountSetup}
                        path = {`${path}/account-setup`}
                    />
                    <Route
                        component = {userIsAuthenticated(
                            userHasValidState(StudyConsent, [PolicyholderState.REGISTERED], {skipUserStateCheck})
                        )}
                        path = {`${path}/study-consent`}
                    />
                    <Route
                        component = {userIsAuthenticated(
                            userHasValidState(
                                registrationGuard(AboutYou),
                                [PolicyholderState.CONSENT_SIGNED],
                                {skipUserStateCheck}
                            )
                        )}
                        path = {`${path}/about-you`}
                    />
                    <Route
                        component = {userIsAuthenticated(
                            userHasValidState(
                                registrationGuard(MedicalHistory),
                                [PolicyholderState.CONSENT_SIGNED],
                                {skipUserStateCheck}
                            )
                        )}
                        path = {`${path}/medical-history`}
                    />
                    <Route
                        component = {userIsAuthenticated(
                            userHasValidState(
                                registrationGuard(FamilyHistory),
                                [PolicyholderState.CONSENT_SIGNED],
                                {skipUserStateCheck}
                            )
                        )}
                        path = {`${path}/family-history`}
                    />
                    <Route
                        component = {userIsAuthenticated(
                            userHasValidState(
                                registrationGuard(GeneticTesting),
                                [PolicyholderState.CONSENT_SIGNED],
                                {skipUserStateCheck}
                            )
                        )}
                        path = {`${path}/genetic-testing`}
                    />
                    <Route
                        component = {userIsAuthenticated(
                            userHasValidState(
                                registrationGuard(ShippingDetails),
                                [PolicyholderState.CONSENT_SIGNED],
                                {skipUserStateCheck}
                            )
                        )}
                        path = {`${path}/shipping-details`}
                    />
                    <Route
                        component = {userIsAuthenticated(
                            userHasValidState(
                                registrationGuard(Summary),
                                [PolicyholderState.CONSENT_SIGNED],
                                {skipUserStateCheck}
                            )
                        )}
                        path = {`${path}/summary`}
                    />
                    <Route>
                        <Redirect
                            push = {true}
                            to = {{
                                pathname: '/not-found',
                                state: {referrer: pathname},
                            }}
                        />
                    </Route>
                </Switch>
                <RegistrationFooter />
            </section>
        </RegistrationContext>
    );
}
