import classNames from 'classnames';
import {
    Card,
    Variant,
} from '../../../denim';

export function GridContainer(props) {
    const {
        children,
        containerClassName,
        containerDataTestId,
        gridClassName,
        gridDataTestId,
        role,
        variant = Variant.NONE,
    } = props;

    let sanitisedVariant = variant;
    if (![Variant.LIGHT, Variant.NONE].includes(variant)) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error('Nav only supports Variant.LIGHT, Variant.DARK or Variant.NONE.');
        }
        sanitisedVariant = Variant.NONE;
    }

    const containerClassNames = classNames('grid-container', sanitisedVariant, containerClassName);
    const gridClassNames = classNames('grid', gridClassName);

    return (
        <Card
            className = {containerClassNames}
            dataTestId = {containerDataTestId}
            role = {role}
        >
            <div
                className = {gridClassNames}
                data-test-id = {gridDataTestId}
            >
                {children}
            </div>
        </Card>
    );
}
