import {
    useCallback,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Button,
    Link,
    TextInput,
} from '../../../../denim';
import {
    action,
    UPDATE_VALUE,
} from '../../../common';
import {Divider} from '../../../components';
import {useRegistrationContext} from '../RegistrationContext';

const SET_FIRST_NAME_ERROR_MESSAGE = 'SET_FIRST_NAME_ERROR_MESSAGE';
const SET_LAST_NAME_ERROR_MESSAGE = 'SET_LAST_NAME_ERROR_MESSAGE';

const init = ({firstName, lastName}) => ({
    firstName: firstName,
    firstNameErrorMessage: null,
    lastName: lastName,
    lastNameErrorMessage: null,
});

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_FIRST_NAME_ERROR_MESSAGE:
            return {
                ...state,
                firstNameErrorMessage: payload,
            };

        case SET_LAST_NAME_ERROR_MESSAGE:
            return {
                ...state,
                lastNameErrorMessage: payload,
            };

        case UPDATE_VALUE:
            return {
                ...state,
                [payload.name]: payload.value,
            };

        default:
            return state;
    }
};

export function FullName() {
    const intl = useIntl();
    const {
        nextStep,
        previousStep,
        state: {data: {aboutYou = {}} = {}} = {},
        updateAboutYouField,
    } = useRegistrationContext();
    const [{firstName, firstNameErrorMessage, lastName, lastNameErrorMessage}, dispatch] = useReducer(
        reducer,
        aboutYou,
        init
    );

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const onBlurHandler = useCallback((value, name) => {
        let errorMessageToDisplay = intl.formatMessage({id: 'registration.common.error.required.text'});

        if (value && !/^[^0-9]*$/m.test(value)) {
            errorMessageToDisplay = intl.formatMessage({id: 'registration.common.name.error.incorrectFormat.text'});
        }

        if (name === 'firstName') {
            dispatch(action(SET_FIRST_NAME_ERROR_MESSAGE, errorMessageToDisplay));
        }
        else {
            dispatch(action(SET_LAST_NAME_ERROR_MESSAGE, errorMessageToDisplay));
        }
    }, []);

    const onChangeHandler = useCallback((value, name) => {
        dispatch(
            action(UPDATE_VALUE, {
                name,
                value,
            })
        );
    }, []);

    const onSubmitHandler = useCallback(
        (event) => {
            event.preventDefault();

            // Check validity cause an invalid event to be triggered on anything that is invalid, which will cause them
            // to display their error state.
            const form = event.target;
            const formValid = form.checkValidity();

            if (formValid) {
                updateAboutYouField('firstName', firstName);
                updateAboutYouField('lastName', lastName);
                nextStep();
            }
            else {
                // Change displayed error message based on type of error (empty string or invalid format)
                dispatch(
                    action(
                        SET_FIRST_NAME_ERROR_MESSAGE,
                        firstName
                            ? intl.formatMessage({id: 'registration.common.name.error.incorrectFormat.text'})
                            : intl.formatMessage({id: 'registration.common.error.required.text'})
                    )
                );

                // Change displayed error message based on type of error (empty string or invalid format)
                dispatch(
                    action(
                        SET_LAST_NAME_ERROR_MESSAGE,
                        lastName
                            ? intl.formatMessage({id: 'registration.common.name.error.incorrectFormat.text'})
                            : intl.formatMessage({id: 'registration.common.error.required.text'})
                    )
                );
            }
        },
        [firstName, lastName]
    );

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage id = {'registration.aboutYou.name.question.text'} />
                </h2>
            </div>
            <Divider />
            <div className = {'question-options text-based-input'}>
                <form
                    noValidate = {true}
                    onSubmit = {onSubmitHandler}
                >
                    <TextInput
                        dataTestId = {'firstname-input'}
                        errorMessage = {firstNameErrorMessage}
                        id = {'firstName'}
                        label = {intl.formatMessage({id: 'registration.common.name.firstName.label'})}
                        name = {'firstName'}
                        onBlur = {onBlurHandler}
                        onChange = {onChangeHandler}
                        pattern = {'[^0-9]*'}
                        required = {true}
                        value = {firstName}
                    />
                    <TextInput
                        dataTestId = {'lastname-input'}
                        errorMessage = {lastNameErrorMessage}
                        id = {'lastName'}
                        label = {intl.formatMessage({id: 'registration.common.name.lastName.label'})}
                        name = {'lastName'}
                        onBlur = {onBlurHandler}
                        onChange = {onChangeHandler}
                        pattern = {'[^0-9]*'}
                        required = {true}
                        value = {lastName}
                    />
                    <Button
                        dataTestId = {'continue-button'}
                        submit = {true}
                    >
                        <FormattedMessage id = {'registration.common.button.continue.text'} />
                    </Button>
                </form>
            </div>
        </div>
    );
}
