import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Footer} from '../../../denim';
import {
    GridContainer,
    LegalFooterSection,
} from '../../components';

export function LandingPageFooter(props) {
    const {hasFootnote} = props;

    const intl = useIntl();

    return (
        <Footer>
            {hasFootnote && (
                <GridContainer containerClassName = {'footnote'}>
                    <p className = {'is-caption'}>
                        <FormattedMessage id = {'landingPage.footer.footnote.text'} />
                    </p>
                </GridContainer>
            )}
            <nav
                aria-label = {intl.formatMessage({id: 'common.footer.label'})}
                className = {'grid main-footer-content'}
            >
                <LegalFooterSection />
            </nav>
        </Footer>
    );
}
