import {
    useCallback,
    useEffect,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {
    Button,
    ErrorCard,
    Link,
    TextInput,
} from '../../../../denim';
import {useApi} from '../../../auth0';
import {
    action,
    UPDATE_VALUE,
} from '../../../common';
import {Divider} from '../../../components';
import {useMinervaConfig} from '../../../config';
import {useRegistrationContext} from '../RegistrationContext';

const ERROR_RAD_REQUEST = 'ERROR_RAD_REQUEST';
const ERROR_INVALID = 'ERROR_INVALID';
const ERROR_UNVALIDATED = 'ERROR_UNVALIDATED';
const ERROR_SERVER_ERROR = 'ERROR_SERVER_ERROR';
const HTTP_BAD_REQUEST = 400;
const SET_ERROR = 'SET_ERROR';
const SET_IS_LOADING = 'SET_IS_LOADING';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_ERROR:
            return {
                ...state,
                hasError: payload,
                isLoading: false,
            };

        case SET_IS_LOADING:
            return {
                ...state,
                hasError: false,
                isLoading: payload,
            };

        case UPDATE_VALUE:
            return {
                ...state,
                shippingAddress: {
                    ...state.shippingAddress,
                    [payload.name]: payload.value,
                },
            };

        default:
            return state;
    }
};

const init = (shippingAddress = {}) => ({
    hasError: null,
    isLoading: false,
    shippingAddress: shippingAddress,
});

export function ManualAddress() {
    const [authenticatedFetch] = useApi();
    const intl = useIntl();
    const {state: {addressAutoCompleted} = {}} = useLocation();
    const {servicesBackend} = useMinervaConfig();
    const {
        previousStep,
        nextStep,
        state: {data: {aboutYou: {firstName, lastName} = {}, shippingDetails = {}} = {}},
        updateShippingDetailsField,
    } = useRegistrationContext();
    const [reducerState, dispatch] = useReducer(reducer, shippingDetails, init);

    const {
        hasError,
        isLoading,
        shippingAddress: {apartment, city, recipientName, state, street, zipCode},
    } = reducerState;

    useEffect(() => {
        if (!recipientName) {
            const fullName = firstName && lastName ? `${firstName} ${lastName}` : '';
            dispatch(
                action(UPDATE_VALUE, {
                    name: 'recipientName',
                    value: fullName,
                })
            );
        }
    }, []);

    const goToPreviousStep = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const handleInputChange = useCallback((value, name) => {
        dispatch(
            action(UPDATE_VALUE, {
                name,
                value,
            })
        );
    }, []);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            const form = event.target;
            const formValid = form.checkValidity();

            if (formValid) {
                try {
                    dispatch(action(SET_IS_LOADING, true));
                    /* eslint-disable camelcase */
                    const response = await authenticatedFetch(
                        `${servicesBackend}/validation/address`,
                        {
                            body: JSON.stringify({
                                apartment_suite_building: apartment,
                                city: city,
                                recipient: recipientName,
                                state: state,
                                street_address: street,
                                zip_code: zipCode,
                            }),
                            method: 'POST',
                        }
                    );

                    if (response.ok) {
                        const {address_validated: addressValidated, address_valid: addressValid} =
                            await response.json();

                        if (addressValidated && addressValid) {
                            Object.entries(reducerState.shippingAddress).forEach(([key, val]) => {
                                updateShippingDetailsField(key, val);
                            });

                            nextStep({complete: true});
                        }
                        // It's most likely that SmartyStreets couldn't validate the address...
                        else if (!addressValidated) {
                            dispatch(action(SET_ERROR, ERROR_UNVALIDATED));
                        }
                        // The address didn't match or rules, i.e. it's maybe a business, or on a military base, etc...
                        else if (!addressValid) {
                            dispatch(action(SET_ERROR, ERROR_INVALID));
                        }
                    }
                    else {
                        dispatch(
                            action(
                                SET_ERROR,
                                response.status === HTTP_BAD_REQUEST
                                    ? ERROR_RAD_REQUEST
                                    : ERROR_SERVER_ERROR
                            )
                        );
                    }
                    /* eslint-enable camelcase */
                }
                catch (error) {
                    dispatch(action(SET_ERROR, ERROR_SERVER_ERROR));
                }
            }
        },
        [apartment, city, recipientName, state, street, zipCode]
    );

    const mode = addressAutoCompleted ? 'CONFIRM' : 'ENTER';

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {goToPreviousStep}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage
                        id = {`registration.shippingDetails.manualAddress.question.text.${mode}`}
                    />
                </h2>
            </div>
            <Divider />
            <div className = {'question-options'}>
                {hasError && (
                    <ErrorCard
                        dataTestId = {'shipping-address-error'}
                        title = {intl.formatMessage({id: `registration.shippingDetails.manualAddress.error.${hasError}.title`})}
                    >
                        <p>
                            <FormattedMessage
                                id = {`registration.shippingDetails.manualAddress.error.${hasError}.description`}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {`registration.shippingDetails.manualAddress.error.${hasError}.support`}
                                values = {{
                                    link: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'support.email'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                    </ErrorCard>
                )}
                <form
                    noValidate = {true}
                    onSubmit = {onSubmitHandler}
                >
                    <TextInput
                        dataTestId = {'recipientName-input'}
                        errorMessage = {intl.formatMessage({id: 'registration.common.error.required.text'})}
                        id = {'recipientName'}
                        label = {intl.formatMessage({id: 'registration.shippingDetails.manualAddress.recipientName.label'})}
                        name = {'recipientName'}
                        onChange = {handleInputChange}
                        required = {true}
                        value = {recipientName}
                    />
                    <TextInput
                        dataTestId = {'street-input'}
                        errorMessage = {intl.formatMessage({id: 'registration.common.error.required.text'})}
                        id = {'street'}
                        label = {intl.formatMessage({id: 'registration.shippingDetails.manualAddress.street.label'})}
                        name = {'street'}
                        onChange = {handleInputChange}
                        required = {true}
                        value = {street}
                    />
                    <TextInput
                        dataTestId = {'apartment-input'}
                        errorMessage = {intl.formatMessage({id: 'registration.common.error.required.text'})}
                        id = {'apartment'}
                        label = {intl.formatMessage({id: 'registration.shippingDetails.manualAddress.apartment.label'})}
                        name = {'apartment'}
                        onChange = {handleInputChange}
                        value = {apartment}
                    />
                    <TextInput
                        dataTestId = {'city-input'}
                        errorMessage = {intl.formatMessage({id: 'registration.common.error.required.text'})}
                        id = {'city'}
                        label = {intl.formatMessage({id: 'registration.shippingDetails.manualAddress.city.label'})}
                        name = {'city'}
                        onChange = {handleInputChange}
                        required = {true}
                        value = {city}
                    />
                    <div className = {'inline-form-group'}>
                        <TextInput
                            dataTestId = {'state-input'}
                            errorMessage = {intl.formatMessage({id: 'registration.common.error.required.text'})}
                            id = {'state'}
                            label = {intl.formatMessage({id: 'registration.shippingDetails.manualAddress.state.label'})}
                            name = {'state'}
                            onChange = {handleInputChange}
                            required = {true}
                            value = {state}
                        />
                        <TextInput
                            dataTestId = {'zipCode-input'}
                            errorMessage = {intl.formatMessage({id: 'registration.common.error.required.text'})}
                            id = {'zipCode'}
                            label = {intl.formatMessage({id: 'registration.shippingDetails.manualAddress.zipCode.label'})}
                            name = {'zipCode'}
                            onChange = {handleInputChange}
                            required = {true}
                            value = {zipCode}
                        />
                    </div>
                    <Button
                        dataTestId = {'continue-button'}
                        isLoading = {isLoading}
                        submit = {true}
                    >
                        <FormattedMessage id = {'registration.common.button.continue.text'} />
                    </Button>
                </form>
            </div>
        </div>
    );
}
