import {
    forwardRef,
    useRef,
    useImperativeHandle,
} from 'react';
import {useIntl} from 'react-intl';

export const Step = forwardRef((props, ref) => {
    const {
        dataTestId,
        description,
        imageSource,
        index,
        optional = false,
        title,
    } = props;

    const intl = useIntl();

    const stepEl = useRef(null);
    const stepContainerEl = useRef(null);

    useImperativeHandle(ref, () => ({
        step: stepEl.current,
        stepContainer: stepContainerEl.current,
    }));

    return (
        <div
            className = {`step-${index}`}
            data-test-id = {dataTestId}
            ref = {stepContainerEl}
        >
            <img
                role = {'presentation'}
                src = {imageSource}
            />

            <div
                className = {`step`}
                ref = {stepEl}
            >
                <div className = {'number is-eyebrow is-small'}>
                    {`${intl.formatMessage({id: 'landingPage.whatToExpect.step.text'})} ${index}`}
                    {optional && (
                        <span className = {'optional'}>
                            {`${intl.formatMessage({id: 'landingPage.whatToExpect.step.optional.text'})}`}
                        </span>
                    )}
                </div>
                {title && <h3 className = {'title'}>{title}</h3>}
                {description && <div className = {'description-step'}>{description}</div>}
            </div>
        </div>
    );
});
