import {
    useCallback,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom';
import {
    Button,
    DateInput,
    ErrorCard,
    Link,
    RadioButtonGroup,
    RadioButton,
} from '../../../../denim';
import {useApi} from '../../../auth0';
import {
    action,
    KIT_REGISTRATION_STORAGE_KEY,
    UPDATE_VALUE,
    useStore,
} from '../../../common';
import {Divider} from '../../../components';
import {useMinervaConfig} from '../../../config';
import {sampleIdPrefix} from './SampleId';

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/PluralRules/PluralRules
const formatOrdinals = (number) => {
    const pr = new Intl.PluralRules('en-US', {type: 'ordinal'});
    const suffixes = new Map([
        ['one', 'st'],
        ['two', 'nd'],
        ['few', 'rd'],
        ['other', 'th'],
    ]);

    const rule = pr.select(number);
    const suffix = suffixes.get(rule);
    return `${number}${suffix}`;
};

const SET_RADIO_GROUP_ERROR_MESSAGE = 'SET_RADIO_GROUP_ERROR_MESSAGE';
const SET_SAMPLE_COLLECTED = 'SET_SAMPLE_COLLECTED';
const SET_IS_LOADING = 'SET_IS_LOADING';
const SET_ERROR = 'SET_ERROR';

const errorTypes = {KIT_REGISTRATION: 'KIT_REGISTRATION'};

const defaultSampleCollected = {
    day: '',
    month: '',
    year: '',
};

const init = ({initialDateSelection = '', initialSampleCollected = defaultSampleCollected}) => {
    const ONE_DAY_IN_MILLISECONDS = 86400000;

    const today = new Date();
    const yesterday = new Date(today.getTime() - ONE_DAY_IN_MILLISECONDS);

    return {
        dateSelection: initialDateSelection,
        errorType: null,
        isLoading: false,
        radioGroupErrorMessage: null,
        sampleCollected: initialSampleCollected,
        today: today,
        yesterday: yesterday,
    };
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_VALUE:
            return {
                ...state,
                dateSelection: payload,
            };

        case SET_SAMPLE_COLLECTED:
            return {
                ...state,
                sampleCollected: payload,
            };

        case SET_RADIO_GROUP_ERROR_MESSAGE:
            return {
                ...state,
                radioGroupErrorMessage: payload,
            };

        case SET_ERROR:
            return {
                ...state,
                errorType: payload,
                isLoading: false,
            };

        case SET_IS_LOADING:
            return {
                ...state,
                errorType: null,
                isLoading: payload,
            };

        default:
            return state;
    }
};

export function SampleCollectionDate(props) {
    const {
        kitRegistrationData: {
            dateSelection: initialDateSelection,
            sampleCollected: initialSampleCollected,
            sampleId,
        } = {},
        updateKitRegistrationData,
    } = props;

    const intl = useIntl();
    const history = useHistory();
    const {path} = useRouteMatch();

    const [authenticatedFetch] = useApi();
    const minervaConfig = useMinervaConfig();
    const store = useStore();

    const [
        {
            dateSelection,
            errorType,
            isLoading,
            radioGroupErrorMessage,
            sampleCollected,
            today,
            yesterday,
        },
        dispatch,
    ] = useReducer(
        reducer,
        {
            initialDateSelection,
            initialSampleCollected,
        },
        init
    );

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        history.push(path, {
            currentStep: 1,
            registering: true,
        });
    });

    const getSampleCollectedDateFromSelection = (selection) => {
        let selectedDate = today;

        if (selection === 'YESTERDAY') {
            selectedDate = yesterday;
        }

        const LAST_TWO_CHARS = -2;
        const day = `0${selectedDate.getDate()}`.slice(LAST_TWO_CHARS);
        const month = `0${selectedDate.getMonth() + 1}`.slice(LAST_TWO_CHARS);
        const year = `${selectedDate.getFullYear()}`;

        return {
            day,
            month,
            year,
        };
    };

    const onChangeHandler = useCallback((value) => {
        dispatch(action(UPDATE_VALUE, value));
        dispatch(action(SET_RADIO_GROUP_ERROR_MESSAGE, null));

        updateKitRegistrationData({dateSelection: value});

        let sampleCollectionDate = defaultSampleCollected;
        if (value !== 'DIFFERENT_DAY') {
            sampleCollectionDate = getSampleCollectedDateFromSelection(value);
        }
        dispatch(action(SET_SAMPLE_COLLECTED, sampleCollectionDate));
        updateKitRegistrationData({sampleCollected: sampleCollectionDate});
    }, []);

    const onSampleCollectedChangeHandler = useCallback((value) => {
        dispatch(action(SET_SAMPLE_COLLECTED, value));
        updateKitRegistrationData({sampleCollected: value});
    }, []);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            const form = event.target;
            const formValid = form.checkValidity();

            if (formValid) {
                dispatch(action(SET_IS_LOADING, true));
                const [numericPart] = sampleId.match(/\d+/);

                const {
                    day,
                    month,
                    year,
                } = sampleCollected;

                try {
                    const response = await authenticatedFetch(
                        `${minervaConfig.servicesBackend}/policyholders/me/kits`,
                        {
                            body: JSON.stringify({
                                // eslint-disable-next-line camelcase
                                sample_collected: `${year}-${month}-${day}`,
                                // eslint-disable-next-line camelcase
                                sample_id: `${sampleIdPrefix}${numericPart}`,
                            }),
                            method: 'POST',
                        }
                    );

                    if (response.ok) {
                        // cleanup
                        store.remove(KIT_REGISTRATION_STORAGE_KEY);
                        history.push(`/dashboard/kit-registered`, {skipUserStateCheck: true});
                    }
                    else {
                        dispatch(action(SET_ERROR, errorTypes.KIT_REGISTRATION));
                    }
                }
                catch (error) {
                    if (process.env.NODE_ENV === 'development') {
                        // eslint-disable-next-line no-console
                        console.log(error);
                    }
                    dispatch(action(SET_ERROR, errorTypes.KIT_REGISTRATION));
                }
            }
            else {
                if (!dateSelection) {
                    dispatch(
                        action(
                            SET_RADIO_GROUP_ERROR_MESSAGE,
                            intl.formatMessage({id: 'registration.common.error.required-options.text'})
                        )
                    );
                }
            }
        },
        [sampleCollected]
    );

    const getDayAndMonth = (date) => {
        const [month, , day] = intl.formatDateToParts(date, {
            day: 'numeric',
            month: 'long',
        });

        return {
            day: formatOrdinals(day.value),
            month: month.value,
        };
    };

    const yesterdayFormatted = getDayAndMonth(yesterday);
    const todayFormatted = getDayAndMonth(today);

    return (
        <>
            <div
                className = {'content'}
                data-test-id = {'register-your-kit'}
            >
                <Link
                    className = {'cancel-link icon before chevron--left'}
                    dataTestId = {'register-your-kit-cancel-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'dashboard.registerYourKit.link.back.text'} />
                </Link>
                <p
                    className = {'is-eyebrow is-caption no-margin'}
                    data-test-id = {'current-step-text'}
                >
                    <FormattedMessage
                        id = {'dashboard.registerYourKit.progress.text'}
                        values = {{
                            currentStep: 2,
                            totalSteps: 2,
                        }}
                    />
                </p>
                <h2 data-test-id = {'register-your-kit-default-content'}>
                    <FormattedMessage id = {'dashboard.registerYourKit.sampleCollected.title.text'} />
                </h2>
                <Divider />
                {errorType && (
                    <ErrorCard
                        dataTestId = {'register-your-kit-communication-error'}
                        title = {intl.formatMessage({id: `dashboard.registerYourKit.error.${errorType}.title`})}
                    >
                        <p>
                            <FormattedMessage
                                id = {`dashboard.registerYourKit.error.${errorType}.description`}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {`dashboard.registerYourKit.error.${errorType}.support`}
                                values = {{
                                    link: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'support.email'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                    </ErrorCard>
                )}
                <div className = {'text-based-input'}>
                    <form
                        noValidate = {true}
                        onSubmit = {onSubmitHandler}
                    >
                        <RadioButtonGroup
                            errorMessage = {radioGroupErrorMessage}
                            name = {'dateSelection'}
                            required = {true}
                            value = {dateSelection}
                        >
                            <RadioButton
                                dataTestId = {'dateSelection-today'}
                                id = {'dateSelection-radio-button-today'}
                                key = {'dateSelection-radio-button-today'}
                                label = {intl.formatMessage(
                                    {id: 'dashboard.registerYourKit.sampleCollected.option.today.text'},
                                    todayFormatted
                                )}
                                onChange = {onChangeHandler}
                                value = {'TODAY'}
                            />
                            <RadioButton
                                dataTestId = {'dateSelection-yesterday'}
                                id = {'dateSelection-radio-button-yesterday'}
                                key = {'dateSelection-radio-button-yesterday'}
                                label = {intl.formatMessage(
                                    {id: 'dashboard.registerYourKit.sampleCollected.option.yesterday.text'},
                                    yesterdayFormatted
                                )}
                                onChange = {onChangeHandler}
                                value = {'YESTERDAY'}
                            />
                            <div className = {'is-caption option-divider'}>
                                <FormattedMessage id = {'common.or.text'} />
                            </div>
                            <RadioButton
                                dataTestId = {'dateSelection-different-day'}
                                id = {'dateSelection-radio-button-different-day'}
                                key = {'dateSelection-radio-button-different-day'}
                                label = {intl.formatMessage({id: 'dashboard.registerYourKit.sampleCollected.option.differentDay.text'})}
                                onChange = {onChangeHandler}
                                value = {'DIFFERENT_DAY'}
                            />
                        </RadioButtonGroup>
                        {dateSelection === 'DIFFERENT_DAY' && (
                            <DateInput
                                className = {'conditional-reveal'}
                                dataTestId = {'register-your-kit-sample-collected'}
                                labelForDay = {intl.formatMessage({id: 'dashboard.registerYourKit.sampleCollected.day.label'})}
                                labelForMonth = {intl.formatMessage({id: 'dashboard.registerYourKit.sampleCollected.month.label'})}
                                labelForYear = {intl.formatMessage({id: 'dashboard.registerYourKit.sampleCollected.year.label'})}
                                name = {'sampleCollected'}
                                onChange = {onSampleCollectedChangeHandler}
                                required = {true}
                                value = {sampleCollected}
                            />
                        )}
                        <Button
                            dataTestId = {'register-your-kit-continue-button'}
                            isLoading = {isLoading}
                            submit = {true}
                        >
                            <FormattedMessage id = {'dashboard.registerYourKit.button.text'} />
                        </Button>
                    </form>
                </div>
            </div>
            <div className = {'graphic'}>
                <img
                    alt = {intl.formatMessage({id: 'dashboard.registerYourKit.calendar.image.altText'})}
                    src = {'/images/test-kit-calendar-illustration.svg'}
                />
            </div>
        </>
    );
}
