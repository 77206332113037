import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Link,
    Nav,
    Variant,
} from '../../../denim';
import {MenuContent} from '../../common';
import {LegalFooterSection} from '../../components';
import {DashboardFooter} from '../dashboard/dashboard-footer';

export function Withdrawn() {
    const intl = useIntl();

    return (
        <div className = {'page-layout'}>
            <header
                className = {'page-header'}
                role = {'banner'}
            >
                <Nav
                    closeLinkAriaLabel = {intl.formatMessage({id: 'common.close.label'})}
                    footerContent = {<LegalFooterSection />}
                    openLinkAriaLabel = {intl.formatMessage({id: 'common.open.label'})}
                    variant = {Variant.DARK}
                >
                    <MenuContent />
                </Nav>
            </header>
            <section
                className = {'page-content grid dashboard withdrawn'}
                role = {'main'}
            >
                <div className = {'content'}>
                    <p className = {'is-eyebrow'}>
                        <FormattedMessage id = {'withdrawn.eyebrow.text'} />
                    </p>
                    <h1 data-test-id = {'withdrawn'}>
                        <FormattedMessage id = {'withdrawn.title.text'} />
                    </h1>
                    <p>
                        <FormattedMessage
                            id = {'withdrawn.description.text'}
                            values = {{
                                link: (chunks) => (
                                    <Link
                                        className = {'contact-link'}
                                        rel = {'noreferrer'}
                                        target = {'_blank'}
                                        url = {intl.formatMessage({id: 'support.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                                phone: (chunks) => (
                                    <Link
                                        className = {'contact-link'}
                                        rel = {'noreferrer'}
                                        target = {'_blank'}
                                        url = {intl.formatMessage({id: 'contactStudyCoordinator.phone'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </div>
                <div className = {'graphic'}>
                    <img
                        alt = {intl.formatMessage({id: 'withdrawn.image.altText'})}
                        role = {'presentation'}
                        src = {'/images/square-and-circle.svg'}
                    />
                </div>
                <DashboardFooter />
            </section>
        </div>
    );
}
