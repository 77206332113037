export class Intent {
    static NONE = 'none';

    static PRIMARY = 'primary';

    static INFO = 'info';

    static SUCCESS = 'success';

    static WARNING = 'warning';

    static DANGER = 'danger';
}
