import {
    useCallback,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Button,
    ErrorCard,
    Link,
} from '../../../../denim';
import {useApi} from '../../../auth0';
import {action} from '../../../common';
import {Divider} from '../../../components';
import {useMinervaConfig} from '../../../config';
import {usePolicyholderContext} from '../../../context';
import {useRegistrationContext} from '../RegistrationContext';
import {AboutYouQuestions} from './AboutYouQuestions';
import {FamilyHistoryQuestions} from './FamilyHistoryQuestions';
import {MedicalHistoryQuestions} from './MedicalHistoryQuestions';
import {PriorGeneticTesting} from './PriorGeneticTestingQuestions';

const UPDATE_IS_COMMUNICATING = 'UPDATE_IS_COMMUNICATING';
const UPDATE_ERROR = 'UPDATE_ERROR';

const initialState = {
    error: null,
    isCommunicating: false,
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_IS_COMMUNICATING:
            return {
                ...state,
                error: null,
                isCommunicating: payload,
            };

        case UPDATE_ERROR:
            return {
                ...state,
                error: payload,
                isCommunicating: false,
            };

        default:
            return state;
    }
};

/* eslint-disable camelcase */
const constructRequestBody = (data = {}) => {
    const {
        aboutYou,
        medicalHistory,
        familyHistory,
        geneticTesting,
        shippingDetails,
    } = data;

    const requestBody = {};

    // Add the About You section details...
    requestBody.first_name = aboutYou.firstName;
    requestBody.last_name = aboutYou.lastName;
    requestBody.contact_number = `+1 ${aboutYou.contactNumber}`;
    requestBody.residential_address = {
        apartment_suite_building: aboutYou.homeAddress.apartment,
        city: aboutYou.homeAddress.city,
        state: aboutYou.homeAddress.state,
        street_address: aboutYou.homeAddress.street,
        zip_code: aboutYou.homeAddress.zipCode,
    };
    requestBody.date_of_birth = `${aboutYou.dateOfBirth.year}-${aboutYou.dateOfBirth.month}-${aboutYou.dateOfBirth.day}`;
    requestBody.assigned_sex = aboutYou.assignedSex;
    requestBody.hispanic_latino_or_spanish = aboutYou.identifyAs;
    requestBody.ethnicity_race = aboutYou.ethnicity;
    if (aboutYou.ethnicity === 'TwoOrMoreRacesOrEthnicities') {
        requestBody.multiple_ethnicities_including_black_or_african_american =
            aboutYou.twoOrMoreRacesOrEthnicities;
    }

    // Add the Medical and Family History sections details...
    requestBody.medical_history = {
        question_a: Object.entries(medicalHistory.cancers)
            .filter(([_, value]) => value)
            .map(([key]) => {
                if (key === 'cancer') {
                    return 'SELF_CANCER_GENERAL';
                }
                else if (key === 'abnormalBreastBiopsy') {
                    return 'SELF_CANCER_ABNORMAL_BREAST_BIOPSY';
                }
                else if (key === 'mastectomy') {
                    return 'SELF_CANCER_MASTECTOMY';
                }
                else if (key === 'abnormalProstateBiopsy') {
                    return 'SELF_CANCER_ABNORMAL_PROSTATE_BIOPSY';
                }
                else if (key === 'noneOfTheAbove') {
                    return 'SELF_CANCER_NOTA';
                }
                else if (key === 'unknown') {
                    return 'SELF_CANCER_DK';
                }

                return 'SELF_CANCER_PNTS';
            }),
        question_b: Object.entries(medicalHistory.heartCondition)
            .filter(([_, value]) => value)
            .map(([key]) => {
                if (key === 'heartAttack') {
                    return 'SELF_HEART_ATTACK';
                }
                else if (key === 'stroke') {
                    return 'SELF_STROKE';
                }
                else if (key === 'ldl') {
                    return 'SELF_LDL_OF_190_OR_ABOVE';
                }
                else if (key === 'coronaryArteryDisease') {
                    return 'SELF_CORONARY_ARTERY_DISEASE';
                }
                else if (key === 'coronaryArteryBypassSurgery') {
                    return 'SELF_CORONARY_ARTERY_BYPASS_SURGERY';
                }
                else if (key === 'stent') {
                    return 'SELF_STENT';
                }
                else if (key === 'angioplasty') {
                    return 'SELF_ANGIOPLASTY';
                }
                else if (key === 'noneOfTheAbove') {
                    return 'SELF_NOTA';
                }
                else if (key === 'unknown') {
                    return 'SELF_DK';
                }

                return 'SELF_PNTS';
            }),
        question_c: medicalHistory.diabetes,
        question_d: Object.entries(familyHistory.general)
            .filter(([_, value]) => value)
            .map(([key]) => {
                if (key === 'breast') {
                    return 'BLOOD_RELATIVE_CANCER_BREAST';
                }
                else if (key === 'ovarian') {
                    return 'BLOOD_RELATIVE_CANCER_OVARIAN';
                }
                else if (key === 'prostate') {
                    return 'BLOOD_RELATIVE_CANCER_PROSTATE';
                }
                else if (key === 'pancreatic') {
                    return 'BLOOD_RELATIVE_CANCER_PANCREATIC';
                }
                else if (key === 'colorectal') {
                    return 'BLOOD_RELATIVE_CANCER_COLORECTAL';
                }
                else if (key === 'noneOfTheAbove') {
                    return 'BLOOD_RELATIVE_CANCER_NOTA';
                }
                else if (key === 'unknown') {
                    return 'BLOOD_RELATIVE_CANCER_DK';
                }

                return 'BLOOD_RELATIVE_CANCER_PNTS';
            }),
        question_e: Object.entries(familyHistory.breastCancer)
            .filter(([_, value]) => value)
            .map(([key]) => {
                if (key === 'fortyFiveOrYounger') {
                    return 'BLOOD_RELATIVE_BREAST_45_OR_YOUNGER';
                }
                else if (key === 'tripleNegative') {
                    return 'BLOOD_RELATIVE_TRIPLE_NEGATIVE_BREAST_60_OR_YOUNGER';
                }
                else if (key === 'bothBreasts') {
                    return 'BLOOD_RELATIVE_BOTH_BREAST_OR_TWO_TUMOURS';
                }
                else if (key === 'maleBreastCancer') {
                    return 'BLOOD_RELATIVE_MALE_BREAST';
                }
                else if (key === 'noneOfTheAbove') {
                    return 'BLOOD_RELATIVE_NOTA';
                }
                else if (key === 'unknown') {
                    return 'BLOOD_RELATIVE_DK';
                }

                return 'BLOOD_RELATIVE_PNTS';
            }),
        question_f: Object.entries(familyHistory.prostateCancer)
            .filter(([_, value]) => value)
            .map(([key]) => {
                if (key === 'metaStatic') {
                    return 'BLOOD_RELATIVE_PROSTATE_CANCER_METASTATIC';
                }
                else if (key === 'diedFrom') {
                    return 'BLOOD_RELATIVE_PROSTATE_CANCER_DIED';
                }
                else if (key === 'noneOfTheAbove') {
                    return 'BLOOD_RELATIVE_PROSTATE_NOTA';
                }
                else if (key === 'unknown') {
                    return 'BLOOD_RELATIVE_PROSTATE_DK';
                }

                return 'BLOOD_RELATIVE_PROSTATE_PNTS';
            }),
        question_g: Object.entries(familyHistory.pancreaticCancer)
            .filter(([_, value]) => value)
            .map(([key]) => {
                if (key === 'parent') {
                    return 'BLOOD_RELATIVE_PANCREATIC_CANCER_PARENT';
                }
                else if (key === 'fullSibling') {
                    return 'BLOOD_RELATIVE_PANCREATIC_CANCER_FULL_SIBLING';
                }
                else if (key === 'child') {
                    return 'BLOOD_RELATIVE_PANCREATIC_CANCER_CHILD';
                }
                else if (key === 'distantRelative') {
                    return 'BLOOD_RELATIVE_PANCREATIC_CANCER_DISTANT';
                }
                else if (key === 'noneOfTheAbove') {
                    return 'BLOOD_RELATIVE_PANCREATIC_CANCER_NOTA';
                }
                else if (key === 'unknown') {
                    return 'BLOOD_RELATIVE_PANCREATIC_CANCER_DK';
                }

                return 'BLOOD_RELATIVE_PANCREATIC_CANCER_PNTS';
            }),
        question_h: familyHistory.colorectalCancer,
    };

    const geneticResultsPersonal = {
        breastCancer: geneticTesting.geneticResultsPersonal.breastCancer,
        hypercholesterolemia: geneticTesting.geneticResultsPersonal.hypercholesterolemia,
        noneOfTheAbove: geneticTesting.geneticResultsPersonal.noneOfTheAbove,
        preferNotToSay: geneticTesting.geneticResultsPersonal.preferNotToSay,
        prostateCancer: geneticTesting.geneticResultsPersonal.prostateCancer,
        unknown: geneticTesting.geneticResultsPersonal.unknown,
    };

    const geneticResultsFamily = {
        breastCancer: geneticTesting.geneticResultsFamily.breastCancer,
        hypercholesterolemia: geneticTesting.geneticResultsFamily.hypercholesterolemia,
        noneOfTheAbove: geneticTesting.geneticResultsFamily.noneOfTheAbove,
        preferNotToSay: geneticTesting.geneticResultsFamily.preferNotToSay,
        prostateCancer: geneticTesting.geneticResultsFamily.prostateCancer,
        unknown: geneticTesting.geneticResultsFamily.unknown,
    };

    // Add the Prior Genetic Testing section details...
    requestBody.genetic_test_history = {
        question_a: geneticTesting.previouslyTested,
        question_b: Object.entries(geneticResultsPersonal)
            .filter(([_, value]) => value)
            .map(([key]) => {
                if (key === 'breastCancer') {
                    return 'BLOOD_RELATIVE_POSITIVE_GENE_TEST_BREAST';
                }
                else if (key === 'prostateCancer') {
                    return 'BLOOD_RELATIVE_POSITIVE_GENE_TEST_PROSTATE';
                }
                else if (key === 'hypercholesterolemia') {
                    return 'BLOOD_RELATIVE_POSITIVE_GENE_TEST_HYPERCHOLESTEROLEMIA';
                }
                else if (key === 'unknown') {
                    return 'SELF_POSITIVE_GENE_TEST_NO';
                }
                else if (key === 'noneOfTheAbove') {
                    return 'SELF_AND_RELATIVE_POSITIVE_GENE_TEST_UNAWARE';
                }

                return 'POSITIVE_GENE_TEST_PNTS';
            }),
        question_c: Object.entries(geneticResultsFamily)
            .filter(([_, value]) => value)
            .map(([key]) => {
                if (key === 'breastCancer') {
                    return 'BLOOD_RELATIVE_POSITIVE_GENE_TEST_BREAST';
                }
                else if (key === 'prostateCancer') {
                    return 'BLOOD_RELATIVE_POSITIVE_GENE_TEST_PROSTATE';
                }
                else if (key === 'hypercholesterolemia') {
                    return 'BLOOD_RELATIVE_POSITIVE_GENE_TEST_HYPERCHOLESTEROLEMIA';
                }
                else if (key === 'unknown') {
                    return 'SELF_POSITIVE_GENE_TEST_NO';
                }
                else if (key === 'noneOfTheAbove') {
                    return 'SELF_AND_RELATIVE_POSITIVE_GENE_TEST_UNAWARE';
                }

                return 'POSITIVE_GENE_TEST_PNTS';
            }),
    };

    // Add the Shipping Details section...
    requestBody.shipping_address = {
        apartment_suite_building: shippingDetails.apartment,
        city: shippingDetails.city,
        recipient: shippingDetails.recipientName,
        state: shippingDetails.state,
        street_address: shippingDetails.street,
        zip_code: shippingDetails.zipCode,
    };

    // Check the optional questions and if they don't have an answer, set them to NOT_ANSWERED...
    if (!requestBody.medical_history.question_e.length) {
        requestBody.medical_history.question_e = ['NOT_ANSWERED'];
    }

    if (!requestBody.medical_history.question_f.length) {
        requestBody.medical_history.question_f = ['NOT_ANSWERED'];
    }

    if (!requestBody.medical_history.question_g.length) {
        requestBody.medical_history.question_g = ['NOT_ANSWERED'];
    }

    if (!requestBody.medical_history.question_h.length) {
        requestBody.medical_history.question_h = 'NOT_ANSWERED';
    }

    if (!requestBody.genetic_test_history.question_b.length) {
        requestBody.genetic_test_history.question_b = ['NOT_ANSWERED'];
    }

    if (!requestBody.genetic_test_history.question_c.length) {
        requestBody.genetic_test_history.question_c = ['NOT_ANSWERED'];
    }

    return JSON.stringify(requestBody);
};
/* eslint-enable camelcase */

export function ParticipantDetails() {
    const [authenticatedFetch] = useApi();
    const minervaConfig = useMinervaConfig();
    const {refreshPolicyholderData} = usePolicyholderContext();
    const intl = useIntl();
    const {
        nextStep,
        previousStep,
        state: {data},
    } = useRegistrationContext();
    const [{error, isCommunicating}, dispatch] = useReducer(reducer, initialState);

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const onSubmitHandler = useCallback(async () => {
        try {
            dispatch(action(UPDATE_IS_COMMUNICATING, true));

            const response = await authenticatedFetch(
                `${minervaConfig.servicesBackend}/policyholders/me`,
                {
                    body: constructRequestBody(data),
                    method: 'POST',
                }
            );

            if (response.ok) {
                dispatch(action(UPDATE_IS_COMMUNICATING, false));
                nextStep({
                    inaccesibleFromSectionTopic: '/registration/shipping-details',
                    registrationComplete: true,
                    sectionComplete: true,
                });

                // Refresh data in PolicyholderContext to ensure it has updated `state` since control logic needs it
                refreshPolicyholderData();
            }
            else {
                dispatch(
                    action(
                        UPDATE_ERROR,
                        intl.formatMessage({id: 'registration.summary.errorResponse.description.text'})
                    )
                );
            }
        }
        catch (communicationError) {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log(communicationError);
            }
            dispatch(
                action(
                    UPDATE_ERROR,
                    intl.formatMessage({id: 'registration.summary.communicationError.description.text'})
                )
            );
        }
    }, []);

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage id = {'registration.summary.participantDetails.title.text'} />
                </h2>
                <p>
                    <FormattedMessage
                        id = {'registration.summary.participantDetails.instruction.text'}
                    />
                </p>
            </div>
            <Divider />
            <div className = {'question-options'}>
                <AboutYouQuestions />
                <MedicalHistoryQuestions />
                <FamilyHistoryQuestions />
                <PriorGeneticTesting />
                {error && (
                    <ErrorCard
                        dataTestId = {'submit-details-communication-error'}
                        title = {intl.formatMessage({id: 'common.error.title.text'})}
                    >
                        <p>{error}</p>
                        <p>
                            <FormattedMessage
                                id = {'common.error.contactSupport.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'support.email'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                    </ErrorCard>
                )}
                <Button
                    dataTestId = {'confirm-answers-button'}
                    isLoading = {isCommunicating}
                    onClick = {onSubmitHandler}
                >
                    <FormattedMessage
                        id = {'registration.summary.participantDetails.button.confirm.text'}
                    />
                </Button>
            </div>
        </div>
    );
}
