import {
    useCallback,
    useState,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Button,
    Checkbox,
    Checkboxes,
    Link,
} from '../../../../denim';
import {
    action,
    UPDATE_VALUE,
} from '../../../common';
import {Divider} from '../../../components';
import {
    init,
    useRegistrationContext,
} from '../RegistrationContext';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_VALUE:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};
const STEP_KEY = '/registration/family-history';
const NUM_STEPS_DEFAULT = 2;

export function BloodRelativeCancer() {
    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState(null);

    const {
        changeNumberOfSteps,
        nextStep,
        previousStep,
        state: {data: {familyHistory} = {}} = {},
        updateFamilyHistoryField,
    } = useRegistrationContext();

    const [general, dispatch] = useReducer(reducer, familyHistory.general);

    const {
        breast,
        colorectal,
        noneOfTheAbove,
        ovarian,
        pancreatic,
        preferNotToSay,
        prostate,
        unknown,
    } = general;

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const onChangeHandler = useCallback(
        (value, name) => {
            const checkboxValue = Boolean(value);
            let payload = {};

            if (['breast', 'colorectal', 'ovarian', 'pancreatic', 'prostate'].includes(name)) {
                payload = {
                    ...general,
                    [name]: checkboxValue,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    unknown: false,
                };

                const subSteps = Object.entries(payload).filter(
                    ([trait, selection]) => trait !== 'ovarian' && selection === true
                );
                changeNumberOfSteps(STEP_KEY, NUM_STEPS_DEFAULT + subSteps.length);
            }
            else {
                const initialValues = {
                    breast: false,
                    colorectal: false,
                    noneOfTheAbove: false,
                    ovarian: false,
                    pancreatic: false,
                    preferNotToSay: false,
                    prostate: false,
                    unknown: false,
                };

                payload = {
                    ...initialValues,
                    [name]: checkboxValue,
                };
                changeNumberOfSteps(STEP_KEY, NUM_STEPS_DEFAULT);
            }
            dispatch(action(UPDATE_VALUE, payload));

            setErrorMessage(null);
        },
        [general, changeNumberOfSteps]
    );

    const onSubmitHandler = useCallback(
        (event) => {
            event.preventDefault();

            // Check validity cause an invalid event to be triggered on anything that is invalid, which will cause them
            // to display their error state.
            const form = event.target;
            const formValid = form.checkValidity();

            // Check at least one checkbox has been selected.
            const isChecked = document.querySelectorAll('input[type=checkbox]:checked').length > 0;

            // If the form if valid, transition to the next step...
            if (formValid && isChecked) {
                updateFamilyHistoryField('general', general);

                // If we have selected any of the "no" options
                if (unknown || preferNotToSay || noneOfTheAbove) {
                    // Reset the sub-step forms to their initial state
                    updateFamilyHistoryField(
                        'breastCancer',
                        init(intl).data.familyHistory.breastCancer
                    );
                    updateFamilyHistoryField(
                        'colorectalCancer',
                        init(intl).data.familyHistory.colorectalCancer
                    );
                    updateFamilyHistoryField(
                        'pancreaticCancer',
                        init(intl).data.familyHistory.pancreaticCancer
                    );
                    updateFamilyHistoryField(
                        'prostateCancer',
                        init(intl).data.familyHistory.prostateCancer
                    );

                    nextStep({complete: true});
                }
                else {
                    // Reset the unselected sub-step forms to their initial state
                    Object.entries(general).forEach(([trait, selection]) => {
                        if (trait === 'breast' && selection === false) {
                            updateFamilyHistoryField(
                                'breastCancer',
                                init(intl).data.familyHistory.breastCancer
                            );
                        }
                        if (trait === 'colorectal' && selection === false) {
                            updateFamilyHistoryField(
                                'colorectalCancer',
                                init(intl).data.familyHistory.colorectalCancer
                            );
                        }
                        if (trait === 'pancreatic' && selection === false) {
                            updateFamilyHistoryField(
                                'pancreaticCancer',
                                init(intl).data.familyHistory.pancreaticCancer
                            );
                        }
                        if (trait === 'prostate' && selection === false) {
                            updateFamilyHistoryField(
                                'prostateCancer',
                                init(intl).data.familyHistory.prostateCancer
                            );
                        }
                    });

                    // If only "ovarian" is selected then we mark this as completed
                    const shouldCompleteStep =
                        ovarian && !breast && !colorectal && !pancreatic && !prostate;

                    nextStep({complete: shouldCompleteStep});
                }
            }
            else {
                setErrorMessage(
                    intl.formatMessage({id: 'registration.common.error.choose-at-least-one.text'})
                );
            }
        },
        [general]
    );

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage
                        id = {'registration.familyHistory.bloodRelativeCancer.title.text'}
                    />
                </h2>
                <p className = {'description'}>
                    <FormattedMessage id = {'registration.familyHistory.question.description'} />
                </p>
            </div>
            <Divider />
            <div className = {'question-options'}>
                <form
                    noValidate = {true}
                    onSubmit = {onSubmitHandler}
                >
                    <Checkboxes
                        errorMessage = {errorMessage}
                        label = {intl.formatMessage({id: 'registration.common.multiOption.instruction.text'})}
                    >
                        <Checkbox
                            checked = {breast}
                            dataTestId = {'blood-relative-cancer-breast'}
                            id = {'breast-checkbox'}
                            key = {'breast-checkbox'}
                            label = {intl.formatMessage({id: 'registration.familyHistory.bloodRelativeCancer.checkbox.breast.text'})}
                            name = {'breast'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {ovarian}
                            conditionalReveal = {intl.formatMessage({id: 'registration.familyHistory.previouslyHad.conditionalReveal.text'})}
                            dataTestId = {'blood-relative-cancer-ovarian'}
                            id = {'ovarian-checkbox'}
                            key = {'ovarian-checkbox'}
                            label = {intl.formatMessage({id: 'registration.familyHistory.bloodRelativeCancer.checkbox.ovarian.text'})}
                            name = {'ovarian'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {prostate}
                            dataTestId = {'blood-relative-cancer-prostate'}
                            id = {'prostate-checkbox'}
                            key = {'prostate-checkbox'}
                            label = {intl.formatMessage({id: 'registration.familyHistory.bloodRelativeCancer.checkbox.prostate.text'})}
                            name = {'prostate'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {pancreatic}
                            dataTestId = {'blood-relative-cancer-pancreatic'}
                            id = {'pancreatic-checkbox'}
                            key = {'pancreatic-checkbox'}
                            label = {intl.formatMessage({id: 'registration.familyHistory.bloodRelativeCancer.checkbox.pancreatic.text'})}
                            name = {'pancreatic'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {colorectal}
                            dataTestId = {'blood-relative-cancer-colorectal'}
                            id = {'colorectal-checkbox'}
                            key = {'colorectal-checkbox'}
                            label = {intl.formatMessage({id: 'registration.familyHistory.bloodRelativeCancer.checkbox.colorectal.text'})}
                            name = {'colorectal'}
                            onChange = {onChangeHandler}
                        />
                        <div className = {'is-caption option-divider'}>
                            <FormattedMessage id = {'common.or.text'} />
                        </div>
                        <Checkbox
                            checked = {noneOfTheAbove}
                            dataTestId = {'blood-relative-cancer-none-of-the-above'}
                            id = {'none-of-the-above-checkbox'}
                            key = {'none-of-the-above-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.noneOfTheAbove.text'})}
                            name = {'noneOfTheAbove'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {unknown}
                            dataTestId = {'blood-relative-cancer-dont-know'}
                            id = {'dont-know-checkbox'}
                            key = {'dont-know-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.unknown.text'})}
                            name = {'unknown'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {preferNotToSay}
                            conditionalReveal = {intl.formatMessage({id: 'registration.familyHistory.preferNotToSay.conditionalReveal.text'})}
                            dataTestId = {'blood-relative-cancer-prefer-not-to-say'}
                            id = {'prefer-not-to-say-checkbox'}
                            key = {'prefer-not-to-say-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.prefer-not-to-say.text'})}
                            name = {'preferNotToSay'}
                            onChange = {onChangeHandler}
                        />
                    </Checkboxes>
                    <Button
                        dataTestId = {'continue-button'}
                        submit = {true}
                    >
                        <FormattedMessage id = {'registration.common.button.continue.text'} />
                    </Button>
                </form>
            </div>
        </div>
    );
}
