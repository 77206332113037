import classNames from 'classnames';
import {
    useCallback,
    useMemo,
    useReducer,
} from 'react';
import {
    action,
    filterInputPropsForType,
    getConstraintValidationsForType,
    SET_DIRTY,
    SET_VALIDITY,
} from '../common';

const initialState = {
    dirty: false,
    valid: true,
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_DIRTY:
            return {
                ...state,
                dirty: payload,
            };

        case SET_VALIDITY:
            return {
                ...state,
                valid: payload,
            };

        default:
            return state;
    }
};

export function Checkbox(props) {
    const {
        className,
        conditionalReveal,
        dataTestId,
        description,
        id,
        label,
        onBlur,
        onChange,
        onFocus,
        ...otherProps
    } = props;

    const [{dirty, valid}, dispatch] = useReducer(reducer, initialState);

    // Filters the otherProps for those are are valid for the given input type...
    const filteredInputProps = useMemo(
        () =>
            filterInputPropsForType({
                ...otherProps,
                type: 'checkbox',
            }),
        [otherProps]
    );

    // Generates a list of constraints that should be checked for the given input type and supplied props...
    const constraintValidations = useMemo(
        () => getConstraintValidationsForType(filteredInputProps),
        [filteredInputProps]
    );

    const onBlurHandler = useCallback(
        (event) => {
            // Get the ValidityState of the input and check for any failed constraints...
            const {validity} = event.target;
            const failedConstraints = constraintValidations.filter(
                (constraint) => validity[constraint]
            );

            // If there are no failedConstraints, then we're valid...
            const inputValidity = failedConstraints.length === 0;

            // Update our internal state so we can apply the correct styling if we're invalid...
            dispatch(action(SET_VALIDITY, inputValidity));

            if (onBlur) {
                onBlur(event.target.checked ? event.target.value : null, inputValidity);
            }
        },
        [constraintValidations, onBlur]
    );

    const onChangeHandler = useCallback(
        (event) => {
            if (onChange) {
                const {target: {checked, name, value} = {}} = event;
                onChange(checked ? value : null, name);
            }
        },
        [onChange]
    );

    const onFocusHandler = useCallback(() => {
        if (onFocus) {
            onFocus();
        }
    }, [onFocus]);

    const checkboxClasses = classNames('checkbox', className, {
        dirty: dirty,
        invalid: !valid,
    });

    return (
        <div
            className = {checkboxClasses}
            data-test-id = {dataTestId}
        >
            <input
                data-test-id = {`${dataTestId}-checkbox`}
                id = {id}
                onBlur = {onBlurHandler}
                onChange = {onChangeHandler}
                onFocus = {onFocusHandler}
                {...filteredInputProps}
            />
            <label
                className = {'is-heavy'}
                data-test-id = {`${dataTestId}-label`}
                htmlFor = {id}
            >
                {label}
            </label>
            {description && (
                <div
                    className = {'description is-caption'}
                    data-test-id = {`${dataTestId}-description`}
                >
                    {description}
                </div>
            )}
            {conditionalReveal && (
                <div
                    className = {'conditional-reveal'}
                    data-test-id = {`${dataTestId}-conditional-reveal`}
                >
                    {conditionalReveal}
                </div>
            )}
        </div>
    );
}
