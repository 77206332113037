import {useAuth0} from '@auth0/auth0-react';
import {
    useCallback,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Button,
    ErrorCard,
    Link,
} from '../../../../denim';
import {useApi} from '../../../auth0';
import {action} from '../../../common';
import {Divider} from '../../../components';
import {useMinervaConfig} from '../../../config';

const THREE_SECONDS = 3000;

const initialState = {
    emailResent: false,
    error: false,
    loading: false,
};

const SET_EMAIL_RESENT_STATUS = 'SET_EMAIL_RESENT_STATUS';
const SET_ERROR = 'SET_ERROR';
const SET_LOADING = 'SET_LOADING';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_EMAIL_RESENT_STATUS:
            return {
                ...state,
                emailResent: payload,
            };

        case SET_ERROR:
            return {
                ...state,
                error: payload,
            };

        case SET_LOADING:
            return {
                ...state,
                error: payload ? false : state.error,
                loading: payload,
            };

        default:
            return state;
    }
};

export function EmailVerification() {
    const {user} = useAuth0();
    const [authenticatedFetch] = useApi();
    const minervaConfig = useMinervaConfig();
    const intl = useIntl();
    const [{emailResent, error, loading}, dispatch] = useReducer(reducer, initialState);

    const resendVerificationEmail = useCallback(async () => {
        if (emailResent) {
            return;
        }

        dispatch(action(SET_LOADING, true));

        try {
            const response = await authenticatedFetch(
                `${minervaConfig.servicesBackend}/policyholders/verify`,
                {method: 'POST'}
            );

            if (response.ok) {
                dispatch(action(SET_EMAIL_RESENT_STATUS, true));
            }
            else {
                dispatch(action(SET_ERROR, true));
            }
        }
        catch (err) {
            dispatch(action(SET_ERROR, true));
        }
        finally {
            dispatch(action(SET_LOADING, false));

            setTimeout(() => {
                dispatch(action(SET_EMAIL_RESENT_STATUS, initialState.emailResent));
            }, THREE_SECONDS);
        }
    }, [emailResent]);

    const resendButtonStatus = emailResent ? 'SENT' : 'READY';

    return (
        <div className = {'content'}>
            <div className = {'question-container no-back-link'}>
                <h2 data-test-id = {'email-verification-title'}>
                    <FormattedMessage id = {'registration.accountSetup.emailVerification.title.text'} />
                </h2>
                <p data-test-id = {'email-verification-instructions'}>
                    <FormattedMessage
                        id = {'registration.accountSetup.emailVerification.instruction.text'}
                        values = {{
                            strong: (chunks) => <strong>{chunks}</strong>,
                            userEmail: user?.email,
                        }}
                    />
                </p>
            </div>
            <Divider />
            {error && (
                <ErrorCard
                    className = {'api-error'}
                    title = {intl.formatMessage({id: 'common.error.title.text'})}
                >
                    <p>
                        <FormattedMessage id = {'common.error.genericApiError.text'} />
                    </p>
                    <p>
                        <FormattedMessage
                            id = {'common.error.contactSupport.text'}
                            values = {{
                                link: (chunks) => (
                                    <Link
                                        rel = {'noreferrer'}
                                        target = {'_blank'}
                                        url = {intl.formatMessage({id: 'support.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </ErrorCard>
            )}
            <h3>
                <FormattedMessage id = {'registration.accountSetup.emailVerification.prompt.text'} />
            </h3>
            <ul>
                <li>
                    <FormattedMessage
                        id = {'registration.accountSetup.emailVerification.prompt.itemOne.text'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id = {'registration.accountSetup.emailVerification.prompt.itemTwo.text'}
                    />
                </li>
                <li>
                    <FormattedMessage
                        id = {'registration.accountSetup.emailVerification.prompt.itemThree.text'}
                    />
                </li>
            </ul>
            <Button
                dataTestId = {'email-verification-button'}
                isLoading = {loading}
                onClick = {resendVerificationEmail}
                primary = {false}
            >
                <FormattedMessage
                    id = {`registration.accountSetup.emailVerification.resend.button.text.${resendButtonStatus}`}
                />
            </Button>
        </div>
    );
}
