import {useAuth0} from '@auth0/auth0-react';
import {
    useEffect,
    useReducer,
    useRef,
} from 'react';
import {useApi} from '../../../auth0';
import {action} from '../../../common';
import {useMinervaConfig} from '../../../config';

const SET_ERROR = 'SET_ERROR';
const SET_LOADING = 'SET_LOADING';
const SET_STATE = 'SET_STATE';

const convertScoreToPercent = (score, precision = 0) => {
    const PERCENT = 100;

    const scorePercent = score * PERCENT;
    return Number(scorePercent.toFixed(precision));
};

const convertResultScoresToPercent = (resultData) => ({
    ...resultData,
    baselineRiskScore: convertScoreToPercent(resultData.baselineRiskScore, 1),
    highRiskThreshold: convertScoreToPercent(resultData.highRiskThreshold, 1),
    integratedRiskScore: convertScoreToPercent(resultData.integratedRiskScore, 1),
});

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_STATE:
            return {
                ...state,
                result: payload,
            };

        case SET_LOADING:
            return {
                ...state,
                loading: payload,
            };

        case SET_ERROR:
            return {
                ...state,
                error: payload.error,
                errorCode: payload.errorCode,
            };

        default:
            return state;
    }
};

const updateCache = (key, traitData) => {
    try {
        const cache = JSON.parse(sessionStorage.getItem(key));

        sessionStorage.setItem(
            key,
            JSON.stringify({
                ...cache,
                [traitData.traitCode]: traitData,
            })
        );
    }
    catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error updating cache', error);
    }
};

const init = ({cacheKey, traitCode}) => {
    let cache = null;

    try {
        cache = JSON.parse(sessionStorage.getItem(cacheKey));
    }
    catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error reading from cache', error);
    }

    let traitResult = cache?.[traitCode];

    if (traitResult) {
        traitResult = convertResultScoresToPercent(traitResult);
    }

    return {
        error: false,
        errorCode: null,
        loading: false,
        result: traitResult,
    };
};

export function useTraitResult(traitCode) {
    const [authenticatedFetch] = useApi();
    const {servicesBackend} = useMinervaConfig();
    const {user} = useAuth0();
    const isCancelled = useRef(false);

    const CACHE_KEY = user.sub;

    const initArg = {
        cacheKey: CACHE_KEY,
        traitCode: traitCode,
    };

    const [state, dispatch] = useReducer(reducer, initArg, init);

    const getTraitResult = async () => {
        if (state.result) {
            return;
        }

        try {
            dispatch(action(SET_LOADING, true));

            const response = await authenticatedFetch(
                `${servicesBackend}/policyholders/me/result/${traitCode}`
            );

            if (response.ok) {
                const data = await response.json();

                updateCache(CACHE_KEY, data);

                if (!isCancelled.current) {
                    dispatch(action(SET_STATE, convertResultScoresToPercent(data)));
                }
            }
            else {
                dispatch(
                    action(SET_ERROR, {
                        error: true,
                        errorCode: response.status,
                    })
                );
            }
        }
        catch (err) {
            if (!isCancelled.current) {
                dispatch(
                    action(SET_ERROR, {
                        error: true,
                        errorCode: null,
                    })
                );
            }
        }
        finally {
            if (!isCancelled.current) {
                dispatch(action(SET_LOADING, false));
            }
        }
    };

    useEffect(() => {
        getTraitResult();

        return () => {
            isCancelled.current = true;
        };
    }, []);

    return state;
}
