import throttle from 'lodash.throttle';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import SmartyStreetsSDK from 'smartystreets-javascript-sdk';
import {
    AutoCompleteTextInput,
    Link,
} from '../../../../denim';
import {Divider} from '../../../components';
import {useMinervaConfig} from '../../../config';
import {useRegistrationContext} from '../RegistrationContext';

const SmartyStreetsCore = SmartyStreetsSDK.core;
const {Lookup} = SmartyStreetsSDK.usAutocompletePro;

const getAddressLabelText = (suggestion) => {
    const {
        city,
        state,
        streetLine,
    } = suggestion;

    return `${streetLine}, ${city} ${state}`;
};
const autoCompleteMatchingFunction = (suggestions) => suggestions;

const suggestionToShippingFieldMap = {
    city: 'city',
    state: 'state',
    streetLine: 'street',
    zipcode: 'zipCode',
};

export function AutoCompleteAddress() {
    const intl = useIntl();
    const minervaConfig = useMinervaConfig();
    const {
        nextStep,
        previousStep,
        state: {data: {shippingDetails: {autoCompleteAddress}} = {}} = {},
        updateShippingDetailsField,
    } = useRegistrationContext();

    const initialOptions = autoCompleteAddress ? [autoCompleteAddress] : [];
    const [options, setOptions] = useState(initialOptions);
    const [searchText, setSearchText] = useState('');

    const credentials = new SmartyStreetsCore.SharedCredentials(minervaConfig.smartyStreetsKey);

    const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials).withLicenses([
        'us-autocomplete-pro-cloud',
    ]);

    const client = clientBuilder.buildUsAutocompleteProClient();

    const smartyStreetsLookup = async (searchTerm, resultHandler) => {
        if (!searchTerm) {
            return;
        }

        const lookup = new Lookup(searchTerm);

        try {
            const response = await client.send(lookup);
            resultHandler(response.result);
        }
        catch (error) {
            if (process.env.NODE_ENV === 'development') {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        }
    };

    const THROTTLE_DELAY_IN_MS = 200;

    const lookAddressUp = useCallback(
        throttle(
            (searchTerm, callback) => smartyStreetsLookup(searchTerm, callback),
            THROTTLE_DELAY_IN_MS
        ),
        [setOptions]
    );

    const onChangeHandler = useCallback((value) => {
        setSearchText(value);
    }, []);

    useEffect(() => {
        let active = true;

        lookAddressUp(searchText, (results) => {
            if (active) {
                setOptions(results);
            }
        });

        return () => {
            active = false;
        };
    }, [searchText]);

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const goToNextStep = useCallback((event) => {
        event.preventDefault();
        nextStep();
    }, []);

    const onSubmitHandler = useCallback((event) => {
        // Hitting return within the text field cause the form to be submitted, which we don't want...
        event.preventDefault();
        return false;
    }, []);

    const onOptionSelectHandler = useCallback(
        (selectedOption) => {
            updateShippingDetailsField('autoCompleteAddress', selectedOption);

            // Clearing the text input deselects any previous selection
            // so we only update the 'autocomplete' field and not the individual address fields
            if (!selectedOption) {
                return;
            }

            Object.entries(selectedOption).forEach(([key, val]) => {
                if (key in suggestionToShippingFieldMap) {
                    updateShippingDetailsField(suggestionToShippingFieldMap[key], val);
                }
            });

            nextStep({state: {addressAutoCompleted: true}});
        },
        [options]
    );

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage
                        id = {'registration.shippingDetails.autoCompleteAddress.question.text'}
                    />
                </h2>
            </div>
            <Divider />
            <div className = {'question-options'}>
                <form onSubmit = {onSubmitHandler}>
                    <AutoCompleteTextInput
                        customMatchingFunction = {autoCompleteMatchingFunction}
                        dataTestId = {'autocomplete-address'}
                        getOptionDisplayText = {getAddressLabelText}
                        maxMatches = {5}
                        name = {'address'}
                        noOptionsFoundMessage = {intl.formatMessage({id: 'common.autoCompleteTextInput.noOptionsFound.text'})}
                        onOptionSelect = {onOptionSelectHandler}
                        optionItemKey = {'text'}
                        options = {options}
                        textInputProps = {{
                            autoComplete: 'off',
                            id: 'address',
                            label: intl.formatMessage({id: 'registration.shippingDetails.autoCompleteAddress.label'}),
                            onChange: onChangeHandler,
                        }}
                        value = {autoCompleteAddress}
                    />
                    <Link
                        className = {'is-help-link is-caption'}
                        dataTestId = {'enter-manually-link'}
                        onClick = {goToNextStep}
                        url = {'#'}
                    >
                        <FormattedMessage
                            id = {'registration.shippingDetails.autoCompleteAddress.enterManually.text'}
                        />
                    </Link>
                </form>
            </div>
        </div>
    );
}
