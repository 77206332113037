import {
    useCallback,
    useLayoutEffect,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {
    Link,
    Nav,
    Variant,
} from '../../../denim';
import {useTitle} from '../../common';
import {
    GridContainer,
    LandingPageFooter,
    LegalFooterSection,
} from '../../components';

export function FrequentlyAskedQuestions() {
    const intl = useIntl();
    const history = useHistory();

    useLayoutEffect(() => {
        // When someone comes in via a link, they probably want to start at the top of the page, not halfway down...
        window.scrollTo(0, 0);
    }, []);

    useTitle(intl.formatMessage({id: 'frequentlyAskedQuestions.documentTitle'}));

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        history.push('/');
    }, []);

    return (
        <>
            <header
                aria-label = {'Page header'}
                className = {'page-header'}
                role = {'banner'}
            >
                <Nav
                    closeLinkAriaLabel = {intl.formatMessage({id: 'common.close.label'})}
                    footerContent = {<LegalFooterSection />}
                    openLinkAriaLabel = {intl.formatMessage({id: 'common.open.label'})}
                    variant = {Variant.LIGHT}
                >
                    <ul role = {'menu'}>
                        <li
                            className = {'after icon launch'}
                            role = {'menuitem'}
                        >
                            <Link
                                target = {'_blank'}
                                url = {'/frequently-asked-questions'}
                            >
                                <FormattedMessage
                                    id = {'report.navigation.links.geneticCounseling.text'}
                                />
                            </Link>
                        </li>
                    </ul>
                </Nav>
            </header>
            <section
                className = {'page-content static-content'}
                role = {'main'}
            >
                <GridContainer containerClassName = {'content-section'}>
                    <div className = {'page-title'}>
                        <Link
                            before = {true}
                            icon = {'chevron--left'}
                            onClick = {onBackClickHandler}
                        >
                            <FormattedMessage id = {'staticPages.common.link.back.text'} />
                        </Link>
                        <h1>
                            <FormattedMessage id = {'frequentlyAskedQuestions.pageTitle.text'} />
                        </h1>
                    </div>
                </GridContainer>
                <GridContainer
                    containerClassName = {'content-section'}
                    variant = {Variant.LIGHT}
                >
                    <div className = {'content-item'}>
                        <h1 id = {'genetic-counseling'}>
                            <FormattedMessage
                                id = {'frequentlyAskedQuestions.geneticCounseling.title.text'}
                            />
                        </h1>
                        <h3 id = {'schedule-appointment'}>
                            <FormattedMessage
                                id = {
                                    'frequentlyAskedQuestions.geneticCounseling.description.three.title.text'
                                }
                            />
                        </h3>
                        <p>
                            <FormattedMessage
                                id = {
                                    'frequentlyAskedQuestions.geneticCounseling.description.finished.three.body.one.text'
                                }
                                values = {{
                                    email: (chunks) => (
                                        <Link
                                            className = {'contact-link'}
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'genomeMedicalSupport.emailTo'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                    phone: (chunks) => (
                                        <Link
                                            className = {'contact-link'}
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'genomeMedicalSupport.phone'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                    </div>
                </GridContainer>
            </section>
            <LandingPageFooter />
        </>
    );
}
