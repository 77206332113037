import {
    useCallback,
    useState,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Button,
    Checkbox,
    Checkboxes,
    Link,
} from '../../../../denim';
import {
    action,
    UPDATE_VALUE,
} from '../../../common';
import {Divider} from '../../../components';
import {useRegistrationContext} from '../RegistrationContext';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_VALUE:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};

export function Cancer() {
    const intl = useIntl();
    const {
        nextStep,
        previousStep,
        state: {data: {medicalHistory} = {}} = {},
        updateMedicalHistoryField,
    } = useRegistrationContext();

    const [cancers, dispatch] = useReducer(reducer, medicalHistory.cancers);
    const {
        abnormalBreastBiopsy,
        abnormalProstateBiopsy,
        cancer,
        mastectomy,
        noneOfTheAbove,
        preferNotToSay,
        unknown,
    } = cancers;

    const [errorMessage, setErrorMessage] = useState(null);

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const onChangeHandler = useCallback(
        (value, name) => {
            const checkboxValue = Boolean(value);
            let payload = {};

            if (
                ['abnormalBreastBiopsy', 'abnormalProstateBiopsy', 'cancer', 'mastectomy'].includes(
                    name
                )
            ) {
                payload = {
                    ...cancers,
                    [name]: checkboxValue,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    unknown: false,
                };
            }
            else {
                const initialValues = {
                    abnormalBreastBiopsy: false,
                    abnormalProstateBiopsy: false,
                    cancer: false,
                    mastectomy: false,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    unknown: false,
                };

                payload = {
                    ...initialValues,
                    [name]: checkboxValue,
                };
            }
            dispatch(action(UPDATE_VALUE, payload));

            setErrorMessage(null);
        },
        [cancers]
    );

    const onSubmitHandler = useCallback(
        (event) => {
            event.preventDefault();
            // Check validity cause an invalid event to be triggered on anything that is invalid, which will cause them
            // to display their error state.
            const form = event.target;
            const formValid = form.checkValidity();

            // Check at least one checkbox has been selected.
            const isChecked = document.querySelectorAll('input[type=checkbox]:checked').length > 0;

            // If the form if valid, transition to the next step...
            if (formValid && isChecked) {
                updateMedicalHistoryField('cancers', cancers);
                nextStep();
            }
            else {
                setErrorMessage(
                    intl.formatMessage({id: 'registration.common.error.choose-at-least-one.text'})
                );
            }
        },
        [cancers]
    );

    const conditionalRevealPreviouslyHad = intl.formatMessage({id: 'registration.medicalHistory.cancer.previouslyHad.conditionalReveal.text'});
    const conditionalRevealPreferNotToSay = intl.formatMessage({id: 'registration.medicalHistory.cancer.preferNotToSay.conditionalReveal.text'});

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage id = {'registration.medicalHistory.cancer.title.text'} />
                </h2>
            </div>
            <Divider />
            <div className = {'question-options'}>
                <form
                    noValidate = {true}
                    onSubmit = {onSubmitHandler}
                >
                    <Checkboxes
                        errorMessage = {errorMessage}
                        label = {intl.formatMessage({id: 'registration.common.multiOption.instruction.text'})}
                    >
                        <Checkbox
                            checked = {cancer}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'cancer-cancer'}
                            description = {intl.formatMessage({id: 'registration.medicalHistory.cancer.checkbox.cancer.description.text'})}
                            id = {'cancer-checkbox'}
                            key = {'cancer-checkbox'}
                            label = {intl.formatMessage({id: 'registration.medicalHistory.cancer.checkbox.cancer.text'})}
                            name = {'cancer'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {abnormalBreastBiopsy}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'cancer-breast-biopsy'}
                            id = {'abnormal-breast-biopsy-checkbox'}
                            key = {'abnormal-breast-biopsy-checkbox'}
                            label = {intl.formatMessage({id: 'registration.medicalHistory.cancer.checkbox.abnormalBreastBiopsy.text'})}
                            name = {'abnormalBreastBiopsy'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {abnormalProstateBiopsy}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'cancer-abnormal-prostate-biopsy'}
                            id = {'abnormal-prostate-biopsy-checkbox'}
                            key = {'abnormal-prostate-biopsy-checkbox'}
                            label = {intl.formatMessage({id: 'registration.medicalHistory.cancer.checkbox.abnormalProstateBiopsy.text'})}
                            name = {'abnormalProstateBiopsy'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {mastectomy}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'cancer-mastectomy'}
                            id = {'mastectomy-checkbox'}
                            key = {'mastectomy-checkbox'}
                            label = {intl.formatMessage({id: 'registration.medicalHistory.cancer.checkbox.mastectomy.text'})}
                            name = {'mastectomy'}
                            onChange = {onChangeHandler}
                        />
                        <div className = {'is-caption option-divider'}>
                            <FormattedMessage id = {'common.or.text'} />
                        </div>
                        <Checkbox
                            checked = {noneOfTheAbove}
                            dataTestId = {'cancer-none-of-the-above'}
                            id = {'none-of-the-above-checkbox'}
                            key = {'none-of-the-above-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.noneOfTheAbove.text'})}
                            name = {'noneOfTheAbove'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {unknown}
                            dataTestId = {'cancer-dont-know'}
                            id = {'dont-know-checkbox'}
                            key = {'dont-know-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.unknown.text'})}
                            name = {'unknown'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {preferNotToSay}
                            conditionalReveal = {conditionalRevealPreferNotToSay}
                            dataTestId = {'cancer-prefer-not-to-say'}
                            id = {'prefer-not-to-say-checkbox'}
                            key = {'prefer-not-to-say-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.prefer-not-to-say.text'})}
                            name = {'preferNotToSay'}
                            onChange = {onChangeHandler}
                        />
                    </Checkboxes>
                    <Button
                        dataTestId = {'continue-button'}
                        submit = {true}
                    >
                        <FormattedMessage id = {'registration.common.button.continue.text'} />
                    </Button>
                </form>
            </div>
        </div>
    );
}
