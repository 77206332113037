import classNames from 'classnames';
import React from 'react';
import {Intent} from '../constants';

export function Card(props) {
    const {
        children,
        className,
        dataTestId,
        intent = Intent.NONE,
        role,
    } = props;

    const classes = classNames('card', className, `intent-${intent}`);

    return (
        <div
            className = {classes}
            data-test-id = {dataTestId}
            role = {role}
        >
            {children}
        </div>
    );
}
