import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {ACCESS_CODE_KEY} from './sessionStorageKeys';

export const getAccessCode = () => sessionStorage.getItem(ACCESS_CODE_KEY);

export const setAccessCode = (accessCode) => {
    sessionStorage.setItem(ACCESS_CODE_KEY, accessCode);
};

export const removeAccessCode = () => {
    sessionStorage.removeItem(ACCESS_CODE_KEY);
};

// Ensures that an access code is present, otherwise takes the user to the landing page...
export const useAccessCode = () => {
    const history = useHistory();

    const accessCode = sessionStorage.getItem(ACCESS_CODE_KEY);

    useEffect(() => {
        if (!accessCode) {
            history.push('/', {invalidAccessCode: true});
        }
    }, [accessCode]);

    return {
        accessCode,
        removeAccessCode,
        setAccessCode,
    };
};
