import {
    useCallback,
    useEffect,
    useReducer,
} from 'react';
import {useIntl} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {
    action,
    KIT_REGISTRATION_STORAGE_KEY,
    UPDATE_VALUE,
    useStore,
    useTitle,
} from '../../../common';
import {Loading} from '../../../components';
import {SampleCollectionDate} from './SampleCollectionDate';
import {SampleId} from './SampleId';

const LOAD_KIT_REGISTRATION_DATA = 'LOAD_KIT_REGISTRATION_DATA';
const SET_REHYDRATING = 'SET_REHYDRATING';

const steps = [SampleId, SampleCollectionDate];

const initialState = {
    kitRegistrationData: {
        dateSelection: '',
        sampleCollected: {
            day: '',
            month: '',
            year: '',
        },
        sampleId: '',
    },
    rehydrating: true,
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_VALUE:
            return {
                ...state,
                kitRegistrationData: {
                    ...state.kitRegistrationData,
                    ...payload,
                },
            };

        case LOAD_KIT_REGISTRATION_DATA:
            return {
                ...state,
                kitRegistrationData: payload,
            };

        case SET_REHYDRATING:
            return {
                ...state,
                rehydrating: payload,
            };

        default:
            return state;
    }
};

export function RegisterYourKit(props) {
    const intl = useIntl();
    const {state: {currentStep = 1} = {}} = useLocation();

    const [{kitRegistrationData, rehydrating}, dispatch] = useReducer(reducer, initialState);

    const store = useStore();

    useEffect(() => {
        dispatch(action(SET_REHYDRATING, true));
        try {
            const data = JSON.parse(store.get(KIT_REGISTRATION_STORAGE_KEY));
            if (data) {
                dispatch(action(LOAD_KIT_REGISTRATION_DATA, data));
            }
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
        finally {
            dispatch(action(SET_REHYDRATING, false));
        }
    }, []);

    useEffect(() => {
        try {
            store.set(KIT_REGISTRATION_STORAGE_KEY, JSON.stringify(kitRegistrationData));
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        }
    }, [kitRegistrationData]);

    const updateKitRegistrationData = useCallback((data) => {
        dispatch(action(UPDATE_VALUE, data));
    }, []);

    useTitle(intl.formatMessage({id: `dashboard.registerYourKit.documentTitle`}));

    const KitRegistrationComponent = steps[currentStep - 1];

    if (rehydrating) {
        return <Loading withPageLayout = {true} />;
    }

    return (
        <KitRegistrationComponent
            kitRegistrationData = {kitRegistrationData}
            updateKitRegistrationData = {updateKitRegistrationData}
            {...props}
        />
    );
}
