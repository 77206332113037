import {
    useCallback,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Button,
    ErrorCard,
    Link,
    TextInput,
} from '../../../../denim';
import {useApi} from '../../../auth0';
import {
    action,
    UPDATE_VALUE,
} from '../../../common';
import {Divider} from '../../../components';
import {useMinervaConfig} from '../../../config';
import {useRegistrationContext} from '../RegistrationContext';
import {Docusign} from './Docusign';

const SET_FETCHING_DOCUSIGN_URL = 'SET_FETCHING_DOCUSIGN_URL';
const FETCH_DOCUSIGN_URL_ERROR = 'FETCH_DOCUSIGN_URL_ERROR';
const SET_FIRST_NAME_ERROR_MESSAGE = 'SET_FIRST_NAME_ERROR_MESSAGE';
const SET_LAST_NAME_ERROR_MESSAGE = 'SET_LAST_NAME_ERROR_MESSAGE';
const SET_DOCUSIGN_URL = 'SET_DOCUSIGN_URL';

const init = ({firstName, lastName}) => ({
    docusignUrl: '',
    error: false,
    fetchingDocusignUrl: false,
    firstName: firstName,
    firstNameErrorMessage: null,
    lastName: lastName,
    lastNameErrorMessage: null,
});

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_FIRST_NAME_ERROR_MESSAGE:
            return {
                ...state,
                firstNameErrorMessage: payload,
            };

        case SET_LAST_NAME_ERROR_MESSAGE:
            return {
                ...state,
                lastNameErrorMessage: payload,
            };

        case SET_FETCHING_DOCUSIGN_URL:
            return {
                ...state,
                error: payload ? false : state.error,
                fetchingDocusignUrl: payload,
            };

        case FETCH_DOCUSIGN_URL_ERROR:
            return {
                ...state,
                error: true,
                fetchingDocusignUrl: false,
            };

        case SET_DOCUSIGN_URL:
            return {
                ...state,
                docusignUrl: payload,
            };

        case UPDATE_VALUE:
            return {
                ...state,
                [payload.name]: payload.value,
            };

        default:
            return state;
    }
};

export function Name() {
    const intl = useIntl();
    const {
        previousStep,
        state: {data: {aboutYou = {}} = {}} = {},
        updateAboutYouField,
    } = useRegistrationContext() ?? {};
    const [authenticatedFetch] = useApi();
    const minervaConfig = useMinervaConfig();
    const [
        {
            docusignUrl,
            error,
            fetchingDocusignUrl,
            firstName,
            firstNameErrorMessage,
            lastName,
            lastNameErrorMessage,
        },
        dispatch,
    ] = useReducer(reducer, aboutYou, init);

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const onBlurHandler = useCallback((value, name) => {
        let errorMessageToDisplay = intl.formatMessage({id: 'registration.common.error.required.text'});

        if (value && !/^[^0-9]*$/m.test(value)) {
            errorMessageToDisplay = intl.formatMessage({id: 'registration.common.name.error.incorrectFormat.text'});
        }

        if (name === 'firstName') {
            dispatch(action(SET_FIRST_NAME_ERROR_MESSAGE, errorMessageToDisplay));
        }
        else {
            dispatch(action(SET_LAST_NAME_ERROR_MESSAGE, errorMessageToDisplay));
        }
    }, []);

    const onChangeHandler = useCallback((value, name) => {
        dispatch(
            action(UPDATE_VALUE, {
                name,
                value,
            })
        );
    }, []);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();
            // Check validity cause an invalid event to be triggered on anything that is invalid, which will cause them
            // to display their error state.
            const form = event.target;
            const formValid = form.checkValidity();

            if (formValid) {
                if (fetchingDocusignUrl) {
                    return;
                }

                try {
                    dispatch(action(SET_FETCHING_DOCUSIGN_URL, true));

                    const response = await authenticatedFetch(
                        `${minervaConfig.servicesBackend}/policyholders/me/econsent?firstName=${firstName}&lastName=${lastName}`
                    );

                    if (response.ok) {
                        const data = await response.json();

                        updateAboutYouField('firstName', firstName);
                        updateAboutYouField('lastName', lastName);
                        dispatch(action(SET_DOCUSIGN_URL, data.url));
                    }
                    else {
                        dispatch(action(FETCH_DOCUSIGN_URL_ERROR));
                    }
                }
                catch (err) {
                    dispatch(action(FETCH_DOCUSIGN_URL_ERROR));
                }
            }
            else {
                // Change displayed error message based on type of error (empty string or invalid format)
                dispatch(
                    action(
                        SET_FIRST_NAME_ERROR_MESSAGE,
                        firstName
                            ? intl.formatMessage({id: 'registration.common.name.error.incorrectFormat.text'})
                            : intl.formatMessage({id: 'registration.common.error.required.text'})
                    )
                );

                // Change displayed error message based on type of error (empty string or invalid format)
                dispatch(
                    action(
                        SET_LAST_NAME_ERROR_MESSAGE,
                        lastName
                            ? intl.formatMessage({id: 'registration.common.name.error.incorrectFormat.text'})
                            : intl.formatMessage({id: 'registration.common.error.required.text'})
                    )
                );
            }
        },
        [fetchingDocusignUrl, firstName, lastName]
    );

    const icon = fetchingDocusignUrl ? 'spinner' : 'launch';

    if (docusignUrl) {
        return <Docusign docusignUrl = {docusignUrl} />;
    }

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage id = {'registration.studyConsent.name.question.text'} />
                </h2>
                <p className = {'description'}>
                    <FormattedMessage id = {'registration.studyConsent.name.question.description'} />
                </p>
            </div>
            <Divider />
            {error && (
                <ErrorCard
                    className = {'api-error'}
                    title = {intl.formatMessage({id: 'common.error.title.text'})}
                >
                    <p>
                        <FormattedMessage id = {'common.error.genericApiError.text'} />
                    </p>
                    <p>
                        <FormattedMessage
                            id = {'common.error.contactSupport.text'}
                            values = {{
                                link: (chunks) => (
                                    <Link
                                        rel = {'noreferrer'}
                                        target = {'_blank'}
                                        url = {intl.formatMessage({id: 'support.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </ErrorCard>
            )}
            <div className = {'question-options text-based-input'}>
                <form
                    noValidate = {true}
                    onSubmit = {onSubmitHandler}
                >
                    <TextInput
                        dataTestId = {'firstname-input'}
                        errorMessage = {firstNameErrorMessage}
                        id = {'firstname'}
                        label = {intl.formatMessage({id: 'registration.common.name.firstName.label'})}
                        name = {'firstName'}
                        onBlur = {onBlurHandler}
                        onChange = {onChangeHandler}
                        pattern = {'[^0-9]*'}
                        required = {true}
                        value = {firstName}
                    />
                    <TextInput
                        dataTestId = {'lastname-input'}
                        errorMessage = {lastNameErrorMessage}
                        id = {'lastName'}
                        label = {intl.formatMessage({id: 'registration.common.name.lastName.label'})}
                        name = {'lastName'}
                        onBlur = {onBlurHandler}
                        onChange = {onChangeHandler}
                        pattern = {'[^0-9]*'}
                        required = {true}
                        value = {lastName}
                    />
                    <Button
                        dataTestId = {'review-button'}
                        icon = {icon}
                        isLoading = {fetchingDocusignUrl}
                        submit = {true}
                    >
                        <FormattedMessage id = {'registration.studyConsent.signDocuments.button.text'} />
                    </Button>
                </form>
            </div>
            <p className = {'is-caption is-dark'}>
                <FormattedMessage
                    id = {'registration.studyConsent.docuSignInformation.text'}
                    values = {{bold: (chunks) => <b>{chunks}</b>}}
                />
            </p>
        </div>
    );
}
