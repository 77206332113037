import {useCallback} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {
    Button,
    Link,
    Nav,
    Variant,
} from '../../../denim';
import {MenuContent} from '../../common';
import {
    Divider,
    GridContainer,
    LegalFooterSection,
} from '../../components';

export function ErrorNotice(props) {
    const {errorCode, fullPage = false} = props;

    const intl = useIntl();

    const onClickHandler = useCallback((event) => {
        event.preventDefault();
        location.reload();
    }, []);

    const paragraphOneId = fullPage
        ? 'encounteredError.paragraph.one.fullPage.text'
        : 'encounteredError.paragraph.one.text';

    const gridContainerRole = {};
    if (fullPage) {
        gridContainerRole.role = 'main';
    }

    return (
        <>
            {fullPage && (
                <header
                    aria-label = {'Page header'}
                    className = {'page-header'}
                    role = {'banner'}
                >
                    <Nav
                        closeLinkAriaLabel = {intl.formatMessage({id: 'common.close.label'})}
                        footerContent = {<LegalFooterSection />}
                        openLinkAriaLabel = {intl.formatMessage({id: 'common.open.label'})}
                        variant = {Variant.LIGHT}
                    >
                        <MenuContent />
                    </Nav>
                </header>
            )}
            <GridContainer
                containerClassName = {'error-notice-grid'}
                {...gridContainerRole}
            >
                <div className = {'error-notice-content'}>
                    <h3>
                        <FormattedMessage id = {'encounteredError.title.text'} />
                    </h3>
                    {errorCode && (
                        <p className = {'is-eyebrow is-small error-code'}>
                            <FormattedMessage
                                id = {'encounteredError.errorCode.text'}
                                values = {{errorCode}}
                            />
                        </p>
                    )}
                    <p>
                        <FormattedMessage id = {paragraphOneId} />
                    </p>
                    {!fullPage && (
                        <Button
                            onClick = {onClickHandler}
                            primary = {false}
                        >
                            <FormattedMessage id = {'encounteredError.reload.button.text'} />
                        </Button>
                    )}
                    <p>
                        <FormattedMessage id = {'encounteredError.paragraph.two.text'} />
                    </p>
                    <div className = {'contact-link is-heavy'}>
                        <FormattedMessage
                            id = {'encounteredError.contactStudyCoordinator.email.text'}
                            values = {{
                                link: (chunks) => (
                                    <Link
                                        rel = {'noreferrer'}
                                        target = {'_blank'}
                                        url = {intl.formatMessage({id: 'encounteredError.contactStudyCoordinator.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </div>
                    <div className = {'contact-link is-heavy'}>
                        <FormattedMessage
                            id = {'encounteredError.contactStudyCoordinator.phone.text'}
                            values = {{
                                phone: (chunks) => (
                                    <Link
                                        rel = {'noreferrer'}
                                        target = {'_blank'}
                                        url = {intl.formatMessage({id: 'encounteredError.contactStudyCoordinator.phone'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </div>
                    {fullPage && (
                        <>
                            <Divider />
                            <div className = {'is-caption'}>
                                <FormattedMessage id = {'notFound.usefulLinks.text'} />
                            </div>

                            <div className = {'useful-links'}>
                                <InternalLink
                                    className = {'link is-caption is-heavy'}
                                    to = {'/'}
                                >
                                    <span>
                                        <FormattedMessage id = {'notFound.usefulLink.home.text'} />
                                    </span>
                                </InternalLink>

                                <div className = {'is-small useful-links-separator'}>{'/'}</div>

                                <InternalLink
                                    className = {'link is-caption is-heavy'}
                                    to = {'/report'}
                                >
                                    <span>
                                        <FormattedMessage id = {'notFound.usefulLink.dashboard.text'} />
                                    </span>
                                </InternalLink>

                                <div className = {'is-small useful-links-separator'}>{'/'}</div>

                                <InternalLink
                                    className = {'link is-caption is-heavy'}
                                    to = {'/frequently-asked-questions'}
                                >
                                    <span>
                                        <FormattedMessage id = {'notFound.usefulLink.helpCenter.text'} />
                                    </span>
                                </InternalLink>
                            </div>
                        </>
                    )}
                </div>
                <img
                    role = {'presentation'}
                    src = {'/images/slatted-circle-gradient.svg'}
                />
            </GridContainer>
        </>
    );
}
