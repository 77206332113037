const DAY_MONTH_LENGTH = 2;
const YEAR_LENGTH = 4;
const DAY_MIN_VALUE = 1;
const DAY_MAX_VALUE = 31;
const MONTH_MIN_VALUE = 1;
const MONTH_MAX_VALUE = 12;

const inRange = (number, start, end) => number >= start && number <= end;

export const preventDefaultHandler = (event) => {
    event.preventDefault();
    return false;
};

export const todayInMilliseconds = () => {
    // Get today as a Date...
    const today = new Date(Date.now());

    // Ensure the day is two digits...
    const utcDate =
        `${today.getUTCDate()}`.length === 1 ? `0${today.getUTCDate()}` : today.getUTCDate();

    // Get the month, remembering that January is 0, not 1, so increment...
    let utcMonth = today.getUTCMonth() + 1;

    // We need to pass the month with a leading 0, i.e. 07, otherwise we get a different number to what we're expecting...
    utcMonth = `${utcMonth}`.length === 1 ? `0${utcMonth}` : utcMonth;

    // Parse the year, month  and day into milliseconds from 01/01/1970...
    return Date.parse(`${today.getUTCFullYear()}-${utcMonth}-${utcDate}`);
};

export const monthValueToMonthNameMap = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    10: 'October',
    11: 'November',
    12: 'December',
};

export const calculateDateInputValidity = (day, month, year) => {
    // Decide what error message to display...
    const calculatedValidity = {
        countOfBlankFields: 0,
        countOfInvalidFields: 0,
        countOfPatternMismatchFields: 0,
        day: {
            blank: false,
            invalid: false,
            pattern: false,
        },
        month: {
            blank: false,
            invalid: false,
            pattern: false,
        },
        year: {
            blank: false,
            invalid: false,
            pattern: false,
        },
    };

    if (day) {
        // Check it's a number, or if it is not in the range 1 - 31 inclusive
        const number = Number.parseInt(day, 10);
        if (Number.isNaN(number) || !inRange(number, DAY_MIN_VALUE, DAY_MAX_VALUE)) {
            calculatedValidity.countOfInvalidFields += 1;
            calculatedValidity.day.invalid = true;
        }
        else if (day.length !== DAY_MONTH_LENGTH) {
            calculatedValidity.countOfPatternMismatchFields += 1;
            calculatedValidity.day.pattern = true;
        }
    }
    else {
        // It's blank, record that...
        calculatedValidity.countOfBlankFields += 1;
        calculatedValidity.day.blank = true;
    }

    if (month) {
        // Check it's a number...
        const number = Number.parseInt(month, 10);
        if (Number.isNaN(number) || !inRange(number, MONTH_MIN_VALUE, MONTH_MAX_VALUE)) {
            calculatedValidity.countOfInvalidFields += 1;
            calculatedValidity.month.invalid = true;
        }
        else if (month.length !== DAY_MONTH_LENGTH) {
            calculatedValidity.countOfPatternMismatchFields += 1;
            calculatedValidity.month.pattern = true;
        }
    }
    else {
        // It's blank, record that...
        calculatedValidity.countOfBlankFields += 1;
        calculatedValidity.month.blank = true;
    }

    if (year) {
        // Check it's a number...
        const number = Number.parseInt(year, 10);
        if (Number.isNaN(number)) {
            calculatedValidity.countOfInvalidFields += 1;
            calculatedValidity.year.invalid = true;
        }
        else if (year.length !== YEAR_LENGTH) {
            calculatedValidity.countOfPatternMismatchFields += 1;
            calculatedValidity.year.pattern = true;
        }
    }
    else {
        // It's blank, record that...
        calculatedValidity.countOfBlankFields += 1;
        calculatedValidity.year.blank = true;
    }

    return calculatedValidity;
};

export const convertCalculatedValidityToErrorMessage = (
    values,
    calculatedValidity,
    errorMessagePrefix,
    intl
) => {
    if (calculatedValidity.countOfBlankFields >= 2) {
        // More than one field is empty, so display a generic message...
        return intl.formatMessage({id: `${errorMessagePrefix}.error.required.text`});
    }
    else if (calculatedValidity.countOfBlankFields === 1) {
        // Only one field is empty, so display a specific error message...
        const fieldName = calculatedValidity.day.blank
            ? 'day'
            : calculatedValidity.month.blank
                ? 'month'
                : 'year';
        return intl.formatMessage({id: `${errorMessagePrefix}.error.required-${fieldName}.text`});
    }
    else if (calculatedValidity.countOfInvalidFields >= 2) {
        // More than one field is invalid, so display a generic message...
        return intl.formatMessage({id: `${errorMessagePrefix}.error.invalid.text`});
    }
    else if (calculatedValidity.countOfInvalidFields === 1) {
        // Check the fields are valid numbers: month, then day, then year...
        const fieldName = calculatedValidity.month.invalid
            ? 'month'
            : calculatedValidity.day.invalid
                ? 'day'
                : 'year';
        return intl.formatMessage(
            {id: `${errorMessagePrefix}.error.invalid-${fieldName}.text`},
            {value: values[fieldName]}
        );
    }
    else if (calculatedValidity.countOfPatternMismatchFields >= 1) {
        // Check the fields are of valid length, 2 digits for month and day,four for year...
        const fieldName = calculatedValidity.month.pattern
            ? 'month'
            : calculatedValidity.day.pattern
                ? 'day'
                : 'year';
        return intl.formatMessage(
            {id: `${errorMessagePrefix}.error.pattern-${fieldName}.text`},
            {value: values[fieldName]}
        );
    }

    return null;
};

export function calculateAge(dateOfBirth) {
    const today = new Date();
    const birthday = new Date(`${dateOfBirth.month}/${dateOfBirth.day}/${today.getUTCFullYear()}`);

    let age = today.getUTCFullYear() - dateOfBirth.year;
    if (today < birthday) {
        age--;
    }

    return age;
}
