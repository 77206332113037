import {
    useCallback,
    useLayoutEffect,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {
    Link,
    Nav,
    Variant,
} from '../../../denim';
import {useTitle} from '../../common';
import {
    GridContainer,
    LandingPageFooter,
    LegalFooterSection,
} from '../../components';

export function StudyPrivacyNotice() {
    const intl = useIntl();
    const history = useHistory();

    useLayoutEffect(() => {
        // When someone comes in via a link, they probably want to start at the top of the page, not halfway down...
        window.scrollTo(0, 0);
    }, []);

    useTitle(intl.formatMessage({id: 'studyPrivacyNotice.documentTitle'}));

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        history.goBack();
    }, []);

    return (
        <>
            <header
                aria-label = {'Page header'}
                className = {'page-header'}
                role = {'banner'}
            >
                <Nav
                    closeLinkAriaLabel = {intl.formatMessage({id: 'common.close.label'})}
                    footerContent = {<LegalFooterSection />}
                    openLinkAriaLabel = {intl.formatMessage({id: 'common.open.label'})}
                    variant = {Variant.LIGHT}
                >
                    <ul role = {'menu'}>
                        <li
                            className = {'after icon launch'}
                            role = {'menuitem'}
                        >
                            <Link
                                target = {'_blank'}
                                url = {'/frequently-asked-questions'}
                            >
                                <FormattedMessage
                                    id = {'report.navigation.links.geneticCounseling.text'}
                                />
                            </Link>
                        </li>
                    </ul>
                </Nav>
            </header>
            <section
                className = {'page-content static-content'}
                role = {'main'}
            >
                <GridContainer containerClassName = {'content-section'}>
                    <div className = {'page-title'}>
                        <Link
                            before = {true}
                            icon = {'chevron--left'}
                            onClick = {onBackClickHandler}
                        >
                            <FormattedMessage id = {'staticPages.common.link.back.text'} />
                        </Link>
                        <h1>
                            <FormattedMessage id = {'studyPrivacyNotice.pageTitle.text'} />
                        </h1>
                    </div>
                </GridContainer>
                <GridContainer
                    containerClassName = {'content-section'}
                    variant = {Variant.LIGHT}
                >
                    <div className = {'content-item'}>
                        <h1 id = {'overview'}>
                            <FormattedMessage id = {'studyPrivacyNotice.overview.title.text'} />
                        </h1>
                        <p>
                            <FormattedMessage id = {'studyPrivacyNotice.overview.paragraph.one.text'} />
                        </p>
                        <ul>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.overview.item.one.text'}
                                    values = {{
                                        strong: (chunks) => <strong>{chunks}</strong>,
                                        underline: (chunks) => (
                                            <span className = {'is-underlined'}>{chunks}</span>
                                        ),
                                    }}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.overview.item.two.text'}
                                    values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.overview.item.three.text'}
                                    values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                />
                            </li>
                        </ul>
                        <p>
                            <FormattedMessage id = {'studyPrivacyNotice.overview.paragraph.two.text'} />
                        </p>
                    </div>
                    <div className = {'content-item'}>
                        <h1 id = {'introduction'}>
                            <FormattedMessage id = {'studyPrivacyNotice.introduction.title.text'} />
                        </h1>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.introduction.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                    </div>
                    <div className = {'content-item'}>
                        <h1 id = {'genomics'}>
                            <FormattedMessage id = {'studyPrivacyNotice.genomics.title.text'} />
                        </h1>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.genomics.paragraph.one.text'}
                                values = {{
                                    em: (chunks) => <em>{chunks}</em>,
                                    strong: (chunks) => <strong>{chunks}</strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage id = {'studyPrivacyNotice.genomics.paragraph.two.text'} />
                        </p>
                    </div>
                    <div className = {'content-item'}>
                        <h1 id = {'approved-study'}>
                            <FormattedMessage id = {'studyPrivacyNotice.approvedStudy.title.text'} />
                        </h1>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.approvedStudy.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                    </div>
                    <div className = {'content-item'}>
                        <h1 id = {'use-of-data'}>
                            <FormattedMessage id = {'studyPrivacyNotice.useOfData.title.text'} />
                        </h1>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.useOfData.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <h2 id = {'saliva-sample'}>
                            <FormattedMessage id = {'studyPrivacyNotice.salivaSample.subtitle.text'} />
                        </h2>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.salivaSample.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.salivaSample.paragraph.two.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link url = {'/study-privacy-notice#infiniti-biology'}>
                                            {chunks}
                                        </Link>
                                    ),
                                    strong: (chunks) => <strong>{chunks}</strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.salivaSample.paragraph.three.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.salivaSample.paragraph.four.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link url = {'/study-privacy-notice#infiniti-biology'}>
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                        <h2 id = {'your-dna-data'}>
                            <FormattedMessage id = {'studyPrivacyNotice.yourDNAData.subtitle.text'} />
                        </h2>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourDNAData.paragraph.one.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link url = {'/study-privacy-notice#your-risk-score'}>
                                            {chunks}
                                        </Link>
                                    ),
                                    strong: (chunks) => <strong>{chunks}</strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourDNAData.paragraph.two.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourDNAData.paragraph.three.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link url = {'/study-privacy-notice#infiniti-biology'}>
                                            {chunks}
                                        </Link>
                                    ),
                                    strong: (chunks) => <strong>{chunks}</strong>,
                                }}
                            />
                        </p>
                        <h2 id = {'your-risk-score'}>
                            <FormattedMessage id = {'studyPrivacyNotice.yourRiskScore.subtitle.text'} />
                        </h2>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRiskScore.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRiskScore.paragraph.two.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link url = {'/study-privacy-notice#genome-medical'}>
                                            {chunks}
                                        </Link>
                                    ),
                                    strong: (chunks) => <strong>{chunks}</strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRiskScore.paragraph.three.text'}
                                values = {{
                                    em: (chunks) => <em>{chunks}</em>,
                                    strong: (chunks) => <strong>{chunks}</strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRiskScore.paragraph.four.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRiskScore.paragraph.five.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link url = {'/study-privacy-notice#genome-medical'}>
                                            {chunks}
                                        </Link>
                                    ),
                                    strong: (chunks) => <strong>{chunks}</strong>,
                                }}
                            />
                        </p>
                        <h2 id = {'other-data'}>
                            <FormattedMessage id = {'studyPrivacyNotice.otherData.subtitle.text'} />
                        </h2>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.otherData.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.otherData.paragraph.two.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link url = {'/study-privacy-notice#genome-medical'}>
                                            {chunks}
                                        </Link>
                                    ),
                                    strong: (chunks) => <strong>{chunks}</strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.otherData.paragraph.three.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.otherData.paragraph.four.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.otherData.paragraph.five.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <ul>
                            <li>
                                <FormattedMessage id = {'studyPrivacyNotice.otherData.item.one.text'} />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.otherData.item.two.text'}
                                    values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.otherData.item.three.text'}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.otherData.item.four.text'}
                                    values = {{
                                        link: (chunks) => (
                                            <Link url = {'/study-privacy-notice#our-partners'}>
                                                {chunks}
                                            </Link>
                                        ),
                                    }}
                                />
                            </li>
                            <li>
                                <FormattedMessage id = {'studyPrivacyNotice.otherData.item.five.text'} />
                            </li>
                            <li>
                                <FormattedMessage id = {'studyPrivacyNotice.otherData.item.six.text'} />
                            </li>
                        </ul>
                    </div>
                    <div className = {'content-item'}>
                        <h1 id = {'our-partners'}>
                            <FormattedMessage id = {'studyPrivacyNotice.ourPartners.title.text'} />
                        </h1>
                        <h2 id = {'mass-mutual'}>
                            <FormattedMessage id = {'studyPrivacyNotice.massMutual.subtitle.text'} />
                        </h2>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.massMutual.paragraph.one.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.massMutual.paragraph.two.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.massMutual.paragraph.three.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <h2 id = {'laboratory-and-telehealth-services'}>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.laboratoryAndTelehealth.subtitle.text'}
                            />
                        </h2>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.laboratoryAndTelehealth.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.laboratoryAndTelehealth.paragraph.two.text'}
                            />
                        </p>
                        <h3 id = {'genome-medical'}>
                            <FormattedMessage id = {'studyPrivacyNotice.genomeMedical.subtitle.text'} />
                        </h3>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.genomeMedical.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.genomeMedical.paragraph.two.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.genomeMedical.paragraph.three.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.genomeMedical.paragraph.four.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.genomeMedical.paragraph.five.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.genomeMedical.paragraph.six.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <h3 id = {'infiniti-biology'}>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.infinitiBiology.subtitle.text'}
                            />
                        </h3>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.infinitiBiology.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.infinitiBiology.paragraph.two.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.infinitiBiology.paragraph.three.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <h3 id = {'spectrum-solutions'}>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.spectrumSolutions.subtitle.text'}
                            />
                        </h3>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.spectrumSolutions.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <h2 id = {'institutional-review-board'}>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.institutionalReviewBoard.subtitle.text'}
                            />
                        </h2>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.institutionalReviewBoard.paragraph.one.text'}
                            />
                        </p>
                        <h2 id = {'other-service-providers'}>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.otherServiceProviders.subtitle.text'}
                            />
                        </h2>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.otherServiceProviders.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                    </div>
                    <div className = {'content-table-container'}>
                        <table id = {'other-service-providers-table'}>
                            <thead>
                                <tr>
                                    <th
                                        scope = {'col'}
                                        width = {'33%'}
                                    >
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.header.one.text'
                                            }
                                        />
                                    </th>
                                    <th
                                        scope = {'col'}
                                        width = {'33%'}
                                    >
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.header.two.text'
                                            }
                                        />
                                    </th>
                                    <th
                                        scope = {'col'}
                                        width = {'33%'}
                                    >
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.header.three.text'
                                            }
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.one.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.one.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.one.col.three.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.two.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.two.col.two.text'
                                            }
                                            values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.two.col.three.text'
                                            }
                                            values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.three.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.three.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.three.col.three.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.four.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.four.col.two.text'
                                            }
                                            values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.four.col.three.text'
                                            }
                                            values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.five.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.five.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.five.col.three.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.six.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.six.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.six.col.three.text'
                                            }
                                            values = {{
                                                br: () => <br />,
                                                em: (chunks) => <em>{chunks}</em>,
                                            }}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.seven.col.one.text'
                                            }
                                            values = {{
                                                span: (chunks) => (
                                                    <span className = {'subtext'}>{chunks}</span>
                                                ),
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.seven.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.seven.col.three.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.eight.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.eight.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.otherServiceProviders.table.row.eight.col.three.text'
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className = {'content-item'}>
                        <h1 id = {'data-use-overview'}>
                            <FormattedMessage id = {'studyPrivacyNotice.dataUseOverview.title.text'} />
                        </h1>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.dataUseOverview.paragraph.one.text'}
                            />
                        </p>
                    </div>
                    <div className = {'content-table-container'}>
                        <table id = {'data-use-overview-table'}>
                            <thead>
                                <tr>
                                    <th scope = {'col'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.header.one.text'
                                            }
                                        />
                                    </th>
                                    <th
                                        scope = {'col'}
                                        width = {'27%'}
                                    >
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.header.two.text'
                                            }
                                        />
                                    </th>
                                    <th
                                        scope = {'col'}
                                        width = {'27%'}
                                    >
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.header.three.text'
                                            }
                                        />
                                    </th>
                                    <th
                                        scope = {'col'}
                                        width = {'27%'}
                                    >
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.header.four.text'
                                            }
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td rowSpan = {'10'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.two.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.two.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.two.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.three.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.three.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.three.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.four.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.four.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.four.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.five.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.five.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.five.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.six.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.six.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.six.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.seven.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.seven.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.seven.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eight.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eight.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eight.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.nine.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.nine.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.nine.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.one.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.ten.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.ten.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.ten.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan = {'6'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eleven.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eleven.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eleven.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eleven.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eleven.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twelve.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twelve.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twelve.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eleven.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.thirteen.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.thirteen.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.thirteen.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eleven.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.fourteen.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.fourteen.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.fourteen.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eleven.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.fifteen.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.fifteen.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.fifteen.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eleven.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.sixteen.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.sixteen.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.sixteen.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan = {'2'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.seventeen.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.seventeen.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.seventeen.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.seventeen.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.seventeen.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eighteen.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eighteen.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.eighteen.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan = {'5'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.nineteen.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.nineteen.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.nineteen.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.nineteen.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.nineteen.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twenty.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twenty.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twenty.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.nineteen.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyOne.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyOne.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyOne.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.nineteen.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyTwo.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyTwo.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyTwo.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.nineteen.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyThree.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyThree.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyThree.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyFour.col.one.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyFour.col.two.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyFour.col.three.text'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.dataUseOverview.table.row.twentyFour.col.four.text'
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <ul className = {'no-decoration'}>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.dataUseOverview.table.legend.item.one'}
                                    values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.dataUseOverview.table.legend.item.two'}
                                    values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.dataUseOverview.table.legend.item.three'}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.dataUseOverview.table.legend.item.four'}
                                />
                            </li>
                        </ul>
                    </div>
                    <div className = {'content-item'}>
                        <h1 id = {'requests-to-share'}>
                            <FormattedMessage id = {'studyPrivacyNotice.requestsToShare.title.text'} />
                        </h1>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.requestsToShare.paragraph.one.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.requestsToShare.paragraph.two.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.requestsToShare.paragraph.three.text'}
                            />
                        </p>
                        <div className = {'content-table-container'}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>
                                            <FormattedMessage
                                                id = {
                                                    'studyPrivacyNotice.requestsToShare.table.row.one.col.one.text'
                                                }
                                            />
                                        </td>
                                        <td>
                                            <FormattedMessage
                                                id = {
                                                    'studyPrivacyNotice.requestsToShare.table.row.one.col.two.text'
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage
                                                id = {
                                                    'studyPrivacyNotice.requestsToShare.table.row.two.col.one.text'
                                                }
                                            />
                                        </td>
                                        <td>
                                            <FormattedMessage
                                                id = {
                                                    'studyPrivacyNotice.requestsToShare.table.row.two.col.two.text'
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage
                                                id = {
                                                    'studyPrivacyNotice.requestsToShare.table.row.three.col.one.text'
                                                }
                                            />
                                        </td>
                                        <td>
                                            <FormattedMessage
                                                id = {
                                                    'studyPrivacyNotice.requestsToShare.table.row.three.col.two.text'
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <FormattedMessage
                                                id = {
                                                    'studyPrivacyNotice.requestsToShare.table.row.four.col.one.text'
                                                }
                                            />
                                        </td>
                                        <td>
                                            <FormattedMessage
                                                id = {
                                                    'studyPrivacyNotice.requestsToShare.table.row.four.col.two.text'
                                                }
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.requestsToShare.paragraph.four.text'}
                            />
                        </p>
                        <ul>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.requestsToShare.item.one.text'}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.requestsToShare.item.two.text'}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'studyPrivacyNotice.requestsToShare.item.three.text'}
                                />
                            </li>
                        </ul>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.requestsToShare.paragraph.five.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.requestsToShare.paragraph.six.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {'https://grants.nih.gov/policy/humansubjects/coc.htm'}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                        <h2 id = {'privacy-laws'}>
                            <FormattedMessage id = {'studyPrivacyNotice.privacyLaws.title.text'} />
                        </h2>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.privacyLaws.paragraph.one.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.privacyLaws.paragraph.two.text'}
                                values = {{em: (chunks) => <em>{chunks}</em>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.privacyLaws.paragraph.three.text'}
                                values = {{
                                    em: (chunks) => <em>{chunks}</em>,
                                    strong: (chunks) => <strong>{chunks}</strong>,
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.privacyLaws.paragraph.four.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.privacyLaws.paragraph.five.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.privacyLaws.paragraph.six.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <h2 id = {'our-responsibilities'}>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.ourResponsibilities.title.text'}
                            />
                        </h2>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.ourResponsibilities.paragraph.one.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {
                                    'studyPrivacyNotice.ourResponsibilities.description.one.title.text'
                                }
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.ourResponsibilities.description.one.body.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {
                                    'studyPrivacyNotice.ourResponsibilities.description.two.title.text'
                                }
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.ourResponsibilities.description.two.body.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {
                                    'studyPrivacyNotice.ourResponsibilities.description.three.title.text'
                                }
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {
                                    'studyPrivacyNotice.ourResponsibilities.description.three.body.text'
                                }
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <ul>
                            <li>
                                <FormattedMessage
                                    id = {
                                        'studyPrivacyNotice.ourResponsibilities.description.three.item.one.text'
                                    }
                                    values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {
                                        'studyPrivacyNotice.ourResponsibilities.description.three.item.two.text'
                                    }
                                    values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {
                                        'studyPrivacyNotice.ourResponsibilities.description.three.item.three.text'
                                    }
                                    values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {
                                        'studyPrivacyNotice.ourResponsibilities.description.three.item.four.text'
                                    }
                                    values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {
                                        'studyPrivacyNotice.ourResponsibilities.description.three.item.five.text'
                                    }
                                    values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                />
                                <ul>
                                    <li>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.ourResponsibilities.description.three.item.five.subitem.one.text'
                                            }
                                            values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                    </li>
                                    <li>
                                        <FormattedMessage
                                            id = {
                                                'studyPrivacyNotice.ourResponsibilities.description.three.item.five.subitem.two.text'
                                            }
                                            values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                                        />
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <strong>
                            <FormattedMessage
                                id = {
                                    'studyPrivacyNotice.ourResponsibilities.description.four.title.text'
                                }
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {
                                    'studyPrivacyNotice.ourResponsibilities.description.four.body.text'
                                }
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {
                                    'studyPrivacyNotice.ourResponsibilities.description.five.title.text'
                                }
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {
                                    'studyPrivacyNotice.ourResponsibilities.description.five.body.text'
                                }
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <h2 id = {'your-rights'}>
                            <FormattedMessage id = {'studyPrivacyNotice.yourRights.title.text'} />
                        </h2>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.paragraph.one.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.paragraph.two.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.paragraph.three.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.one.title.text'}
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.one.body.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.two.title.text'}
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.two.body.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.three.title.text'}
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.three.body.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.four.title.text'}
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.four.body.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.four.body.two.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.five.title.text'}
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.five.body.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.five.body.two.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.six.title.text'}
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.six.body.text'}
                                values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.seven.title.text'}
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.seven.body.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.eight.title.text'}
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.eight.body.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.nine.title.text'}
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.nine.body.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'advarra.email'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                    phone: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'advarra.phone'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.nine.body.two.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'dataProtection.email'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.nine.body.three.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'informationCommissioner.website'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                    phone: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'informationCommissioner.phone'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.nine.body.four.text'}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.nine.body.five.text'}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.ten.title.text'}
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.ten.body.text'}
                                values = {{
                                    link: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'support.email'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                    linkTwo: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'dataProtection.email'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                        <strong>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.eleven.title.text'}
                            />
                        </strong>
                        <p>
                            <FormattedMessage
                                id = {'studyPrivacyNotice.yourRights.description.eleven.body.text'}
                            />
                        </p>
                    </div>
                </GridContainer>
            </section>
            <LandingPageFooter />
        </>
    );
}
