import classNames from 'classnames';
import {
    useCallback,
    useEffect,
    useReducer,
    useState,
} from 'react';
import {useIntl} from 'react-intl';
import shallowEquals from 'shallow-equals';
import {
    calculateDateInputValidity,
    convertCalculatedValidityToErrorMessage,
    monthValueToMonthNameMap,
    todayInMilliseconds,
} from '../../minerva/common';
import {
    action,
    SET_VALIDITY,
    SET_VISITED_AND_VALIDITY,
} from '../common';
import {FormGroup} from '../form-group';
import {DateInputLayout} from './DateInputLayout';
import {DatePortion} from './DatePortion';

const DAY = 'day';
const MONTH = 'month';
const YEAR = 'year';
const SET_DIRTY = 'SET_DIRTY';

const init = (initialValue) => {
    const {
        day = '',
        month = '',
        year = '',
    } = initialValue ?? {};

    return {
        dirty: false,
        initialValue: {
            day,
            month,
            year,
        },
        validity: {
            day: true,
            month: true,
            year: true,
        },
        visited: {
            day: false,
            month: false,
            year: false,
        },
    };
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_DIRTY:
            return {
                ...state,
                dirty: payload,
            };

        case SET_VISITED_AND_VALIDITY:
            return {
                ...state,
                validity: {
                    ...state.validity,
                    [payload.portionKey]: payload.valid,
                },
                visited: {
                    ...state.visited,
                    [payload.portionKey]: true,
                },
            };

        case SET_VALIDITY:
            return {
                ...state,
                validity: {
                    ...state.validity,
                    [payload.portionKey]: payload.valid,
                },
                visited: Object.keys(state.visited).reduce(
                    (memo, key) => ({
                        ...memo,
                        [key]: true,
                    }),
                    {}
                ),
            };

        default:
            return state;
    }
};

// Appends 'portion' to then end of the supplied 'id', if 'id' exists, otherwise it returns 'portion'...
const constructPortionId = (id, portion) => (id ? `${id}-${portion}` : portion);

// Tries to work out if the entered date is valid or not...
const calculateValidityState = (
    validity,
    visited,
    value,
    maxDateInMilliseconds = todayInMilliseconds()
) => {
    // All fields need to have been visited or non-empty (in the case of pre-filled fields) for validity checks to take place...
    const allFieldsVisited = Object.values(visited).every((visitedState) => visitedState);
    const preFilled = Object.values(value).every((key) => Boolean(key));

    if (allFieldsVisited || preFilled) {
        // First check each individual field for it's own validity, this is essentially to check if it's empty, or
        // doesn't match the supplied pattern (it's not a number)...
        const allFieldsValid = Object.values(validity).every((validityState) => validityState);
        if (allFieldsValid) {
            const {
                day,
                month,
                year,
            } = value;

            // Check to make sure the values that have been entered are actually valid. Date.parse() should return NaN
            // if the date is nonsense, otherwise it'll return a number representing the milliseconds elapsed since
            // January 1, 1970...
            const dateString = `${year}-${month}-${day}`;
            const date = Date.parse(dateString);

            if (!Number.isNaN(date)) {
                // Date.parse() can also return a 'valid' value if the `year`, `month` and `day` values are reasonable
                // This can cause some issues because creating a date from '2021-02-31' gives "March, 03" because there's only 28 days in february.
                // So we need to check that the date created is the same as the date string supplied
                const DATE_PORTION_LENGTH = 10;

                if (new Date(date).toISOString().slice(0, DATE_PORTION_LENGTH) !== dateString) {
                    return false;
                }

                return date <= maxDateInMilliseconds;
            }
            return false;
        }

        // One or more fields have invalid state...
        return false;
    }

    // Valid by default, i.e. don't show the red outline until we're sure...
    return true;
};

export function DateInput(props) {
    const {
        autocompleteForDay = 'bday-day',
        autocompleteForMonth = 'bday-month',
        autocompleteForYear = 'bday-year',
        className,
        dataTestId,
        description,
        disabled,
        form,
        id,
        label,
        labelForDay,
        labelForMonth,
        labelForYear,
        layout = DateInputLayout.MONTH_DAY_YEAR,
        onChange,
        onFocus,
        readOnly,
        required,
        tabIndex,
        title,
        titleForDay,
        titleForMonth,
        titleForYear,
        value,
    } = props;

    const [{dirty, initialValue, validity, visited}, dispatch] = useReducer(reducer, value, init);
    const intl = useIntl();
    const [valid, setValid] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    // Break out the individual field values...
    const {
        day = '',
        month = '',
        year = '',
    } = value ?? {};

    useEffect(() => {
        if (!shallowEquals(initialValue, value)) {
            dispatch(action(SET_DIRTY, true));
        }
    }, [value]);

    useEffect(() => {
        const validityState = calculateValidityState(validity, visited, value);
        setValid(validityState);

        const calculatedValidity = calculateDateInputValidity(day, month, year);

        const displayErrorMessage = convertCalculatedValidityToErrorMessage(
            value,
            calculatedValidity,
            'dashboard.registerYourKit.sampleCollected',
            intl
        );

        const DATE_PORTION_LENGTH = 10;

        const dateString = `${year}-${month}-${day}`;
        const date = Date.parse(dateString);

        if (displayErrorMessage) {
            setErrorMessage(displayErrorMessage);
        }
        else if (!Number.isNaN(date)) {
            // If the date seems sensible at face value, lets clear out the error message and check
            // the each scenario again
            setErrorMessage(null);

            if (new Date(date).toISOString().slice(0, DATE_PORTION_LENGTH) !== dateString) {
                setErrorMessage(
                    intl.formatMessage(
                        {id: 'registration.aboutYou.dateOfBirth.error.day-out-of-bounds.text'},
                        {
                            day: day,
                            month: monthValueToMonthNameMap[month],
                        }
                    )
                );
            }
            else if (Date.now() < date) {
                setErrorMessage(intl.formatMessage({id: 'common.error.dateInput.future.text'}));
            }
        }
    }, [validity, visited, value]);

    const onBlurHandler = useCallback((portionKey, portionValid) => {
        dispatch(
            action(SET_VISITED_AND_VALIDITY, {
                portionKey: portionKey,
                valid: portionValid,
            })
        );
    }, []);

    const onChangeHandler = useCallback(
        (portionKey, portionValue, portionValid) => {
            if (onChange) {
                const previousValue = {
                    day,
                    month,
                    year,
                };
                onChange({
                    ...previousValue,
                    [portionKey]: portionValue,
                });
            }

            // Recalculate validity when the entire date input is dirty
            // So as users correct the input we can give them real time feedback/errorMessage
            const allFieldsVisited = Object.values(visited).every((visitedState) => visitedState);

            if (allFieldsVisited) {
                dispatch(
                    action(SET_VALIDITY, {
                        portionKey: portionKey,
                        valid: portionValid,
                    })
                );
            }
        },
        [onChange, value, visited]
    );

    const onFocusHandler = useCallback(() => {
        if (onFocus) {
            onFocus();
        }
    }, [onFocus]);

    const onInvalidHandler = useCallback(() => {
        setValid(false);
    }, [day, month, year]);

    // Work out classes not just for the container, but also for each individual portion of the date
    const formGroupClasses = classNames(className, {dirty});
    const dateLayoutClasses = classNames('date-layout', {
        'day-month-year': layout === DateInputLayout.DAY_MONTH_YEAR,
        'month-day-year': layout === DateInputLayout.MONTH_DAY_YEAR,
        'year-month-day': layout === DateInputLayout.YEAR_MONTH_DAY,
    });

    // Only pass the error message to the form group if we're invalid…
    const errorMessageToDisplay = !valid ? errorMessage : null;

    const commonProps = {
        disabled,
        dispatch,
        form,
        readOnly,
        required,
        tabIndex,
    };

    return (
        <FormGroup
            className = {formGroupClasses}
            dataTestId = {dataTestId}
            description = {description}
            errorMessage = {errorMessageToDisplay}
            label = {label}
            title = {title}
        >
            <div className = {dateLayoutClasses}>
                <DatePortion
                    autocomplete = {autocompleteForMonth}
                    className = {MONTH}
                    dataTestId = {constructPortionId(dataTestId, MONTH)}
                    id = {constructPortionId(id, MONTH)}
                    invalid = {!valid}
                    label = {labelForMonth}
                    maxLength = {2}
                    onBlur = {onBlurHandler}
                    onChange = {onChangeHandler}
                    onFocus = {onFocusHandler}
                    onInvalid = {onInvalidHandler}
                    pattern = {'[0-9]{2}'}
                    portionKey = {MONTH}
                    title = {titleForMonth}
                    value = {month}
                    {...commonProps}
                />
                <DatePortion
                    autocomplete = {autocompleteForDay}
                    className = {DAY}
                    dataTestId = {constructPortionId(dataTestId, DAY)}
                    id = {constructPortionId(id, DAY)}
                    invalid = {!valid}
                    label = {labelForDay}
                    maxLength = {2}
                    onBlur = {onBlurHandler}
                    onChange = {onChangeHandler}
                    onFocus = {onFocusHandler}
                    onInvalid = {onInvalidHandler}
                    pattern = {'[0-9]{2}'}
                    portionKey = {DAY}
                    title = {titleForDay}
                    value = {day}
                    {...commonProps}
                />
                <DatePortion
                    autocomplete = {autocompleteForYear}
                    className = {YEAR}
                    dataTestId = {constructPortionId(dataTestId, YEAR)}
                    id = {constructPortionId(id, YEAR)}
                    invalid = {!valid}
                    label = {labelForYear}
                    maxLength = {4}
                    onBlur = {onBlurHandler}
                    onChange = {onChangeHandler}
                    onFocus = {onFocusHandler}
                    onInvalid = {onInvalidHandler}
                    pattern = {'[0-9]{4}'}
                    portionKey = {YEAR}
                    title = {titleForYear}
                    value = {year}
                    {...commonProps}
                />
            </div>
        </FormGroup>
    );
}
