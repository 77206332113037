import {
    useCallback,
    useState,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Button,
    Checkbox,
    Checkboxes,
    Link,
} from '../../../../denim';
import {
    action,
    UPDATE_VALUE,
} from '../../../common';
import {Divider} from '../../../components';
import {useRegistrationContext} from '../RegistrationContext';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_VALUE:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};

export function PancreaticCancer(props) {
    const {isLastStep} = props;

    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState(null);

    const {
        nextStep,
        previousStep,
        state: {data: {familyHistory} = {}} = {},
        updateFamilyHistoryField,
    } = useRegistrationContext();

    const [pancreaticCancer, dispatch] = useReducer(reducer, familyHistory.pancreaticCancer);

    const {
        child,
        distantRelative,
        fullSibling,
        noneOfTheAbove,
        parent,
        preferNotToSay,
        unknown,
    } =
        pancreaticCancer;

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const onChangeHandler = useCallback(
        (value, name) => {
            const checkboxValue = Boolean(value);
            let payload = {};

            if (['parent', 'fullSibling', 'child', 'distantRelative'].includes(name)) {
                payload = {
                    ...pancreaticCancer,
                    [name]: checkboxValue,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    unknown: false,
                };
            }
            else {
                const initialValues = {
                    child: false,
                    distantRelative: false,
                    fullSibling: false,
                    noneOfTheAbove: false,
                    parent: false,
                    preferNotToSay: false,
                    unknown: false,
                };

                payload = {
                    ...initialValues,
                    [name]: checkboxValue,
                };
            }
            dispatch(action(UPDATE_VALUE, payload));

            setErrorMessage(null);
        },
        [pancreaticCancer]
    );

    const onSubmitHandler = useCallback(
        (event) => {
            event.preventDefault();

            // Check validity cause an invalid event to be triggered on anything that is invalid, which will cause them
            // to display their error state.
            const form = event.target;
            const formValid = form.checkValidity();

            // Check at least one checkbox has been selected.
            const isChecked = document.querySelectorAll('input[type=checkbox]:checked').length > 0;

            // If the form if valid, transition to the next step...
            if (formValid && isChecked) {
                updateFamilyHistoryField('pancreaticCancer', pancreaticCancer);
                nextStep({complete: isLastStep});
            }
            else {
                setErrorMessage(
                    intl.formatMessage({id: 'registration.common.error.choose-at-least-one.text'})
                );
            }
        },
        [pancreaticCancer]
    );

    const conditionalRevealPreviouslyHad = intl.formatMessage({id: 'registration.familyHistory.previouslyHad.conditionalReveal.text'});
    const conditionalRevealPreferNotToSay = intl.formatMessage({id: 'registration.familyHistory.preferNotToSay.conditionalReveal.text'});

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage id = {'registration.familyHistory.pancreaticCancer.title.text'} />
                </h2>
            </div>
            <Divider />
            <div className = {'question-options'}>
                <form
                    noValidate = {true}
                    onSubmit = {onSubmitHandler}
                >
                    <Checkboxes
                        errorMessage = {errorMessage}
                        label = {intl.formatMessage({id: 'registration.common.multiOption.instruction.text'})}
                    >
                        <Checkbox
                            checked = {parent}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'pancreatic-cancer-parent'}
                            id = {'parent-checkbox'}
                            key = {'parent-checkbox'}
                            label = {intl.formatMessage({id: 'registration.familyHistory.pancreaticCancer.checkbox.parent.text'})}
                            name = {'parent'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {fullSibling}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'pancreatic-cancer-full-sibling'}
                            id = {'full-sibling-checkbox'}
                            key = {'full-sibling-checkbox'}
                            label = {intl.formatMessage({id: 'registration.familyHistory.pancreaticCancer.checkbox.fullSibling.text'})}
                            name = {'fullSibling'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {child}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'pancreatic-cancer-child'}
                            id = {'child-checkbox'}
                            key = {'child-checkbox'}
                            label = {intl.formatMessage({id: 'registration.familyHistory.pancreaticCancer.checkbox.child.text'})}
                            name = {'child'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {distantRelative}
                            dataTestId = {'pancreatic-cancer-distant-relative'}
                            id = {'distant-relative-cancer-checkbox'}
                            key = {'distant-relative-cancer-checkbox'}
                            label = {intl.formatMessage({id: 'registration.familyHistory.pancreaticCancer.checkbox.distantRelative.text'})}
                            name = {'distantRelative'}
                            onChange = {onChangeHandler}
                        />
                        <div className = {'is-caption option-divider'}>
                            <FormattedMessage id = {'common.or.text'} />
                        </div>
                        <Checkbox
                            checked = {noneOfTheAbove}
                            dataTestId = {'pancreatic-cancer-none-of-the-above'}
                            id = {'none-of-the-above-checkbox'}
                            key = {'none-of-the-above-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.noneOfTheAbove.text'})}
                            name = {'noneOfTheAbove'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {unknown}
                            dataTestId = {'pancreatic-cancer-dont-know'}
                            id = {'unknown-checkbox'}
                            key = {'unknown-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.unknown.text'})}
                            name = {'unknown'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {preferNotToSay}
                            conditionalReveal = {conditionalRevealPreferNotToSay}
                            dataTestId = {'pancreatic-cancer-prefer-not-to-say'}
                            id = {'prefer-not-to-say-checkbox'}
                            key = {'prefer-not-to-say-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.prefer-not-to-say.text'})}
                            name = {'preferNotToSay'}
                            onChange = {onChangeHandler}
                        />
                    </Checkboxes>
                    <Button
                        dataTestId = {'continue-button'}
                        submit = {true}
                    >
                        <FormattedMessage id = {'registration.common.button.continue.text'} />
                    </Button>
                </form>
            </div>
        </div>
    );
}
