import {
    useCallback,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import {
    Button,
    ErrorCard,
    Link,
    TextInput,
} from '../../../../denim';
import {useApi} from '../../../auth0';
import {
    action,
    HTTP_BAD_REQUEST,
    UPDATE_VALUE,
} from '../../../common';
import {Divider} from '../../../components/divider';
import {useMinervaConfig} from '../../../config';
import {useRegistrationContext} from '../RegistrationContext';
import {IneligibleState} from './IneligibleState';

const ERROR_RAD_REQUEST = 'ERROR_RAD_REQUEST';
const ERROR_INVALID = 'ERROR_INVALID';
const ERROR_SERVER_ERROR = 'ERROR_SERVER_ERROR';
const SET_ERROR = 'SET_ERROR';
const SET_IS_LOADING = 'SET_IS_LOADING';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_ERROR:
            return {
                ...state,
                hasError: payload,
                isLoading: false,
            };

        case SET_IS_LOADING:
            return {
                ...state,
                hasError: false,
                isLoading: payload,
            };

        case UPDATE_VALUE:
            return {
                ...state,
                homeAddress: {
                    ...state.homeAddress,
                    [payload.name]: payload.value,
                },
            };

        default:
            return state;
    }
};

const init = (homeAddress) => ({
    hasError: null,
    homeAddress: homeAddress,
    isLoading: false,
});

export function HomeAddressManual() {
    const [authenticatedFetch] = useApi();
    const history = useHistory();
    const intl = useIntl();
    const {
        pathname,
        state: {displayIneligibility = false, ...historyState},
    } = useLocation();
    const {servicesBackend} = useMinervaConfig();
    const {
        nextStep,
        previousStep,
        state: {data: {aboutYou = {}} = {}} = {},
        updateAboutYouField,
    } = useRegistrationContext();
    const [{hasError, homeAddress, isLoading}, dispatch] = useReducer(
        reducer,
        aboutYou.homeAddress,
        init
    );
    const {
        street,
        apartment,
        city,
        state,
        zipCode,
    } = homeAddress;

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const handleInputChange = useCallback((value, name) => {
        dispatch(
            action(UPDATE_VALUE, {
                name,
                value,
            })
        );
    }, []);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            const form = event.target;
            const formValid = form.checkValidity();

            if (formValid) {
                try {
                    dispatch(action(SET_IS_LOADING, true));

                    /* eslint-disable camelcase */
                    const response = await authenticatedFetch(
                        `${servicesBackend}/validation/address`,
                        {
                            body: JSON.stringify({
                                apartment_suite_building: apartment,
                                city: city,
                                state: state,
                                street_address: street,
                                zip_code: zipCode,
                            }),
                            method: 'POST',
                        }
                    );

                    if (response.ok) {
                        const {address_validated: addressValidated, address_valid: addressValid} =
                            await response.json();

                        if (addressValidated && addressValid) {
                            updateAboutYouField('homeAddress', homeAddress);
                            nextStep();
                        }
                        // It's most likely that SmartyStreets couldn't validate the address...
                        else if (!addressValidated) {
                            dispatch(action(SET_ERROR, ERROR_INVALID));
                        }
                        // If the address isn't valid, then move them to the ineligible state page...
                        else if (!addressValid) {
                            history.push(pathname, {
                                displayIneligibility: true,
                                ...historyState,
                            });
                        }
                    }
                    else {
                        dispatch(
                            action(
                                SET_ERROR,
                                response.status === HTTP_BAD_REQUEST
                                    ? ERROR_RAD_REQUEST
                                    : ERROR_SERVER_ERROR
                            )
                        );
                    }
                    /* eslint-enable camelcase */
                }
                catch (error) {
                    dispatch(action(SET_ERROR, ERROR_SERVER_ERROR));
                }
            }
        },
        [homeAddress]
    );

    if (displayIneligibility) {
        return <IneligibleState stateName = {state} />;
    }

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage id = {'registration.aboutYou.homeAddress.question.text'} />
                </h2>
                <p className = {'description'}>
                    <FormattedMessage
                        id = {'registration.aboutYou.homeAddress.question.description.one'}
                    />
                </p>
                <p className = {'description'}>
                    <FormattedMessage
                        id = {'registration.aboutYou.homeAddress.question.description.two'}
                    />
                </p>
            </div>
            <Divider />
            <div className = {'question-options'}>
                {hasError && (
                    <ErrorCard
                        dataTestId = {'home-address-error'}
                        title = {intl.formatMessage({id: `registration.aboutYou.homeAddress.error.${hasError}.title`})}
                    >
                        <p>
                            <FormattedMessage
                                id = {`registration.aboutYou.homeAddress.error.${hasError}.description`}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {`registration.aboutYou.homeAddress.error.${hasError}.support`}
                                values = {{
                                    link: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'support.email'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                    </ErrorCard>
                )}
                <form
                    noValidate = {true}
                    onSubmit = {onSubmitHandler}
                >
                    <TextInput
                        dataTestId = {'home-address-street-input'}
                        errorMessage = {intl.formatMessage({id: 'registration.common.error.required.text'})}
                        id = {'street'}
                        label = {intl.formatMessage({id: 'registration.aboutYou.homeAddress.manualAddress.street.label'})}
                        name = {'street'}
                        onChange = {handleInputChange}
                        required = {true}
                        value = {street}
                    />
                    <TextInput
                        dataTestId = {'home-address-apartment-input'}
                        errorMessage = {intl.formatMessage({id: 'registration.common.error.required.text'})}
                        id = {'apartment'}
                        label = {intl.formatMessage({id: 'registration.aboutYou.homeAddress.manualAddress.apartment.label'})}
                        name = {'apartment'}
                        onChange = {handleInputChange}
                        value = {apartment}
                    />
                    <TextInput
                        dataTestId = {'home-address-city-input'}
                        errorMessage = {intl.formatMessage({id: 'registration.common.error.required.text'})}
                        id = {'city'}
                        label = {intl.formatMessage({id: 'registration.aboutYou.homeAddress.manualAddress.city.label'})}
                        name = {'city'}
                        onChange = {handleInputChange}
                        required = {true}
                        value = {city}
                    />
                    <div className = {'inline-form-group'}>
                        <TextInput
                            dataTestId = {'home-address-state-input'}
                            errorMessage = {intl.formatMessage({id: 'registration.common.error.required.text'})}
                            id = {'state'}
                            label = {intl.formatMessage({id: 'registration.aboutYou.homeAddress.manualAddress.state.label'})}
                            name = {'state'}
                            onChange = {handleInputChange}
                            required = {true}
                            value = {state}
                        />
                        <TextInput
                            dataTestId = {'home-address-zip-code-input'}
                            errorMessage = {intl.formatMessage({id: 'registration.common.error.required.text'})}
                            id = {'zipCode'}
                            label = {intl.formatMessage({id: 'registration.aboutYou.homeAddress.manualAddress.zipCode.label'})}
                            name = {'zipCode'}
                            onChange = {handleInputChange}
                            required = {true}
                            value = {zipCode}
                        />
                    </div>
                    <Button
                        dataTestId = {'continue-button'}
                        isLoading = {isLoading}
                        submit = {true}
                    >
                        <FormattedMessage id = {'registration.common.button.continue.text'} />
                    </Button>
                </form>
            </div>
        </div>
    );
}
