import {useAuth0} from '@auth0/auth0-react';
import {useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {Link} from '../../denim';

export function MenuContent(props) {
    const {children} = props;

    const {
        loginWithRedirect,
        logout,
        isAuthenticated,
    } = useAuth0();

    const goToAuth0Signup = useCallback((event) => {
        event.preventDefault();

        loginWithRedirect({
            appState: {returnTo: '/report'},
            screen_hint: 'login', // eslint-disable-line
        });
    }, []);

    const onLogoutClickHandler = useCallback((event) => {
        event.preventDefault();

        logout({returnTo: window.location.origin});
    }, []);

    const authActionLinkHandler = isAuthenticated ? onLogoutClickHandler : goToAuth0Signup;
    const authAction = isAuthenticated ? 'logOut' : 'logIn';

    return (
        <ul
            data-test-id = {'nav-menu-list'}
            role = {'menu'}
        >
            <li role = {'menuitem'}>
                <InternalLink
                    className = {'link'}
                    to = {'/frequently-asked-questions'}
                >
                    <span>
                        <FormattedMessage id = {'navBar.getHelp.link.text'} />
                    </span>
                </InternalLink>
            </li>
            <li role = {'menuitem'}>
                <Link
                    dataTestId = {'auth-action-link'}
                    onClick = {authActionLinkHandler}
                    url = {'/'}
                >
                    <FormattedMessage id = {`${authAction}.link.text`} />
                </Link>
            </li>
            {children}
        </ul>
    );
}
