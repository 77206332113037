import {
    useCallback,
    useLayoutEffect,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import {
    Button,
    ErrorCard,
    Link,
} from '../../../../denim';
import {useApi} from '../../../auth0';
import {
    PolicyholderState,
    action,
    useTitle,
} from '../../../common';
import {
    Divider,
    Loading,
} from '../../../components';
import {useMinervaConfig} from '../../../config';
import {usePolicyholderContext} from '../../../context';
import {TrackingInfo} from './TrackingInfo';

const SET_TRACKING_CODE = 'SET_TRACKING_CODE';
const SET_ERROR = 'SET_ERROR';
const SET_LOADING = 'SET_LOADING';

const initialState = {
    error: false,
    loading: false,
    trackingCode: null,
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_TRACKING_CODE:
            return {
                ...state,
                trackingCode: payload,
            };

        case SET_ERROR:
            return {
                ...state,
                error: payload,
            };

        case SET_LOADING:
            return {
                ...state,
                loading: payload,
            };

        default:
            return state;
    }
};

export function KitTracking() {
    const intl = useIntl();
    const history = useHistory();
    const minervaConfig = useMinervaConfig();
    const [authenticatedFetch] = useApi();
    const {state: {policyholder: {state} = {}} = {}} = usePolicyholderContext();
    const [{trackingCode, error, loading}, dispatch] = useReducer(reducer, initialState);

    useTitle(intl.formatMessage({id: `dashboard.kitTracking.documentTitle`}));

    useLayoutEffect(() => {
        if (state === PolicyholderState.KIT_ORDERED) {
            return;
        }

        dispatch(action(SET_LOADING, true));

        const fetchKits = async () => {
            const response = await authenticatedFetch(
                `${minervaConfig.servicesBackend}/policyholders/me/kit-orders`
            );

            if (response.ok) {
                const data = await response.json();

                // Get most recent shipped kit from list
                const [mostRecentlyShippedKit] = data
                    .filter((item) => item.order_status === 'SHIPPED')
                    .sort((first, second) => new Date(second.placed_at) - new Date(first.placed_at));

                dispatch(action(SET_TRACKING_CODE, mostRecentlyShippedKit.outbound_tracking_code));
                return;
            }
            throw new Error('Unable to fetch tracking information.');
        };

        fetchKits()
            .catch(() => dispatch(action(SET_ERROR, true)))
            .finally(() => dispatch(action(SET_LOADING, false)));
    }, []);

    const onClickHandler = useCallback(() => {
        history.push('/dashboard/kit-registration');
    });

    if (loading) {
        return <Loading withPageLayout = {true} />;
    }

    return (
        <>
            <div
                className = {'content'}
                data-test-id = {'kit-tracking'}
            >
                <h1>
                    <FormattedMessage id = {'dashboard.kitTracking.title.text'} />
                </h1>
                <p>
                    <FormattedMessage id = {'dashboard.kitTracking.descriptionOne.text'} />
                </p>
                {error ? (
                    <ErrorCard
                        dataTestId = {'tracking-info-error'}
                        title = {intl.formatMessage({id: 'dashboard.kitTracking.error.title'})}
                    >
                        <p>
                            <FormattedMessage id = {'dashboard.kitTracking.error.description'} />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {`dashboard.kitTracking.error.support`}
                                values = {{
                                    link: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'support.email'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                    </ErrorCard>
                ) : (
                    <TrackingInfo
                        policyHolderState = {state}
                        trackingCode = {trackingCode}
                    />
                )}
                <p>
                    <FormattedMessage
                        id = {'dashboard.kitTracking.descriptionTwo.text'}
                        values = {{strong: (chunks) => <strong>{chunks}</strong>}}
                    />
                </p>
                <HashLink
                    className = {'link is-heavy is-help-link'}
                    to = {'/frequently-asked-questions#why-register'}
                >
                    <span>
                        <FormattedMessage id = {'dashboard.kitTracking.link.text'} />
                    </span>
                </HashLink>
                <Divider />
                <p className = {'is-heavy is-caption half-margin'}>
                    <FormattedMessage id = {'dashboard.kitTracking.buttonCaption.text'} />
                </p>
                <Button
                    dataTestId = {'continue-button'}
                    onClick = {onClickHandler}
                >
                    <FormattedMessage id = {'registration.common.button.continue.text'} />
                </Button>
            </div>
            <div className = {'graphic'}>
                <img
                    alt = {intl.formatMessage({id: 'dashboard.kitBoxFront.image.altText'})}
                    src = {'/images/test-kit-upright-box.png'}
                />
            </div>
        </>
    );
}
