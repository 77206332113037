import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ReactDOM from 'react-dom';
import {IntlProvider} from 'react-intl';
import Modal from 'react-modal';
import {BrowserRouter} from 'react-router-dom';
import messages from './i18n/messages.json';
import {
    App,
    Auth0ProviderWithHistory,
    MinervaContext,
} from './minerva';
import {PolicyholderContext} from './minerva/context';
import {ErrorBoundary} from './minerva/pages';

import '../css/minerva-user-portal.pcss';

// Get the element where we will render the application into...
const appRoot = document.getElementById('root');

// Tell the modal where the application is rendered into, so that it can apply aria-hidden when a modal is open:
Modal.setAppElement(appRoot);

ReactDOM.render(
    <MinervaContext>
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <IntlProvider
                    defaultLocale = {'en'}
                    locale = {'en'}
                    messages = {messages}
                >
                    <ErrorBoundary>
                        <PolicyholderContext>
                            <App />
                        </PolicyholderContext>
                    </ErrorBoundary>
                </IntlProvider>
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    </MinervaContext>,
    appRoot
);
