import {useReducer} from 'react';
import {
    UPDATE_VALUE,
    useAccessCode,
} from '../../../common';
import {useRegistrationProgress} from '../RegistrationContext';
import {DoYouQualify} from './DoYouQualify';
import {YoureIn} from './YoureIn';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_VALUE:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};

const initialState = {
    age: false,
    country: false,
    state: false,
    studyPrivacyNoticeAccepted: false,
};

const steps = [DoYouQualify, YoureIn];

export function Eligibility(props) {
    useAccessCode();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {currentStep = 1} = useRegistrationProgress();

    const EligibilityComponent = steps[currentStep - 1];

    return (
        <EligibilityComponent
            dispatch = {dispatch}
            state = {state}
            {...props}
        />
    );
}
