import {
    useCallback,
    useLayoutEffect,
    useState,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Button,
    Checkbox,
    Checkboxes,
    Link,
} from '../../../../denim';
import {
    action,
    UPDATE_VALUE,
} from '../../../common';
import {Divider} from '../../../components';
import {useRegistrationContext} from '../RegistrationContext';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_VALUE:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};

export function GeneticTestingFamily() {
    const intl = useIntl();
    const {
        nextStep,
        previousStep,
        state: {data: {geneticTesting} = {}} = {},
        updateGeneticTestingField,
    } = useRegistrationContext();
    const [errorMessage, setErrorMessage] = useState(null);
    const [geneticResults, dispatch] = useReducer(reducer, geneticTesting.geneticResultsFamily);

    // This page is shown conditionally on the previous page picking a particular answer.
    useLayoutEffect(() => {
        if (geneticTesting.previouslyTested !== 'PRIOR_GENETIC_TEST_YES') {
            previousStep();
        }
    }, []);

    const {
        breastCancer,
        hypercholesterolemia,
        noneOfTheAbove,
        preferNotToSay,
        prostateCancer,
        unknown,
    } = geneticResults;

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const onChangeHandler = useCallback(
        (value, name) => {
            const checkboxValue = Boolean(value);
            let payload = {};

            if (['breastCancer', 'hypercholesterolemia', 'prostateCancer'].includes(name)) {
                payload = {
                    ...geneticResults,
                    [name]: checkboxValue,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    unknown: false,
                };
            }
            else {
                const initialValues = {
                    breastCancer: false,
                    hypercholesterolemia: false,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    prostateCancer: false,
                    unknown: false,
                };

                payload = {
                    ...initialValues,
                    [name]: checkboxValue,
                };
            }
            dispatch(action(UPDATE_VALUE, payload));

            setErrorMessage(null);
        },
        [geneticResults]
    );

    const onSubmitHandler = useCallback(
        (event) => {
            // Check validity cause an invalid event to be triggered on anything that is invalid, which will cause them
            // to display their error state.
            const {form} = event.target;
            const formValid = form.checkValidity();

            // Check at least one checkbox has been selected.
            const isChecked = document.querySelectorAll('input[type=checkbox]:checked').length > 0;

            // If the form if valid, transition to the next step...
            if (formValid && isChecked) {
                updateGeneticTestingField('geneticResultsFamily', geneticResults);
                nextStep({complete: true});
            }
            else {
                setErrorMessage(
                    intl.formatMessage({id: 'registration.common.error.choose-at-least-one.text'})
                );
            }
        },
        [geneticResults]
    );

    const conditionalRevealCancer = intl.formatMessage({id: 'registration.geneticTesting.common.cancer.conditionalReveal.text'});
    const conditionalRevealHeartCondition = intl.formatMessage({id: 'registration.geneticTesting.common.heartCondition.conditionalReveal.text'});
    const conditionalRevealPreferNotToSay = intl.formatMessage({id: 'registration.geneticTesting.common.preferNotToSay.conditionalReveal.text'});

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage
                        id = {'registration.geneticTesting.geneticTestingFamily.question.text'}
                    />
                </h2>
                <p>
                    <FormattedMessage
                        id = {
                            'registration.geneticTesting.geneticTestingFamily.question.description.text'
                        }
                    />
                </p>
            </div>
            <Divider />
            <div className = {'question-options'}>
                <form>
                    <Checkboxes
                        errorMessage = {errorMessage}
                        label = {intl.formatMessage({id: 'registration.geneticTesting.geneticTestingFamily.checkboxes.text'})}
                    >
                        <Checkbox
                            checked = {breastCancer}
                            conditionalReveal = {conditionalRevealCancer}
                            dataTestId = {'genetic-testing-family-breast-cancer'}
                            id = {'genetic-testing-family-checkbox-breast-cancer'}
                            key = {'genetic-testing-family-checkbox-breast-cancer'}
                            label = {intl.formatMessage({id: 'registration.geneticTesting.common.checkbox.breastCancer.text'})}
                            name = {'breastCancer'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {prostateCancer}
                            conditionalReveal = {conditionalRevealCancer}
                            dataTestId = {'genetic-testing-family-prostate-cancer'}
                            id = {'genetic-testing-family-checkbox-prostate-cancer'}
                            key = {'genetic-testing-family-checkbox-prostate-cancer'}
                            label = {intl.formatMessage({id: 'registration.geneticTesting.common.checkbox.prostateCancer.text'})}
                            name = {'prostateCancer'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {hypercholesterolemia}
                            conditionalReveal = {conditionalRevealHeartCondition}
                            dataTestId = {'genetic-testing-family-hypercholesterolemia'}
                            description = {intl.formatMessage({id: 'registration.geneticTesting.common.checkbox.hypercholesterolemia.description.text'})}
                            id = {'genetic-testing-family-checkbox-hypercholesterolemia'}
                            key = {'genetic-testing-family-checkbox-hypercholesterolemia'}
                            label = {intl.formatMessage({id: 'registration.geneticTesting.common.checkbox.hypercholesterolemia.text'})}
                            name = {'hypercholesterolemia'}
                            onChange = {onChangeHandler}
                        />
                        <div className = {'is-caption option-divider'}>
                            <FormattedMessage id = {'common.or.text'} />
                        </div>
                        <Checkbox
                            checked = {noneOfTheAbove}
                            dataTestId = {'genetic-testing-family-dont-know'}
                            id = {'genetic-testing-family-checkbox-none-of-the-above'}
                            key = {'genetic-testing-family-checkbox-none-of-the-above'}
                            label = {intl.formatMessage({id: 'registration.geneticTesting.geneticTestingFamily.checkbox.none-of-the-above.text'})}
                            name = {'noneOfTheAbove'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {unknown}
                            dataTestId = {'genetic-testing-family-none-of-the-above'}
                            id = {'genetic-testing-family-checkbox-unknown'}
                            key = {'genetic-testing-family-checkbox-unknown'}
                            label = {intl.formatMessage({id: 'registration.common.option.unknown.text'})}
                            name = {'unknown'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {preferNotToSay}
                            conditionalReveal = {conditionalRevealPreferNotToSay}
                            dataTestId = {'genetic-testing-family-prefer-not-to-say'}
                            id = {'genetic-testing-family-checkbox-prefer-not-to-say'}
                            key = {'genetic-testing-family-checkbox-prefer-not-to-say'}
                            label = {intl.formatMessage({id: 'registration.common.option.prefer-not-to-say.text'})}
                            name = {'preferNotToSay'}
                            onChange = {onChangeHandler}
                        />
                    </Checkboxes>
                    <Button
                        dataTestId = {'continue-button'}
                        onClick = {onSubmitHandler}
                    >
                        <FormattedMessage id = {'registration.common.button.continue.text'} />
                    </Button>
                </form>
            </div>
        </div>
    );
}
