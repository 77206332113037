import {useAuth0} from '@auth0/auth0-react';
import {useCallback} from 'react';

export function useAccessTokenWithLoginFallback() {
    const {getAccessTokenSilently, loginWithRedirect} = useAuth0();

    return useCallback(async () => {
        try {
            return await getAccessTokenSilently();
        }
        catch (error) {
            // Check if failure due to needing to login (e.g. expired token)
            if (['login_required', 'consent_required'].includes(error.error)) {
                await loginWithRedirect();

                // Use Promise.race to return a forever pending promise until the loginWithRedirect kicks in
                // This helps to prevent flashes of content just before the browser redirects
                return Promise.race([]);
            }

            throw new Error(error);
        }
    }, [getAccessTokenSilently, loginWithRedirect]);
}
