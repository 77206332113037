import classNames from 'classnames';
import {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
} from 'react';
import {
    action,
    filterInputPropsForType,
    getConstraintValidationsForType,
    SET_DIRTY,
    SET_VALIDITY,
} from '../common';
import {FormGroup} from '../form-group';

const init = (initialValue = '') => ({
    dirty: false,
    initialValue: initialValue,
    valid: true,
});

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_DIRTY:
            return {
                ...state,
                dirty: payload,
            };

        case SET_VALIDITY:
            return {
                ...state,
                valid: payload,
            };

        default:
            return state;
    }
};

/**
 * React component for rendering text based input fields,
 *      i.e. type = ['text' | 'email' | 'password' | 'search' | 'tel' | 'url']
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export function TextInput(props) {
    const {
        className,
        dataTestId,
        description,
        errorMessage,
        formGroupClassName,
        formGroupDataTestId,
        innerRef,
        invalid = false,
        label,
        onBlur,
        onChange,
        onFocus,
        prefixContent,
        value = '',
        ...otherProps
    } = props;
    const [{dirty, initialValue, valid}, dispatch] = useReducer(reducer, value, init);

    useEffect(() => {
        if (initialValue !== value) {
            dispatch(action(SET_DIRTY, true));
        }
    }, [value]);

    // Filters the otherProps for those are are valid for the given input type...
    const textInputProps = useMemo(() => filterInputPropsForType(otherProps), [otherProps]);

    // Generates a list of constraints that should be checked for the given input type and supplied props...
    const constraintValidations = useMemo(
        () => getConstraintValidationsForType(textInputProps),
        [textInputProps]
    );

    const onBlurHandler = useCallback(
        (event) => {
            // Get the ValidityState of the input and check for any failed constraints...
            const {validity} = event.target;
            const failedConstraints = constraintValidations.filter(
                (constraint) => validity[constraint]
            );

            // If there are no failedConstraints, then we're valid...
            const inputValidity = failedConstraints.length === 0;

            // Update our internal state so we can apply the correct styling if we're invalid...
            dispatch(action(SET_VALIDITY, inputValidity));

            if (onBlur) {
                onBlur(event.target.value, event.target.name);
            }
        },
        [constraintValidations, onBlur]
    );

    const onChangeHandler = useCallback(
        (event) => {
            dispatch(action(SET_VALIDITY, true));

            if (onChange) {
                onChange(event.target.value, event.target.name);
            }
        },
        [onChange]
    );

    const onFocusHandler = useCallback(
        (event) => {
            if (onFocus) {
                onFocus(event.target.value, event.target.name);
            }
        },
        [onFocus]
    );

    const onInvalidHandler = useCallback(() => {
        dispatch(action(SET_VALIDITY, false));
    }, []);

    // Has the user overridden the internal validity checks...?
    const invalidState = invalid || !valid;

    const formGroupClasses = classNames(formGroupClassName, {dirty});
    const textInputClasses = classNames('text-input', className, {
        'has-prefix': prefixContent,
        invalid: invalidState,
    });

    // Only pass the error message to the form group if we're invalid...
    const errorMessageToDisplay = invalidState ? errorMessage : null;

    return (
        <FormGroup
            className = {formGroupClasses}
            dataTestId = {formGroupDataTestId}
            description = {description}
            errorMessage = {errorMessageToDisplay}
            label = {label}
            labelFor = {textInputProps['id']}
        >
            {prefixContent && <span className = {'prefix'}>{prefixContent}</span>}
            <input
                className = {textInputClasses}
                data-test-id = {dataTestId}
                onBlur = {onBlurHandler}
                onChange = {onChangeHandler}
                onFocus = {onFocusHandler}
                onInvalid = {onInvalidHandler}
                ref = {innerRef}
                value = {value}
                {...textInputProps}
            />
        </FormGroup>
    );
}
