import classNames from 'classnames';
import {
    useCallback,
    useEffect,
    useReducer,
} from 'react';
import {useIntl} from 'react-intl';
import {
    Button,
    FormGroup,
    Variant,
} from '../../../denim';
import {action} from '../../common';
import {useMinervaConfig} from '../../config';

const UPDATE_ACCESS_CODE = 'UPDATE_ACCESS_CODE';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const SET_IS_VALIDATING_ACCESS_CODE = 'SET_IS_VALIDATING_ACCESS_CODE';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_ACCESS_CODE:
            return {
                ...state,
                accessCode: payload,
            };

        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: payload,
            };

        case SET_IS_VALIDATING_ACCESS_CODE:
            return {
                ...state,
                isValidatingAccessCode: payload,
            };

        default:
            return state;
    }
};

const init = (initialAccessCode = '') => ({
    accessCode: initialAccessCode,
    errorMessage: null,
    isValidatingAccessCode: false,
});

export function AccessCodeInput(props) {
    const {
        buttonText,
        className,
        dataTestId,
        id,
        label,
        onInvalidAccessCode,
        onValidAccessCode,
        onError,
        value = '',
        variant = Variant.SECONDARY,
    } = props;

    const intl = useIntl();
    const {servicesBackend} = useMinervaConfig();
    const [{accessCode, errorMessage, isValidatingAccessCode}, dispatch] = useReducer(
        reducer,
        value,
        init
    );

    useEffect(() => {
        if (value !== accessCode) {
            dispatch(action(UPDATE_ACCESS_CODE, value));
        }
    }, [value]);

    let sanitisedVariant = variant;
    if (![Variant.PRIMARY, Variant.SECONDARY].includes(variant)) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error('AccessCodeInput only supports Variant.PRIMARY and Variant.SECONDARY.');
        }
        sanitisedVariant = Variant.SECONDARY;
    }

    const onChangeHandler = useCallback((event) => {
        dispatch(action(UPDATE_ACCESS_CODE, event.target.value));
        dispatch(action(SET_ERROR_MESSAGE, null));
    }, []);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            if (!accessCode) {
                dispatch(
                    action(
                        SET_ERROR_MESSAGE,
                        intl.formatMessage({id: 'landingPage.accessCodeIssue.noAccessCode'})
                    )
                );
            }
            else {
                dispatch(action(SET_IS_VALIDATING_ACCESS_CODE, true));

                try {
                    const response = await fetch(
                        `${servicesBackend}/invitations/available/${accessCode}`
                    );

                    // Do this here, as if we do it in a finally block, the component have been unmounted and we get a
                    // React maon in the console...
                    dispatch(action(SET_IS_VALIDATING_ACCESS_CODE, false));

                    if (response.ok) {
                        if (onValidAccessCode) {
                            onValidAccessCode(accessCode);
                        }
                    }
                    else {
                        if (onInvalidAccessCode) {
                            onInvalidAccessCode(accessCode);
                        }
                    }
                }
                catch (error) {
                    if (process.env.NODE_ENV === 'development') {
                        // eslint-disable-next-line no-console
                        console.error(error);
                    }

                    dispatch(action(SET_IS_VALIDATING_ACCESS_CODE, false));

                    if (onError) {
                        onError(error);
                    }
                }
            }
        },
        [accessCode]
    );

    const containerClassNames = classNames('access-code-input', sanitisedVariant, className);

    const errorMessageToDisplay = accessCode ? null : errorMessage;

    return (
        <div className = {containerClassNames}>
            <form
                noValidate = {true}
                onSubmit = {onSubmitHandler}
            >
                <FormGroup
                    errorMessage = {errorMessageToDisplay}
                    label = {label}
                    labelFor = {id}
                >
                    <input
                        className = {'text-input'}
                        data-test-id = {dataTestId}
                        id = {id}
                        onChange = {onChangeHandler}
                        required = {true}
                        type = {'text'}
                        value = {accessCode}
                    />
                    <Button
                        dataTestId = {`${dataTestId}-button`}
                        isLoading = {isValidatingAccessCode}
                        submit = {true}
                    >
                        {buttonText}
                    </Button>
                </FormGroup>
            </form>
        </div>
    );
}
