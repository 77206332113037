export class DateInputLayout {
    // Order the date input fields as day, month, year
    static DAY_MONTH_YEAR = 0;

    // Order the date input fields as month, day, year
    static MONTH_DAY_YEAR = 1;

    // Order the date input fields as year, month, day
    static YEAR_MONTH_DAY = 2;
}
