export class MinervaConfig {
    config = null;

    constructor(config) {
        this.config = config ?? {};
    }

    static async init() {
        try {
            const applicationConfig = await (await fetch('/config.json')).json();

            return new MinervaConfig(applicationConfig);
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Unable to load application config - ${error}`);
        }

        throw new Error('Unable to load the application configuration.');
    }

    get studyOpen() {
        const {studyOpen} = this.config;
        return studyOpen ?? true;
    }

    get auth0() {
        const {auth0} = this.config;
        return auth0;
    }

    get auth0Audience() {
        const {auth0: {audience} = {}} = this.config;
        return audience;
    }

    get auth0ClientId() {
        const {auth0: {clientId} = {}} = this.config;
        return clientId;
    }

    get auth0Domain() {
        const {auth0: {domain} = {}} = this.config;
        return domain;
    }

    get services() {
        const {services} = this.config;
        return services;
    }

    get servicesBackend() {
        const {services: {backend} = {}} = this.config;
        return backend;
    }

    get smartyStreetsKey() {
        const {services: {smartyStreetsKey} = {}} = this.config;
        return smartyStreetsKey;
    }

    get servicesDatadog() {
        const {services: {datadog} = {}} = this.config;
        return datadog;
    }

    get servicesDatadogApplicationId() {
        const {
            services: {datadog: {applicationId} = {}},
        } = this.config;
        return applicationId;
    }

    get servicesDatadogClientToken() {
        const {
            services: {datadog: {clientToken} = {}},
        } = this.config;
        return clientToken;
    }

    get servicesDatadogSite() {
        const {
            services: {datadog: {site} = {}},
        } = this.config;
        return site;
    }

    get servicesDatadogEnv() {
        const {
            services: {datadog: {env} = {}},
        } = this.config;
        return env;
    }

    get servicesDatadogService() {
        const {services: {datadog: {service} = {}} = {}} = this.config;
        return service;
    }

    get eligibility() {
        const {eligibility} = this.config;
        return eligibility;
    }

    get eligibilityAges() {
        const {eligibility: {ages = {}} = {}} = this.config;
        return ages;
    }

    get version() {
        const {version = ''} = this.config;
        return version;
    }

    get faq() {
        const {faq} = this.config;
        return faq;
    }

    get faqHomepage() {
        const {faq: {homepage} = {}} = this.config;
        return homepage;
    }

    get surveyBaseUrl() {
        const {services: {surveyBaseUrl} = {}} = this.config;
        return surveyBaseUrl;
    }
}
