import {Breakpoint} from '../constants';
import {useWindowDimensions} from './useWindowDimensions';

const BREAKPOINT_PHONE = 599;
const BREAKPOINT_TABLET_PORTRAIT = 899;
const BREAKPOINT_TABLET_LANDSCAPE = 1135;

export function useBreakpoints() {
    const {width} = useWindowDimensions();

    if (width <= BREAKPOINT_PHONE) {
        return Breakpoint.PHONE;
    }
    else if (width > BREAKPOINT_PHONE && width <= BREAKPOINT_TABLET_PORTRAIT) {
        return Breakpoint.TABLET_PORTRAIT;
    }
    else if (width > BREAKPOINT_TABLET_PORTRAIT && width <= BREAKPOINT_TABLET_LANDSCAPE) {
        return Breakpoint.TABLET_LANDSCAPE;
    }

    return Breakpoint.DESKTOP;
}
