import {useAccessTokenWithLoginFallback} from './useAccessTokenWithLoginFallback';

export function useApi() {
    const getAccessTokenSilentlyWithLogin = useAccessTokenWithLoginFallback();

    const abortController = new AbortController();

    return [
        async (resource, init) => {
            const token = await getAccessTokenSilentlyWithLogin();

            // Protect against there being no custom settings...
            const options = init ?? {};

            return fetch(resource, {
                signal: abortController.signal,
                ...options,
                headers: {
                    'Content-Type': 'application/json',
                    ...options.headers,
                    Authorization: `Bearer ${token}`,
                },
            });
        },
        abortController,
    ];
}
