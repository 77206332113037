export const PolicyholderState = {
    CAP_RETRIEVED: 'CAP_RETRIEVED',
    CONSENT_SIGNED: 'CONSENT_SIGNED',
    DETAILS_SUBMITTED: 'DETAILS_SUBMITTED',
    KIT_ORDERED: 'KIT_ORDERED',
    KIT_REGISTERED: 'KIT_REGISTERED',
    KIT_SHIPPED: 'KIT_SHIPPED',
    REGISTERED: 'REGISTERED',
    TEST_APPROVED: 'TEST_APPROVED',
    TEST_REQUESTED: 'TEST_REQUESTED',
};
