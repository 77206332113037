import {
    useCallback,
    useState,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Button,
    Checkbox,
    Checkboxes,
    Link,
} from '../../../../denim';
import {
    action,
    UPDATE_VALUE,
} from '../../../common';
import {Divider} from '../../../components';
import {useRegistrationContext} from '../RegistrationContext';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_VALUE:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};

export function ProstateCancer(props) {
    const {isLastStep} = props;

    const intl = useIntl();
    const [errorMessage, setErrorMessage] = useState(null);

    const {
        nextStep,
        previousStep,
        state: {data: {familyHistory} = {}} = {},
        updateFamilyHistoryField,
    } = useRegistrationContext();

    const [prostateCancer, dispatch] = useReducer(reducer, familyHistory.prostateCancer);

    const {
        diedFrom,
        metaStatic,
        noneOfTheAbove,
        preferNotToSay,
        unknown,
    } = prostateCancer;

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const onChangeHandler = useCallback(
        (value, name) => {
            const checkboxValue = Boolean(value);
            let payload = {};

            if (['diedFrom', 'metaStatic'].includes(name)) {
                payload = {
                    ...prostateCancer,
                    [name]: checkboxValue,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    unknown: false,
                };
            }
            else {
                const initialValues = {
                    diedFrom: false,
                    metaStatic: false,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    unknown: false,
                };

                payload = {
                    ...initialValues,
                    [name]: checkboxValue,
                };
            }
            dispatch(action(UPDATE_VALUE, payload));

            setErrorMessage(null);
        },
        [prostateCancer]
    );

    const onSubmitHandler = useCallback(
        (event) => {
            event.preventDefault();

            // Check validity cause an invalid event to be triggered on anything that is invalid, which will cause them
            // to display their error state.
            const form = event.target;
            const formValid = form.checkValidity();

            // Check at least one checkbox has been selected.
            const isChecked = document.querySelectorAll('input[type=checkbox]:checked').length > 0;

            // If the form if valid, transition to the next step...
            if (formValid && isChecked) {
                updateFamilyHistoryField('prostateCancer', prostateCancer);
                nextStep({complete: isLastStep});
            }
            else {
                setErrorMessage(
                    intl.formatMessage({id: 'registration.common.error.choose-at-least-one.text'})
                );
            }
        },
        [prostateCancer]
    );

    const conditionalRevealPreviouslyHad = intl.formatMessage({id: 'registration.familyHistory.previouslyHad.conditionalReveal.text'});
    const conditionalRevealPreferNotToSay = intl.formatMessage({id: 'registration.familyHistory.preferNotToSay.conditionalReveal.text'});

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage id = {'registration.familyHistory.prostateCancer.title.text'} />
                </h2>
                <p className = {'description'}>
                    <FormattedMessage id = {'registration.familyHistory.question.description'} />
                </p>
            </div>
            <Divider />
            <div className = {'question-options'}>
                <form
                    noValidate = {true}
                    onSubmit = {onSubmitHandler}
                >
                    <Checkboxes
                        errorMessage = {errorMessage}
                        label = {intl.formatMessage({id: 'registration.common.multiOption.instruction.text'})}
                    >
                        <Checkbox
                            checked = {metaStatic}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'prostate-cancer-metastatic'}
                            description = {intl.formatMessage({id: 'registration.familyHistory.prostateCancer.checkbox.metaStatic.description.text'})}
                            id = {'metastatic-checkbox'}
                            key = {'metastatic-checkbox'}
                            label = {intl.formatMessage({id: 'registration.familyHistory.prostateCancer.checkbox.metaStatic.text'})}
                            name = {'metaStatic'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {diedFrom}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'prostate-cancer-died-from'}
                            id = {'died-from-checkbox'}
                            key = {'died-from-checkbox'}
                            label = {intl.formatMessage({id: 'registration.familyHistory.prostateCancer.checkbox.diedFrom.text'})}
                            name = {'diedFrom'}
                            onChange = {onChangeHandler}
                        />
                        <div className = {'is-caption option-divider'}>
                            <FormattedMessage id = {'common.or.text'} />
                        </div>
                        <Checkbox
                            checked = {noneOfTheAbove}
                            dataTestId = {'prostate-cancer-none-of-the-above'}
                            id = {'none-of-the-above-checkbox'}
                            key = {'none-of-the-above-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.noneOfTheAbove.text'})}
                            name = {'noneOfTheAbove'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {unknown}
                            dataTestId = {'prostate-cancer-dont-know'}
                            id = {'dont-know-checkbox'}
                            key = {'dont-know-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.unknown.text'})}
                            name = {'unknown'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {preferNotToSay}
                            conditionalReveal = {conditionalRevealPreferNotToSay}
                            dataTestId = {'prostate-cancer-prefer-not-to-say'}
                            id = {'prefer-not-to-say-checkbox'}
                            key = {'prefer-not-to-say-checkbox'}
                            label = {intl.formatMessage({id: 'registration.common.option.prefer-not-to-say.text'})}
                            name = {'preferNotToSay'}
                            onChange = {onChangeHandler}
                        />
                    </Checkboxes>
                    <Button
                        dataTestId = {'continue-button'}
                        submit = {true}
                    >
                        <FormattedMessage id = {'registration.common.button.continue.text'} />
                    </Button>
                </form>
            </div>
        </div>
    );
}
