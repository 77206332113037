export function Spinner(props) {
    const {dataTestId = 'button-loading-spinner', primary = false} = props;

    return (
        <svg
            className = {'spinner'}
            data-test-id = {dataTestId}
            fill = {'none'}
            height = {'20'}
            width = {'20'}
            xmlns = {'http://www.w3.org/2000/svg'}
        >
            <g
                clipPath = {'url(#clip0)'}
                style = {{mixBlendMode: primary ? 'multiply' : 'screen'}}
            >
                <rect
                    fill = {'#EFEFEF'}
                    height = {'5.5'}
                    rx = {'1'}
                    width = {'2'}
                    x = {'9'}
                />
                <rect
                    fill = {'#EFEFEF'}
                    height = {'5.5'}
                    rx = {'1'}
                    transform = {'rotate(-45 2.222 3.636)'}
                    width = {'2'}
                    x = {'2.222'}
                    y = {'3.636'}
                />
                <rect
                    fill = {'#646464'}
                    height = {'5.5'}
                    rx = {'1'}
                    transform = {'rotate(-45 12.475 13.889)'}
                    width = {'2'}
                    x = {'12.475'}
                    y = {'13.889'}
                />
                <rect
                    fill = {'#646464'}
                    height = {'5.5'}
                    rx = {'1'}
                    width = {'2'}
                    x = {'9'}
                    y = {'14.5'}
                />
                <rect
                    fill = {'#BDBDBD'}
                    height = {'3.999'}
                    rx = {'1'}
                    transform = {'rotate(90 18.5 9)'}
                    width = {'2'}
                    x = {'18.499'}
                    y = {'9'}
                />
                <rect
                    fill = {'#BDBDBD'}
                    height = {'5.5'}
                    rx = {'1'}
                    transform = {'rotate(90 5.5 9)'}
                    width = {'2'}
                    x = {'5.5'}
                    y = {'9'}
                />
                <rect
                    fill = {'#BDBDBD'}
                    height = {'5.5'}
                    rx = {'1'}
                    transform = {'rotate(45 6.111 12.475)'}
                    width = {'2'}
                    x = {'6.111'}
                    y = {'12.475'}
                />
            </g>
            <defs>
                <clipPath id = {'clip0'}>
                    <path
                        d = {'M0 0h20v20H0z'}
                        fill = {'#fff'}
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
