export const SET_DIRTY = 'SET_DIRTY';
export const SET_VALUE = 'SET_VALUE';
export const SET_VALIDITY = 'SET_VALIDITY';
export const SET_VISITED = 'SET_VISITED';
export const SET_VISITED_AND_VALIDITY = 'SET_VISITED_AND_VALIDITY';

export const action = (type, payload) => ({
    payload,
    type,
});
