import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link} from '../../../denim';
import {GridContainer} from '../../components';

export function StudyClosedHero() {
    const intl = useIntl();

    return (
        <GridContainer
            containerClassName = {'hero study-closed'}
            containerDataTestId = {'study-closed-hero'}
        >
            <h1>
                <FormattedMessage id = {'landingPage.studyClosedHero.finished.title.text'} />
            </h1>
            <div className = {'info'}>
                <p>
                    <FormattedMessage id = {'landingPage.studyClosedHero.finished.paragraphOne.text'} />
                </p>
                <p className = {'no-margin'}>
                    <FormattedMessage
                        id = {'landingPage.studyClosedHero.finished.paragraphTwo.text'}
                        values = {{
                            link: (chunks) => (
                                <Link
                                    className = {'contact-link'}
                                    rel = {'noreferrer'}
                                    target = {'_blank'}
                                    url = {intl.formatMessage({id: 'support.email'})}
                                >
                                    {chunks}
                                </Link>
                            ),
                        }}
                    />
                </p>
                <p>
                    <FormattedMessage
                        id = {'landingPage.studyClosedHero.finished.paragraphThree.text'}
                    />
                </p>
            </div>
        </GridContainer>
    );
}
