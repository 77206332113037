import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

export function LegalFooterSection() {
    return (
        <div className = {'legal-footer-section'}>
            <div className = {'legal-links'}>
                <Link
                    className = {'link'}
                    to = {'/website-terms-and-conditions'}
                >
                    <span>
                        <FormattedMessage
                            id = {'report.navigation.links.websiteTermsAndConditions.text'}
                        />
                    </span>
                </Link>
                <span>{' / '}</span>
                <Link
                    className = {'link'}
                    to = {'/website-privacy-notice'}
                >
                    <span>
                        <FormattedMessage id = {'report.navigation.links.websitePrivacyNotice.text'} />
                    </span>
                </Link>
            </div>
            <p
                className = {'is-caption is-second is-dark copyright'}
                data-test-id = {'copyright'}
            >
                <FormattedMessage
                    id = {'common.footer.copyright.text'}
                    values = {{currentYear: new Date().getUTCFullYear()}}
                />
            </p>
        </div>
    );
}
