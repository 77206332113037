import {datadogRum} from '@datadog/browser-rum';
import {
    createContext,
    useContext,
    useEffect,
    useReducer,
} from 'react';
import {
    ErrorNotice,
    Loading,
} from '../components';
import {MinervaConfig} from './MinervaConfig';

// The Minerva context that can be used by the application to provide things like the application configuration, or as a
// place to aggregate data while progressing through the application workflow...
const Context = createContext({});

const SET_CONFIG = 'SET_CONFIG';
const SET_ERROR = 'SET_ERROR';
const SET_LOADING = 'SET_LOADING';

const initialState = {
    config: null,
    error: null,
    loading: true,
};

const reducer = (state, {payload, type}) => {
    switch (type) {
        case SET_CONFIG:
            return {
                ...state,
                config: payload,
            };

        case SET_ERROR:
            return {
                ...state,
                error: payload,
            };

        case SET_LOADING:
            return {
                ...state,
                loading: payload,
            };

        default:
            return state;
    }
};

// A general context access hook, which gives access to everything within the context...
export const useMinervaContext = () => useContext(Context);

// A hook for accessing only the application config...
export const useMinervaConfig = () => {
    const {minervaConfig} = useContext(Context);
    return minervaConfig;
};

// A React component that hides the fact that we're really setting up a context...
export function MinervaContext(props) {
    const {children} = props;
    const [{config, error, loading}, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        dispatch({
            payload: true,
            type: SET_LOADING,
        });

        const configureUI = async () => {
            // Attempt to get the application configuration...
            const minervaConfig = await MinervaConfig.init();
            dispatch({
                payload: minervaConfig,
                type: SET_CONFIG,
            });

            if (minervaConfig?.servicesDatadog) {
                datadogRum.init({
                    allowedTracingOrigins: [minervaConfig.servicesBackend],
                    applicationId: minervaConfig.servicesDatadogApplicationId,
                    clientToken: minervaConfig.servicesDatadogClientToken,
                    defaultPrivacyLevel: 'mask',
                    env: minervaConfig.servicesDatadogEnv,
                    sampleRate: 100,
                    service: minervaConfig.servicesDatadogService,
                    site: minervaConfig.servicesDatadogSite,
                    trackInteractions: true,
                    // Specify a version number to identify the deployed version of your application in Datadog
                    version: minervaConfig.version,
                });
                datadogRum.startSessionReplayRecording();
            }
        };

        configureUI()
            .catch((err) => {
                dispatch({
                    payload: err,
                    type: SET_ERROR,
                });
            })
            .finally(() => {
                dispatch({
                    payload: false,
                    type: SET_LOADING,
                });
            });
    }, []);

    if (loading) {
        return <Loading withPageLayout = {true} />;
    }

    if (error) {
        return (
            <div className = {'page-layout'}>
                <ErrorNotice fullPage = {true} />
            </div>
        );
    }

    const contextValue = {minervaConfig: config};
    return <Context.Provider value = {contextValue}>{children}</Context.Provider>;
}

// Old skool higher order component for accessing everything within the context...
export function withMinervaContext(Component) {
    return function wrappedComponent(props) {
        return (
            <Context.Consumer>
                {(value) => (
                    <Component
                        {...props}
                        minervaContext = {value}
                    />
                )}
            </Context.Consumer>
        );
    };
}
