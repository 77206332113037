import {useIntl} from 'react-intl';
import {Footer} from '../../../../denim';
import {LegalFooterSection} from '../../../components';

export function DashboardFooter() {
    const intl = useIntl();

    return (
        <Footer>
            <nav aria-label = {intl.formatMessage({id: 'common.footer.label'})}>
                <LegalFooterSection />
            </nav>
        </Footer>
    );
}
