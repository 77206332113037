import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {Link as InternalLink} from 'react-router-dom';
import {Link} from '../../../denim/link';

export const richTextFormatter = (intl) => ({
    bold: (chunks) => <strong>{chunks}</strong>,
    dpo: (chunks) => (
        <Link
            rel = {'noreferrer'}
            target = {'_blank'}
            url = {intl.formatMessage({id: 'dataProtection.email'})}
        >
            {chunks}
        </Link>
    ),
    email: (chunks) => (
        <Link
            rel = {'noreferrer'}
            target = {'_blank'}
            url = {intl.formatMessage({id: 'support.email'})}
        >
            {chunks}
        </Link>
    ),
    icf: (chunks) => <em>{chunks}</em>,
    italic: (chunks) => <em>{chunks}</em>,
    nbsp: () => <>&#160;</>,
    spn: (chunks) => (
        <InternalLink
            className = {'link'}
            to = {'/study-privacy-notice'}
        >
            <span>{chunks}</span>
        </InternalLink>
    ),
    wpn: (chunks) => (
        <InternalLink
            className = {'link'}
            to = {'/website-privacy-notice'}
        >
            <span>{chunks}</span>
        </InternalLink>
    ),
    wtc: (chunks) => (
        <InternalLink
            className = {'link'}
            to = {'/website-terms-and-conditions'}
        >
            <span>{chunks}</span>
        </InternalLink>
    ),
});

export function StaticContent(props) {
    const {page, schema} = props;

    const intl = useIntl();

    return schema.map(({title: section, paragraphs}) => (
        <div
            className = {'content-item'}
            key = {section}
        >
            <h2>
                <FormattedMessage id = {`${page}.${section}.title`} />
            </h2>
            {paragraphs.map((paragraph) => (
                <p key = {`${section}-${paragraph}`}>
                    <FormattedMessage
                        id = {`${page}.${section}.paragraph.${paragraph}`}
                        values = {richTextFormatter(intl)}
                    />
                </p>
            ))}
        </div>
    ));
}
