import {Auth0Provider} from '@auth0/auth0-react';
import {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {useMinervaConfig} from '../config';

export const Auth0ProviderWithHistory = ({children}) => {
    const history = useHistory();
    const minervaConfig = useMinervaConfig();

    const onRedirectCallback = useCallback(
        (appState) => {
            // Use the router's history to replace the url
            history.push(appState?.returnTo || window.location.pathname);
        },
        [history]
    );

    return (
        <Auth0Provider
            audience = {minervaConfig.auth0Audience}
            cacheLocation = {'localstorage'}
            clientId = {minervaConfig.auth0ClientId}
            domain = {minervaConfig.auth0Domain}
            onRedirectCallback = {onRedirectCallback}
            redirectUri = {window.location.origin}
            useRefreshTokens = {true}
        >
            {children}
        </Auth0Provider>
    );
};
