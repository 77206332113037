import {useBreakpoints} from '../common';
import {Breakpoint} from '../constants';
import {NavDesktop} from './nav-desktop';
import {NavMobile} from './nav-mobile';

export function Nav(props) {
    const {children, ...otherProps} = props;

    const currentBreakpoint = useBreakpoints();

    const NavComponent = [Breakpoint.TABLET_LANDSCAPE, Breakpoint.DESKTOP].includes(currentBreakpoint)
        ? NavDesktop
        : NavMobile;

    return <NavComponent {...otherProps}>{children}</NavComponent>;
}
