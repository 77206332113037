import classNames from 'classnames';
import React, {
    useCallback,
    useEffect,
    useReducer,
    useRef,
    useState,
} from 'react';
import Modal from 'react-modal';
import {action} from '../common';
import {TextInput} from '../text-input';
import {AutoCompleteOptionItem} from './AutoCompleteOptionItem';

const CLOSE_AUTO_COMPLETE = 'CLOSE_AUTO_COMPLETE';
const DEFAULT_MAX_MATCHES = 10;
const EXACT = 'EXACT';
const INEXACT = 'INEXACT';
const SET_AUTO_COMPLETE = 'SET_AUTO_COMPLETE';
const SELECT_AUTO_COMPLETE_VALUE = 'SELECT_AUTO_COMPLETE_VALUE';

const init = (initialValue = '') => ({
    formGroupClassName: null,
    showOptions: false,
    textInputValue: initialValue,
});

const reducer = (state, {type, payload}) => {
    switch (type) {
        case CLOSE_AUTO_COMPLETE:
            return {
                ...state,
                formGroupClassName: null,
                showOptions: false,
            };

        case SET_AUTO_COMPLETE:
            return {
                ...state,
                formGroupClassName: payload ? 'remove-controls-bottom-margin' : null,
                showOptions: payload ? true : false,
                textInputValue: payload,
            };

        case SELECT_AUTO_COMPLETE_VALUE:
            return {
                ...state,
                formGroupClassName: null,
                showOptions: false,
                textInputValue: payload,
            };

        default:
            return state;
    }
};

export const findExactOptions = (options, text, caseSensitive) => {
    const foundOptions = [];

    for (const option of options) {
        if (
            (caseSensitive && option.startsWith(text)) ||
            (!caseSensitive && option.toUpperCase().startsWith(text.toUpperCase()))
        ) {
            foundOptions.push(option);
        }
    }

    return foundOptions;
};

export const findInexactOptions = (options, text, caseSensitive) => {
    const foundOptions = [];

    for (const option of options) {
        if (
            (caseSensitive && option.includes(text)) ||
            (!caseSensitive && option.toUpperCase().includes(text.toUpperCase()))
        ) {
            foundOptions.push(option);
        }
    }

    return foundOptions;
};

export function AutoCompleteTextInput(props) {
    const {
        caseSensitive = false,
        className,
        customMatchingFunction,
        dataTestId,
        getOptionDisplayText = (option) => option,
        matching = EXACT,
        maxMatches = DEFAULT_MAX_MATCHES,
        noOptionsFoundMessage = '',
        onOptionSelect,
        options = [],
        optionItemKey,
        stressMatchingText = true,
        textInputProps = {value: ''},
        value,
    } = props;

    const [{formGroupClassName, showOptions, textInputValue}, dispatch] = useReducer(
        reducer,
        textInputProps.value,
        init
    );

    const autoCompleteContainer = useRef(null);
    const autoCompleteInput = useRef(null);
    const [highlightedItemIndex, setHighlightedItemIndex] = useState(-1);

    let matchingFunction = customMatchingFunction;
    if (!matchingFunction) {
        if (matching === INEXACT) {
            matchingFunction = findInexactOptions;
        }
        else {
            matchingFunction = findExactOptions;
        }
    }

    const matchedOptions = showOptions ? matchingFunction(options, textInputValue, caseSensitive) : [];

    // Wraps matching text in suggestion with <strong> tag to emphasise it.
    const getOptionDisplayTextStressed = (option) => {
        const displayOption = getOptionDisplayText(option);
        const superString = caseSensitive ? displayOption : displayOption.toUpperCase();
        const subString = caseSensitive ? textInputValue : textInputValue.toUpperCase();

        // Split input text by spaces, find earliest and latest matching start and end index
        const splitSubSting = subString.split(' ');
        let minStartIndex = superString.length;
        let maxEndIndex = 0;
        for (const word of splitSubSting) {
            const startIndex = superString.indexOf(word);
            const endIndex = startIndex + word.length;

            if (startIndex >= 0 && startIndex < minStartIndex) {
                minStartIndex = startIndex;
            }
            if (endIndex > maxEndIndex) {
                maxEndIndex = endIndex;
            }
        }

        if (maxEndIndex <= 0 || minStartIndex >= displayOption.length) {
            return displayOption;
        }

        const displayOutput = (
            <>
                <strong>{displayOption.slice(0, minStartIndex)}</strong>
                {displayOption.slice(minStartIndex, maxEndIndex)}
                <strong>{displayOption.slice(maxEndIndex)}</strong>
            </>
        );

        return displayOutput;
    };

    const optionDisplayTextFormat = stressMatchingText
        ? getOptionDisplayTextStressed
        : getOptionDisplayText;

    useEffect(() => {
        const selectedValue = value ? getOptionDisplayText(value) : '';

        dispatch(action(SELECT_AUTO_COMPLETE_VALUE, selectedValue));
    }, [value]);

    const onClickHandler = useCallback(
        (event, option) => {
            dispatch(action(SELECT_AUTO_COMPLETE_VALUE, getOptionDisplayText(option)));

            // Provide a way for parent component to get selected value.
            if (onOptionSelect) {
                onOptionSelect(option);
            }
        },
        [matchedOptions]
    );

    const keyDownHandler = (event) => {
        const UP_ARROW_KEY_CODE = 38;
        const DOWN_ARROW_KEY_CODE = 40;
        const ENTER_KEY_CODE = 13;

        // No need to handle these events when the items list is hidden
        if (!showOptions) {
            return;
        }

        let nextIdx = highlightedItemIndex;
        const totalDisplayedItems = Math.min(matchedOptions.length, maxMatches);

        if (event.keyCode === DOWN_ARROW_KEY_CODE) {
            nextIdx = nextIdx >= totalDisplayedItems - 1 ? nextIdx : nextIdx + 1;
        }
        else if (event.keyCode === UP_ARROW_KEY_CODE) {
            nextIdx = nextIdx <= 0 ? nextIdx : nextIdx - 1;
        }
        else if (event.keyCode === ENTER_KEY_CODE) {
            onClickHandler(event, matchedOptions[highlightedItemIndex]);
        }

        setHighlightedItemIndex(nextIdx);
    };

    useEffect(() => {
        const {current: autoCompleteInputNode} = autoCompleteInput;
        autoCompleteInputNode.addEventListener('keydown', keyDownHandler);

        return () => autoCompleteInputNode.removeEventListener('keydown', keyDownHandler);
    }, [highlightedItemIndex, options, showOptions, maxMatches, matchedOptions]);

    useEffect(() => {
        if (!showOptions) {
            setHighlightedItemIndex(-1);
        }
    }, [showOptions]);

    const onChangeHandler = useCallback(
        (inputValue, name) => {
            dispatch(action(SET_AUTO_COMPLETE, inputValue));

            if (textInputProps.onChange) {
                textInputProps.onChange(inputValue, name);
            }

            // Clearing the text input deselects any previous selection
            if (!inputValue && onOptionSelect) {
                onOptionSelect(null);
            }
        },
        [textInputProps.onChange]
    );

    const onBlurHandler = useCallback(
        (event) => {
            // Only handle blur if focus is outside of entire component, not if focus left any child element
            const {current: autoCompleteParentNode} = autoCompleteContainer;

            if (!autoCompleteParentNode.contains(event.relatedTarget)) {
                const previouslySelected = value ? getOptionDisplayText(value) : '';

                dispatch(action(SELECT_AUTO_COMPLETE_VALUE, previouslySelected));
                dispatch(action(CLOSE_AUTO_COMPLETE, null));
            }
        },
        [value]
    );

    // Mount Modal component to this element or default to document.body if not available i.e in unit tests
    const modalParentSelector = useCallback(
        () => document.querySelector('.auto-complete-text-input') || document.body
    );

    let matchedOptionsDisplay = (
        <ul>
            <li
                className = {'no-options-found icon warning before'}
                data-test-id = {'auto-complete-text-input-no-options-found'}
            >
                {noOptionsFoundMessage}
            </li>
        </ul>
    );

    if (matchedOptions.length > 0) {
        matchedOptionsDisplay = (
            <ul className = {'auto-complete-text-input-option-list'}>
                {matchedOptions.slice(0, maxMatches).map((option, idx) => (
                    <AutoCompleteOptionItem
                        getOptionDisplayText = {optionDisplayTextFormat}
                        highlightedItemIndex = {highlightedItemIndex}
                        itemIndex = {idx}
                        key = {option[optionItemKey] || idx}
                        onClick = {onClickHandler}
                        option = {option}
                        setHighlightedItemIndex = {setHighlightedItemIndex}
                    />
                ))}
            </ul>
        );
    }

    const classes = classNames('auto-complete-text-input', className);

    return (
        <div
            className = {classes}
            data-test-id = {dataTestId}
            onBlur = {onBlurHandler}
            ref = {autoCompleteContainer}
        >
            <TextInput
                {...textInputProps}
                formGroupClassName = {formGroupClassName}
                innerRef = {autoCompleteInput}
                onChange = {onChangeHandler}
                value = {textInputValue}
            />
            <Modal
                className = {'auto-complete-text-input-modal'}
                isOpen = {showOptions}
                overlayClassName = {'auto-complete-text-input-overlay'}
                parentSelector = {modalParentSelector}
                shouldCloseOnEsc = {true}
                shouldFocusAfterRender = {false}
            >
                {matchedOptionsDisplay}
            </Modal>
        </div>
    );
}
