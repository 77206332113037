import {
    useCallback,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Button,
    ErrorCard,
    Link,
    TextInput,
} from '../../../../denim';
import {useApi} from '../../../auth0';
import {
    action,
    HTTP_BAD_REQUEST,
} from '../../../common';
import {Divider} from '../../../components';
import {useMinervaConfig} from '../../../config';
import {useRegistrationContext} from '../RegistrationContext';

const ERROR_RAD_REQUEST = 'ERROR_RAD_REQUEST';
const ERROR_SERVER_ERROR = 'ERROR_SERVER_ERROR';
const ERROR_VALIDATION_FAILED = 'ERROR_VALIDATION_FAILED';
const SET_ERROR = 'SET_ERROR';
const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
const SET_IS_LOADING = 'SET_IS_LOADING';
const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';

const phoneNumberFormatRegexString = '^[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4}$';
const phoneNumberFormatRegex = new RegExp(phoneNumberFormatRegexString, 'm');

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_ERROR:
            return {
                ...state,
                hasError: payload,
                isLoading: false,
            };

        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: payload,
            };

        case SET_IS_LOADING:
            return {
                ...state,
                isLoading: payload,
            };

        case SET_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: payload,
            };

        default:
            return state;
    }
};

const init = (phoneNumber) => ({
    errorMessage: null,
    hasError: null,
    isLoading: false,
    phoneNumber: phoneNumber,
});

export function PhoneNumber() {
    const [authenticatedFetch] = useApi();
    const intl = useIntl();
    const {servicesBackend} = useMinervaConfig();
    const {
        nextStep,
        previousStep,
        state: {data: {aboutYou: {displayContactNumber} = {}} = {}} = {},
        updateAboutYouField,
    } = useRegistrationContext();
    const [{errorMessage, hasError, phoneNumber, isLoading}, dispatch] = useReducer(
        reducer,
        displayContactNumber,
        init
    );

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const onBlurHandler = useCallback((value) => {
        let errorMessageToDisplay = intl.formatMessage({id: 'registration.common.error.required.text'});

        if (value && !phoneNumberFormatRegex.test(value)) {
            errorMessageToDisplay = intl.formatMessage({id: 'registration.aboutYou.phoneNumber.error.incorrectFormat.text'});
        }

        dispatch(action(SET_ERROR_MESSAGE, errorMessageToDisplay));
    }, []);

    const onChangeHandler = useCallback((value) => {
        dispatch(action(SET_PHONE_NUMBER, value));
    }, []);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            // Check validity cause an invalid event to be triggered on anything that is invalid, which will cause them
            // to display their error state.
            const form = event.target;
            const formValid = form.checkValidity();

            // If the form if valid, transition to the next step...
            if (formValid) {
                dispatch(action(SET_IS_LOADING, true));

                try {
                    // Grab the 10 digits of the phone number and put them in format XXX-XXX-XXXX
                    const phoneDigits = phoneNumber.match(/\d/g);
                    /* eslint-disable no-magic-numbers */
                    phoneDigits.splice(3, 0, '-');
                    phoneDigits.splice(7, 0, '-');
                    /* eslint-enable no-magic-numbers */
                    const formattedPhoneNumber = phoneDigits.join('');

                    /* eslint-disable camelcase */
                    const response = await authenticatedFetch(
                        `${servicesBackend}/validation/phone-number`,
                        {
                            body: JSON.stringify({
                                country_code: '+1',
                                phone_number: formattedPhoneNumber,
                            }),
                            method: 'POST',
                        }
                    );

                    if (response.ok) {
                        updateAboutYouField('contactNumber', formattedPhoneNumber);
                        updateAboutYouField('displayContactNumber', phoneNumber);
                        nextStep();
                    }
                    else {
                        dispatch(
                            action(
                                SET_ERROR,
                                response.status === HTTP_BAD_REQUEST
                                    ? ERROR_RAD_REQUEST
                                    : ERROR_VALIDATION_FAILED
                            )
                        );
                    }
                    /* eslint-enable camelcase */
                }
                catch (error) {
                    dispatch(action(SET_ERROR, ERROR_SERVER_ERROR));
                }
            }
            else {
                // Change displayed error message based on type of error (empty string or invalid format)
                dispatch(
                    action(
                        SET_ERROR_MESSAGE,
                        phoneNumber
                            ? intl.formatMessage({id: 'registration.aboutYou.phoneNumber.error.incorrectFormat.text'})
                            : intl.formatMessage({id: 'registration.common.error.required.text'})
                    )
                );
            }
        },
        [phoneNumber]
    );

    return (
        <div
            className = {'content'}
            data-test-id = {'phone-number'}
        >
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2
                    className = {'question'}
                    data-test-id = {'phone-number-question'}
                >
                    <FormattedMessage id = {'registration.aboutYou.phoneNumber.question.text'} />
                </h2>
                <p className = {'description'}>
                    <FormattedMessage id = {'registration.aboutYou.phoneNumber.question.description'} />
                </p>
            </div>
            <Divider />
            {hasError && (
                <ErrorCard
                    dataTestId = {'contact-error'}
                    title = {intl.formatMessage({id: `registration.aboutYou.phoneNumber.error.${hasError}.title`})}
                >
                    <p>
                        <FormattedMessage
                            id = {`registration.aboutYou.phoneNumber.error.${hasError}.description`}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            id = {`registration.aboutYou.phoneNumber.error.${hasError}.support`}
                            values = {{
                                link: (chunks) => (
                                    <Link
                                        rel = {'noreferrer'}
                                        target = {'_blank'}
                                        url = {intl.formatMessage({id: 'support.email'})}
                                    >
                                        {chunks}
                                    </Link>
                                ),
                            }}
                        />
                    </p>
                </ErrorCard>
            )}
            <div className = {'question-options text-based-input'}>
                <form
                    noValidate = {true}
                    onSubmit = {onSubmitHandler}
                >
                    <TextInput
                        dataTestId = {'contact-input'}
                        description = {intl.formatMessage({id: 'registration.aboutYou.phoneNumber.phoneNumber.description'})}
                        errorMessage = {errorMessage}
                        id = {'phoneNumber'}
                        label = {intl.formatMessage({id: 'registration.aboutYou.phoneNumber.phoneNumber.label'})}
                        name = {'contactNumber'}
                        onBlur = {onBlurHandler}
                        onChange = {onChangeHandler}
                        pattern = {phoneNumberFormatRegexString}
                        required = {true}
                        type = {'tel'}
                        value = {phoneNumber}
                    />
                    <Button
                        dataTestId = {'continue-button'}
                        isLoading = {isLoading}
                        submit = {true}
                    >
                        <FormattedMessage id = {'registration.common.button.continue.text'} />
                    </Button>
                </form>
            </div>
        </div>
    );
}
