import {useAuth0} from '@auth0/auth0-react';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from 'react';
import {useIntl} from 'react-intl';
import {
    useHistory,
    useLocation,
} from 'react-router-dom';
import {
    action,
    APP_DATA_STORAGE_KEY,
    FLOW_STORAGE_KEY,
    PolicyholderState,
    useStore,
} from '../../common';
import {Loading} from '../../components';
import {usePolicyholderContext} from '../../context';

const CHANGE_NUMBER_OF_STEPS = 'CHANGE_NUMBER_OF_STEPS';
const COMPLETE_SECTION = 'COMPLETE_SECTION';
const COMPLETE_TOPIC = 'COMPLETE_TOPIC';
const LOAD_APP_DATA = 'LOAD_APP_DATA';
const LOAD_FLOW_DATA = 'LOAD_FLOW_DATA';
const MAKE_TOPIC_ACCESSIBLE = 'MAKE_TOPIC_ACCESSIBLE';
const SET_ASKING_FOR_NAME = 'SET_ASKING_FOR_NAME';
const SET_HOME_ADDRESS_AS_SHIPPING_ADDRESS = 'SET_HOME_ADDRESS_AS_SHIPPING_ADDRESS';
const SET_REHYDRATING = 'SET_REHYDRATING';
const UPDATE_ABOUT_YOU_FIELD = 'UPDATE_ABOUT_YOU_FIELD';
const UPDATE_MEDICAL_HISTORY_FIELD = 'UPDATE_MEDICAL_HISTORY_FIELD';
const UPDATE_FAMILY_HISTORY_FIELD = 'UPDATE_FAMILY_HISTORY_FIELD';
const UPDATE_GENETIC_TESTING_FIELD = 'UPDATE_GENETIC_TESTING_FIELD';
const UPDATE_SHIPPING_DETAILS_FIELD = 'UPDATE_SHIPPING_DETAILS_FIELD';

const ABOUT_YOU_ONE_EXTRA_STEP = 9;
const ABOUT_YOU_TWO_EXTRA_STEPS = 10;

function init(intl) {
    return {
        data: {
            aboutYou: {
                assignedSex: '',
                contactNumber: '',
                dateOfBirth: {
                    day: '',
                    month: '',
                    year: '',
                },
                displayContactNumber: '',
                ethnicity: '',
                firstName: '',
                homeAddress: {
                    apartment: '',
                    city: '',
                    state: '',
                    street: '',
                    zipCode: '',
                },
                homeAddressAutoComplete: null,
                identifyAs: '',
                lastName: '',
                twoOrMoreRacesOrEthnicities: '',
            },
            familyHistory: {
                breastCancer: {
                    bothBreasts: false,
                    fortyFiveOrYounger: false,
                    maleBreastCancer: false,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    tripleNegative: false,
                    unknown: false,
                },
                colorectalCancer: '',
                general: {
                    breast: false,
                    colorectal: false,
                    noneOfTheAbove: false,
                    ovarian: false,
                    pancreatic: false,
                    preferNotToSay: false,
                    prostate: false,
                    unknown: false,
                },
                pancreaticCancer: {
                    child: false,
                    distantRelative: false,
                    fullSibling: false,
                    noneOfTheAbove: false,
                    parent: false,
                    preferNotToSay: false,
                    unknown: false,
                },
                prostateCancer: {
                    diedFrom: false,
                    metaStatic: false,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    unknown: false,
                },
            },
            geneticTesting: {
                geneticResultsFamily: {
                    breastCancer: false,
                    hypercholesterolemia: false,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    prostateCancer: false,
                    unknown: false,
                },
                geneticResultsPersonal: {
                    breastCancer: false,
                    hypercholesterolemia: false,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    prostateCancer: false,
                    unknown: false,
                },
                previouslyTested: '',
            },
            medicalHistory: {
                cancers: {
                    abnormalBreastBiopsy: false,
                    abnormalProstateBiopsy: false,
                    cancer: false,
                    mastectomy: false,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    unknown: false,
                },
                diabetes: '',
                heartCondition: {
                    angioplasty: false,
                    coronaryArteryBypassSurgery: false,
                    coronaryArteryDisease: false,
                    heartAttack: false,
                    ldl: false,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    stent: false,
                    stroke: false,
                    unknown: false,
                },
            },
            shippingDetails: {
                addressToShipTo: '',
                apartment: '',
                autoCompleteAddress: null,
                city: '',
                recipientName: '',
                state: '',
                street: '',
                zipCode: '',
            },
        },
        flow: [
            {
                accessible: false,
                completed: false,
                hidePrivacyNotice: true,
                key: '/registration/welcome',
                steps: 1,
                title: intl.formatMessage({id: 'registration.progressBar.topic.letsGetStarted.text'}),
            },
            {
                accessible: false,
                completed: false,
                hidePrivacyNotice: true,
                key: '/registration/eligibility',
                steps: 2,
                title: intl.formatMessage({id: 'registration.progressBar.topic.eligibility.text'}),
            },
            {
                accessible: false,
                completed: false,
                hidePrivacyNotice: true,
                key: '/registration/account-setup',
                steps: 1,
                title: intl.formatMessage({id: 'registration.progressBar.topic.accountSetup.text'}),
            },
            {
                accessible: false,
                completed: false,
                hidePrivacyNotice: false,
                key: '/registration/study-consent',
                steps: 8,
                title: intl.formatMessage({id: 'registration.progressBar.topic.studyConsent.text'}),
            },
            {
                accessible: false,
                askingForName: false,
                completed: false,
                hidePrivacyNotice: false,
                key: '/registration/about-you',
                steps: 8,
                title: intl.formatMessage({id: 'registration.progressBar.topic.aboutYou.text'}),
            },
            {
                accessible: false,
                completed: false,
                hidePrivacyNotice: false,
                key: '/registration/medical-history',
                steps: 4,
                title: intl.formatMessage({id: 'registration.progressBar.topic.medicalHistory.text'}),
            },
            {
                accessible: false,
                completed: false,
                hidePrivacyNotice: false,
                key: '/registration/family-history',
                steps: 2,
                title: intl.formatMessage({id: 'registration.progressBar.topic.familyHistory.text'}),
            },
            {
                accessible: false,
                completed: false,
                hidePrivacyNotice: false,
                key: '/registration/genetic-testing',
                steps: 1,
                title: intl.formatMessage({id: 'registration.progressBar.topic.geneticTesting.text'}),
            },
            {
                accessible: false,
                completed: false,
                hidePrivacyNotice: false,
                key: '/registration/shipping-details',
                steps: 1,
                title: intl.formatMessage({id: 'registration.progressBar.topic.shippingDetails.text'}),
            },
            {
                accessible: false,
                completed: false,
                hidePrivacyNotice: false,
                key: '/registration/summary',
                steps: 2,
                title: intl.formatMessage({id: 'registration.progressBar.topic.summary.text'}),
            },
        ],
        rehydrating: false,
    };
}

const ABOUT_YOU_TOPIC_INDEX = () => {
    const intl = useIntl();
    return init(intl).flow.findIndex(({key}) => key === '/registration/about-you');
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case COMPLETE_SECTION:
            return {
                ...state,
                flow: state.flow.map((topic) => {
                    if (payload.includes(topic.key)) {
                        return {
                            ...topic,
                            accessible: false,
                            completed: true,
                        };
                    }

                    return topic;
                }),
            };

        case LOAD_APP_DATA:
            return {
                ...state,
                data: payload,
            };

        case LOAD_FLOW_DATA:
            return {
                ...state,
                flow: payload,
            };

        case COMPLETE_TOPIC:
            return {
                ...state,
                flow: state.flow.map((topic) => {
                    if (payload === topic.key) {
                        return {
                            ...topic,
                            completed: true,
                        };
                    }

                    return topic;
                }),
            };

        case MAKE_TOPIC_ACCESSIBLE:
            return {
                ...state,
                flow: state.flow.map((topic) => {
                    if (payload === topic.key) {
                        return {
                            ...topic,
                            accessible: true,
                        };
                    }

                    return topic;
                }),
            };

        case UPDATE_ABOUT_YOU_FIELD:
            return {
                ...state,
                data: {
                    ...state.data,
                    aboutYou: {
                        ...state.data.aboutYou,
                        [payload.name]: payload.value,
                    },
                },
            };

        case UPDATE_FAMILY_HISTORY_FIELD:
            return {
                ...state,
                data: {
                    ...state.data,
                    familyHistory: {
                        ...state.data.familyHistory,
                        [payload.name]: payload.value,
                    },
                },
            };

        case UPDATE_MEDICAL_HISTORY_FIELD:
            return {
                ...state,
                data: {
                    ...state.data,
                    medicalHistory: {
                        ...state.data.medicalHistory,
                        [payload.name]: payload.value,
                    },
                },
            };

        case UPDATE_GENETIC_TESTING_FIELD:
            return {
                ...state,
                data: {
                    ...state.data,
                    geneticTesting: {
                        ...state.data.geneticTesting,
                        [payload.name]: payload.value,
                    },
                },
            };

        case UPDATE_SHIPPING_DETAILS_FIELD:
            return {
                ...state,
                data: {
                    ...state.data,
                    shippingDetails: {
                        ...state.data.shippingDetails,
                        [payload.name]: payload.value,
                    },
                },
            };

        case SET_ASKING_FOR_NAME:
            return {
                ...state,
                flow: state.flow.map((topic) => {
                    if (topic.key === '/registration/about-you') {
                        return {
                            ...topic,
                            askingForName: true,
                        };
                    }

                    return topic;
                }),
            };

        case SET_HOME_ADDRESS_AS_SHIPPING_ADDRESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    shippingDetails: {
                        ...state.data.shippingDetails,
                        autoCompleteAddress: null,
                        recipientName: `${state.data.aboutYou.firstName} ${state.data.aboutYou.lastName}`,
                        ...state.data.aboutYou.homeAddress,
                    },
                },
            };

        case CHANGE_NUMBER_OF_STEPS:
            return {
                ...state,
                flow: state.flow.map((section) => {
                    if (section.key === payload.key) {
                        return {
                            ...section,
                            steps: payload.steps,
                        };
                    }

                    return section;
                }),
            };

        case SET_REHYDRATING:
            return {
                ...state,
                rehydrating: payload,
            };

        default:
            return state;
    }
};

const Context = createContext({});

// A general context access hook, which gives access to everything within the context...
export const useRegistrationContext = () => useContext(Context);

// A helper hook that hides the fact that we're using browser history to control the flow progress...
export const useRegistrationProgress = () => {
    const {pathname: currentTopic, state: {step: currentStep = 1} = {}} = useLocation();
    return {
        currentStep,
        currentTopic,
    };
};

// A React component that hides the fact that we're really setting up a context, this also gives us the opportunity to
// process actions etc and twerk how the progress bar should look...
export function RegistrationContext(props) {
    const {children} = props;

    const intl = useIntl();
    const {user} = useAuth0();
    const history = useHistory();
    const {currentStep, currentTopic} = useRegistrationProgress();
    const {state: {policyholder} = {}} = usePolicyholderContext();
    const [state, dispatch] = useReducer(reducer, intl, init);
    const {
        flow,
        data,
        rehydrating,
    } = state;
    const store = useStore();

    // Get previously stored state from localStorage
    useEffect(() => {
        // Only attempt rehydration if the user is authenticated...
        if (user) {
            dispatch(action(SET_REHYDRATING, true));

            try {
                const appData = JSON.parse(store.get(APP_DATA_STORAGE_KEY));
                const flowData = JSON.parse(store.get(FLOW_STORAGE_KEY));

                const aboutYouTopic = flowData?.find(
                    (topic) => topic.key === '/registration/about-you'
                );

                if (flowData) {
                    dispatch(action(LOAD_FLOW_DATA, flowData));
                }

                // Work out topics to complete and make inaccessible based on user's stored state
                let topicIndex = -1;
                const topicKeys = flow.map((topic) => topic.key);

                if (user && !user.email_verified) {
                    topicIndex = topicKeys.indexOf('/registration/eligibility');
                }
                else if (policyholder?.state === PolicyholderState.REGISTERED) {
                    topicIndex = topicKeys.indexOf('/registration/account-setup');
                }
                else if (policyholder?.state === PolicyholderState.CONSENT_SIGNED) {
                    topicIndex = topicKeys.indexOf('/registration/study-consent');
                }
                else if (policyholder?.state === PolicyholderState.DETAILS_SUBMITTED) {
                    topicIndex = topicKeys.indexOf('/registration/shipping-details');
                }

                const topicsToComplete = topicKeys.slice(0, topicIndex + 1);
                dispatch(action(COMPLETE_SECTION, topicsToComplete));

                if (policyholder?.state === PolicyholderState.CONSENT_SIGNED) {
                    if (
                        aboutYouTopic?.askingForName ||
                        !appData ||
                        !appData.aboutYou.firstName ||
                        !appData.aboutYou.lastName
                    ) {
                        dispatch(action(SET_ASKING_FOR_NAME));

                        const numAboutYouSteps =
                            appData?.aboutYou?.ethnicity === 'TwoOrMoreRacesOrEthnicities'
                                ? ABOUT_YOU_TWO_EXTRA_STEPS
                                : ABOUT_YOU_ONE_EXTRA_STEP;

                        dispatch(
                            action(CHANGE_NUMBER_OF_STEPS, {
                                key: '/registration/about-you',
                                steps: numAboutYouSteps,
                            })
                        );
                    }
                }

                if (appData) {
                    dispatch(action(LOAD_APP_DATA, appData));

                    // Check the app data and see if we need to adjust the number of steps some sections have...
                    if (appData.aboutYou.ethnicity === 'TwoOrMoreRacesOrEthnicities') {
                        const numAboutYouSteps = aboutYouTopic?.askingForName
                            ? ABOUT_YOU_TWO_EXTRA_STEPS
                            : ABOUT_YOU_ONE_EXTRA_STEP;

                        dispatch(
                            action(CHANGE_NUMBER_OF_STEPS, {
                                key: '/registration/about-you',
                                steps: numAboutYouSteps,
                            })
                        );
                    }

                    if (appData.geneticTesting.previouslyTested === 'PRIOR_GENETIC_TEST_YES') {
                        dispatch(
                            action(CHANGE_NUMBER_OF_STEPS, {
                                key: '/registration/genetic-testing',
                                steps: 3,
                            })
                        );
                    }

                    if (appData.shippingDetails.addressToShipTo === 'DifferentAddress') {
                        dispatch(
                            action(CHANGE_NUMBER_OF_STEPS, {
                                key: '/registration/shipping-details',
                                steps: 3,
                            })
                        );
                    }

                    const {general} = appData.familyHistory;
                    // Skipping 'ovarian' from this list because we don't have follow up questions for it, thus no need to track as a sub-step
                    const familyHistorySubItems = ['breast', 'prostate', 'pancreatic', 'colorectal'];
                    const familyHistorySubSteps = Object.entries(general).filter(
                        ([trait, selection]) =>
                            familyHistorySubItems.includes(trait) && selection === true
                    );

                    dispatch(
                        action(CHANGE_NUMBER_OF_STEPS, {
                            key: '/registration/family-history',
                            steps: 2 + familyHistorySubSteps.length,
                        })
                    );
                }
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
            finally {
                dispatch(action(SET_REHYDRATING, false));
            }
        }
    }, [policyholder, user]);

    // Update localStorage store whenever `data` changes. Every form component manages its own state and Only updates this
    // contexts state when the user clicks `Continue`. At that point we're sure that's their final value for that entry
    useEffect(() => {
        // Only attempt to store stuff in localStorage if the user is authenticated...
        if (user) {
            try {
                store.set(APP_DATA_STORAGE_KEY, JSON.stringify(data));
                store.set(FLOW_STORAGE_KEY, JSON.stringify(flow));
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.log(error);
            }
        }
    }, [data, flow, user]);

    const flowMap = useMemo(
        () =>
            flow.reduce((res, curr) => {
                res[curr.key] = curr;
                return res;
            }, {}),
        [flow]
    );

    const showPrivacyNotice = useMemo(() => {
        const hidePrivacyNotice = flowMap[currentTopic]?.hidePrivacyNotice;
        return !hidePrivacyNotice;
    }, [currentTopic]);

    const changeNumberOfSteps = useCallback((key, steps) => {
        dispatch(
            action(CHANGE_NUMBER_OF_STEPS, {
                key,
                steps,
            })
        );
    }, []);

    const previousStep = useCallback(() => {
        // Find the topic in the list...
        const topicIndex = flow.findIndex((topic) => topic.key === currentTopic);

        // Check the current step, if it's one, then we need to go to the previous topic, otherwise we need to go to the
        // previous step in the current topic. MVP: if we're on the first step of the first topic, do nothing...
        if (topicIndex === 0 && currentStep === 1) {
            return;
        }

        let transitionToStep = currentStep;
        let transitionToTopic = currentTopic;

        if (currentStep > 1) {
            transitionToStep -= 1;
        }
        else {
            const {key, steps} = flow[topicIndex - 1];
            transitionToTopic = key;
            transitionToStep = steps;
        }

        // Transition to the previous step, or topic...
        history.push(transitionToTopic, {
            skipUserStateCheck: true,
            step: transitionToStep,
        });
    }, [currentStep, currentTopic]);

    const nextStep = useCallback(
        (options = {}) => {
            const {
                skipUserStateCheck = true,
                complete,
                registrationComplete,
                sectionComplete,
                inaccesibleFromSectionTopic,
                state: historyState,
            } = options;

            // Find the topic in the list...
            const topicIndex = flow.findIndex((topic) => topic.key === currentTopic);

            // Check the current step, if it's equal to the number of steps in that topic, then we need to go to the next
            // topic, otherwise we need to go to the next step in the current topic. MVP: if we're on the last step of the
            // last topic, do nothing...
            if (topicIndex === flow.length - 1 && currentStep === flow[topicIndex].steps) {
                return;
            }

            let transitionToStep = currentStep;
            let transitionToTopic = currentTopic;

            if (currentStep < flow[topicIndex].steps) {
                transitionToStep += 1;
            }
            else {
                const {key} = flow[topicIndex + 1];
                transitionToTopic = key;
                transitionToStep = 1;
            }

            // Make this topic as complete if the complete flag is true...
            if (complete) {
                dispatch(action(COMPLETE_TOPIC, currentTopic));
                dispatch(action(MAKE_TOPIC_ACCESSIBLE, currentTopic));
            }

            // Make all topics before inaccesibleFromSectionTopic complete and unaccessible if the sectionComplete flag is true...
            if (sectionComplete) {
                const topicKeys = flow.map((topic) => topic.key);
                const topicIndex = topicKeys.indexOf(inaccesibleFromSectionTopic);
                const topicsToComplete = topicKeys.slice(0, topicIndex + 1);
                dispatch(action(COMPLETE_SECTION, topicsToComplete));
            }

            if (registrationComplete) {
                dispatch(action(LOAD_APP_DATA, init(intl).data));
            }

            // Transition to the next step, or topic...
            history.push(transitionToTopic, {
                skipUserStateCheck: skipUserStateCheck,
                step: transitionToStep,
                ...historyState,
            });
        },
        [currentStep, currentTopic, flow]
    );

    const setHomeAddressAsShippingAddress = useCallback(() => {
        dispatch(action(SET_HOME_ADDRESS_AS_SHIPPING_ADDRESS));
    }, []);

    const updateAboutYouField = useCallback((name, value) => {
        dispatch(
            action(UPDATE_ABOUT_YOU_FIELD, {
                name,
                value,
            })
        );
    }, []);

    const updateMedicalHistoryField = useCallback((name, value) => {
        dispatch(
            action(UPDATE_MEDICAL_HISTORY_FIELD, {
                name,
                value,
            })
        );
    }, []);

    const updateGeneticTestingField = useCallback((name, value) => {
        dispatch(
            action(UPDATE_GENETIC_TESTING_FIELD, {
                name,
                value,
            })
        );
    }, []);

    const updateShippingDetailsField = useCallback((name, value) => {
        dispatch(
            action(UPDATE_SHIPPING_DETAILS_FIELD, {
                name,
                value,
            })
        );
    }, []);

    const updateFamilyHistoryField = useCallback((name, value) => {
        dispatch(
            action(UPDATE_FAMILY_HISTORY_FIELD, {
                name,
                value,
            })
        );
    }, []);

    const context = {
        changeNumberOfSteps,
        nextStep,
        previousStep,
        setHomeAddressAsShippingAddress,
        showPrivacyNotice,
        state,
        updateAboutYouField,
        updateFamilyHistoryField,
        updateGeneticTestingField,
        updateMedicalHistoryField,
        updateShippingDetailsField,
    };

    if (rehydrating) {
        return <Loading withPageLayout = {true} />;
    }

    return <Context.Provider value = {context}>{children}</Context.Provider>;
}

export {ABOUT_YOU_TOPIC_INDEX, init};
