import {useAuth0} from '@auth0/auth0-react';
import {datadogRum} from '@datadog/browser-rum';
import {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useReducer,
} from 'react';
import {action} from '../../denim/common';
import {useApi} from '../auth0';
import {useMinervaConfig} from '../config';
import {Withdrawn} from '../pages/withdrawn';

const SET_STATE = 'SET_STATE';
const SET_ERROR = 'SET_ERROR';

const initialState = {
    error: false,
    errorCode: null,
    policyholder: {},
};

const reducer = (state, {type, payload}) => {
    switch (type) {
        case SET_STATE:
            return {
                ...state,
                policyholder: payload,
            };

        case SET_ERROR:
            return {
                ...state,
                error: payload.error,
                errorCode: payload.errorCode,
            };

        default:
            return state;
    }
};

const Context = createContext({});

export const usePolicyholderContext = () => useContext(Context);
export function PolicyholderContext(props) {
    const {children} = props;
    const minervaConfig = useMinervaConfig();
    const {user} = useAuth0();
    const [authenticatedFetch] = useApi();
    const [state, dispatch] = useReducer(reducer, initialState);

    const getPolicyholder = async () => {
        try {
            const response = await authenticatedFetch(
                `${minervaConfig.servicesBackend}/policyholders/me`,
                {headers: {'Content-Type': 'text/plain'}}
            );

            if (response.ok) {
                const data = await response.json();

                dispatch(action(SET_STATE, data));
            }
            else {
                dispatch(
                    action(SET_ERROR, {
                        error: true,
                        errorCode: response.status,
                    })
                );
            }
        }
        catch (err) {
            dispatch(
                action(SET_ERROR, {
                    error: true,
                    errorCode: null,
                })
            );
        }
    };

    useEffect(() => {
        if (user) {
            datadogRum.setUser({id: user.sub});
            getPolicyholder();
        }
    }, [user]);

    // For when an API call returns an updated policyholder, so we don't need to do another API cal lto get the
    // latest state / status.
    const manuallyUpdatePolicyholderData = useCallback((policyholder) => {
        if (policyholder) {
            dispatch(action(SET_STATE, policyholder));
        }
    }, []);

    const refreshPolicyholderData = useCallback(() => {
        getPolicyholder();
    }, []);

    const contextValue = {
        manuallyUpdatePolicyholderData,
        refreshPolicyholderData,
        state,
    };

    return (
        <Context.Provider value = {contextValue}>
            {state?.policyholder?.status === 'WITHDRAWN' ? <Withdrawn /> : children}
        </Context.Provider>
    );
}
