import {
    useCallback,
    useLayoutEffect,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {
    Link,
    Nav,
    Variant,
} from '../../../denim';
import {useTitle} from '../../common';
import {
    GridContainer,
    LandingPageFooter,
    LegalFooterSection,
    richTextFormatter,
    StaticContent,
} from '../../components';

const FIRST_BLOCK_OF_PAGE_CONTENT = [
    {
        paragraphs: ['one', 'two'],
        title: 'privacyNoticeScope',
    },
    {
        paragraphs: ['one', 'two', 'three', 'four'],
        title: 'privacy',
    },
    {
        paragraphs: ['one', 'two'],
        title: 'thisPolicyAppliesTo',
    },
    {
        paragraphs: ['one'],
        title: 'theDataWeCollect',
    },
    {
        paragraphs: ['one'],
        title: 'sellingYourData',
    },
    {
        paragraphs: ['one'],
        title: 'howYourDataIsCollected',
    },
    {
        paragraphs: ['one'],
        title: 'dataRetention',
    },
    {
        paragraphs: ['one'],
        title: 'transferringYourData',
    },
    {
        paragraphs: ['one'],
        title: 'dataSecurity',
    },
    {
        paragraphs: ['one'],
        title: 'cookies',
    },
];

const FINAL_BLOCK_OF_PAGE_CONTENT = [
    {
        paragraphs: ['one'],
        title: 'thirdPartyAccess',
    },
    {
        paragraphs: ['one'],
        title: 'turnOffCookies',
    },
    {
        paragraphs: ['one', 'two', 'three'],
        title: 'yourRights',
    },
    {
        paragraphs: ['one'],
        title: 'contactUs',
    },
    {
        paragraphs: ['one'],
        title: 'changingTheseTerms',
    },
];

export function WebsitePrivacyNotice() {
    const history = useHistory();
    const intl = useIntl();

    useLayoutEffect(() => {
        // When someone comes in via a link, they probably want to start at the top of the page, not halfway down...
        window.scrollTo(0, 0);
    }, []);

    useTitle(intl.formatMessage({id: 'websitePrivacyNotice.documentTitle'}));

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        history.goBack();
    }, []);

    return (
        <>
            <header
                aria-label = {'Page header'}
                className = {'page-header'}
                role = {'banner'}
            >
                <Nav
                    closeLinkAriaLabel = {intl.formatMessage({id: 'common.close.label'})}
                    footerContent = {<LegalFooterSection />}
                    openLinkAriaLabel = {intl.formatMessage({id: 'common.open.label'})}
                    variant = {Variant.LIGHT}
                >
                    <ul role = {'menu'}>
                        <li
                            className = {'after icon launch'}
                            role = {'menuitem'}
                        >
                            <Link
                                target = {'_blank'}
                                url = {'/frequently-asked-questions'}
                            >
                                <FormattedMessage
                                    id = {'report.navigation.links.geneticCounseling.text'}
                                />
                            </Link>
                        </li>
                    </ul>
                </Nav>
            </header>
            <section
                className = {'page-content static-content'}
                role = {'main'}
            >
                <GridContainer containerClassName = {'content-section'}>
                    <div className = {'page-title'}>
                        <Link
                            before = {true}
                            icon = {'chevron--left'}
                            onClick = {onBackClickHandler}
                        >
                            <FormattedMessage id = {'staticPages.common.link.back.text'} />
                        </Link>
                        <h1>
                            <FormattedMessage id = {'websitePrivacyNotice.pageTitle.text'} />
                        </h1>
                    </div>
                </GridContainer>
                <GridContainer
                    containerClassName = {'content-section'}
                    variant = {Variant.LIGHT}
                >
                    <StaticContent
                        page = {'websitePrivacyNotice'}
                        schema = {FIRST_BLOCK_OF_PAGE_CONTENT}
                    />
                    <div className = {'content-item'}>
                        <h2>
                            <FormattedMessage id = {'websitePrivacyNotice.useOfCookies.title'} />
                        </h2>
                        <p>
                            <FormattedMessage id = {'websitePrivacyNotice.useOfCookies.paragraph.one'} />
                        </p>
                        <p>
                            <FormattedMessage id = {'websitePrivacyNotice.useOfCookies.paragraph.two'} />
                        </p>
                        <ul>
                            <li>
                                <FormattedMessage
                                    id = {'websitePrivacyNotice.useOfCookies.service.datadog'}
                                    values = {richTextFormatter(intl)}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'websitePrivacyNotice.useOfCookies.service.auth0'}
                                    values = {richTextFormatter(intl)}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'websitePrivacyNotice.useOfCookies.service.intercom'}
                                    values = {richTextFormatter(intl)}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'websitePrivacyNotice.useOfCookies.service.youtube'}
                                    values = {richTextFormatter(intl)}
                                />
                            </li>
                            <li>
                                <FormattedMessage
                                    id = {'websitePrivacyNotice.useOfCookies.service.docusign'}
                                    values = {richTextFormatter(intl)}
                                />
                            </li>
                        </ul>
                        <p>
                            <FormattedMessage
                                id = {'websitePrivacyNotice.useOfCookies.paragraph.three'}
                            />
                        </p>
                    </div>
                    <div className = {'content-table-container'}>
                        <table id = {'use-of-cookies'}>
                            <thead>
                                <tr>
                                    <th scope = {'col'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.header.one.text'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </th>
                                    <th scope = {'col'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.header.two.text'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </th>
                                    <th scope = {'col'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.header.three.text'
                                            }
                                        />
                                    </th>
                                    <th scope = {'col'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.header.four.text'
                                            }
                                        />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.one.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.one.cookieName'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.one.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.one.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan = {2}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.two.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.two.cookieName'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.two.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.two.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.two.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.three.cookieName'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.three.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.three.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan = {2}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.four.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.four.cookieName'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.four.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.four.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.four.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.five.cookieName'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.five.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.five.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan = {2}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.six.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.six.cookieName'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.six.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td rowSpan = {2}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.six.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.six.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.seven.cookieName'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.seven.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.six.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan = {8}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.cookieName'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td rowSpan = {8}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.nine.cookieName'
                                            }
                                        />
                                    </td>
                                    <td rowSpan = {7}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.nine.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.description'
                                            }
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.ten.cookieName'
                                            }
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.nine.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eleven.cookieName'
                                            }
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.nine.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.twelve.cookieName'
                                            }
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.nine.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.thirteen.cookieName'
                                            }
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.nine.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.fourteen.cookieName'
                                            }
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.nine.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.description'
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.serviceProvider'
                                            }
                                        />
                                    </td>
                                    <td>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.fifteen.cookieName'
                                            }
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.nine.expiry'
                                            }
                                            values = {richTextFormatter(intl)}
                                        />
                                    </td>
                                    <td className = {'hidden-on-large'}>
                                        <FormattedMessage
                                            id = {
                                                'websitePrivacyNotice.useOfCookies.table.row.eight.description'
                                            }
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <StaticContent
                        page = {'websitePrivacyNotice'}
                        schema = {FINAL_BLOCK_OF_PAGE_CONTENT}
                    />
                </GridContainer>
            </section>
            <LandingPageFooter />
        </>
    );
}
