import {useAuth0} from '@auth0/auth0-react';
import classNames from 'classnames';
import {
    Redirect,
    Route,
    Switch,
    useLocation,
} from 'react-router-dom';
import {Loading} from './components';
import {
    FrequentlyAskedQuestions,
    LandingPage,
    StudyPrivacyNotice,
    WebsitePrivacyNotice,
    WebsiteTermsAndConditions,
} from './pages';

export function App() {
    const {isLoading} = useAuth0();
    const {pathname} = useLocation();

    // Show loading component while Auth0 is figuring out the credentials
    // Solves the issue of Auth0 flashing the landing page for a moment before redirection
    if (isLoading) {
        return <Loading withPageLayout = {true} />;
    }

    // Attempt to work out where we are, so we can apply a CSS class at a higher level. Get rid of the leading /, then
    // split the string on further slashes, then take the first array item...
    const [basePathname] = pathname.substring(1).split('/');

    // This `basePathname` appendage is so the css can tell which page we are on and decide which
    // layout to apply. This is useful for the pages that have the subnav. We can know ahead of time,
    // How much height to give the 'header' part of the grid with this information
    const pageLayoutClasses = classNames('page-layout', {[`${basePathname}-page`]: basePathname});

    return (
        <div className = {pageLayoutClasses}>
            <Switch>
                <Route
                    component = {LandingPage}
                    exact = {true}
                    path = {'/'}
                />
                <Route
                    component = {FrequentlyAskedQuestions}
                    path = {'/frequently-asked-questions'}
                />
                <Route
                    component = {StudyPrivacyNotice}
                    path = {'/study-privacy-notice'}
                />
                <Route
                    component = {WebsiteTermsAndConditions}
                    path = {'/website-terms-and-conditions'}
                />
                <Route
                    component = {WebsitePrivacyNotice}
                    path = {'/website-privacy-notice'}
                />
                <Route>
                    <Redirect
                        push = {true}
                        to = {{
                            pathname: '/',
                            state: {referrer: pathname},
                        }}
                    />
                </Route>
            </Switch>
        </div>
    );
}
