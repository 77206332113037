import classNames from 'classnames';
import React, {
    useCallback,
    useState,
} from 'react';
import Modal from 'react-modal';
import {Link} from 'react-router-dom';
import {Variant} from '../../constants';
import {Footer} from '../../footer';

export function NavMobile(props) {
    const {
        children,
        className,
        closeLinkAriaLabel = '',
        dataTestId,
        footerContent,
        openLinkAriaLabel = '',
        parentSelector,
        progressBar,
        variant = Variant.DARK,
    } = props;

    const [showMenu, setShowMenu] = useState(false);

    let sanitisedVariant = variant;
    if (![Variant.LIGHT, Variant.DARK, Variant.NONE].includes(variant)) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error('Nav only supports Variant.LIGHT, Variant.DARK or Variant.NONE.');
        }
        sanitisedVariant = Variant.DARK;
    }

    const onCloseHandler = useCallback(() => {
        setShowMenu(false);
    });

    const onClickHandler = useCallback(() => {
        setShowMenu((isShowing) => !isShowing);
    });

    // Mount Modal component to specified element or default to document.body if not available i.e in unit tests
    const modalParentSelector = useCallback(
        () => document.querySelector(parentSelector) || document.body
    );

    const dataTestIdSuffix = (isOpen) => (isOpen ? '-expanded' : '');

    const outerNavClasses = classNames('nav-header', sanitisedVariant, className);
    const innerNavClasses = classNames('nav', 'nav-mobile');
    const modalOverlayClasses = classNames('nav-header', 'nav-overlay', sanitisedVariant);

    const wordmark =
        sanitisedVariant === Variant.LIGHT ? 'genomics-wordmark.svg' : 'genomics-wordmark-white.svg';

    const navLeft = (isOpen) => {
        const imageSrc = isOpen ? `/images/${wordmark}` : '/images/genomics-icon.svg';

        return (
            <div
                className = {'nav-left'}
                data-test-id = {`nav-left${dataTestIdSuffix(isOpen)}`}
            >
                {progressBar && !isOpen ? (
                    progressBar
                ) : (
                    <Link
                        className = {'link'}
                        data-test-id = {`nav-home-link${dataTestIdSuffix(isOpen)}`}
                        to = {'/'}
                    >
                        <span>
                            {/* ::TODO:: Move alt message to messages bundle */}
                            <img
                                alt = {'Genomics'} // FIX THIS
                                className = {'nav-mobile-image'}
                                data-test-id = {'nav-home-link-image'}
                                src = {imageSrc}
                            />
                        </span>
                    </Link>
                )}
            </div>
        );
    };

    const navRight = (isOpen) => {
        const icon = isOpen ? 'close' : 'hamburger';

        const ariaLabel = isOpen ? closeLinkAriaLabel : openLinkAriaLabel;

        return (
            <div
                className = {`nav-right`}
                data-test-id = {`nav-right${dataTestIdSuffix(isOpen)}`}
            >
                <div
                    aria-label = {ariaLabel}
                    className = {`nav-mobile-icon icon ${icon} before`}
                    data-test-id = {`nav-menu-button${dataTestIdSuffix(isOpen)}`}
                    onClick = {onClickHandler}
                    role = {'button'}
                />
            </div>
        );
    };

    return (
        <nav
            className = {outerNavClasses}
            role = {'navigation'}
        >
            <div className = {'nav-width-controller'}>
                <div
                    className = {innerNavClasses}
                    data-test-id = {dataTestId}
                >
                    {navLeft(false)}
                    {navRight(false)}
                    <Modal
                        className = {'nav-width-controller nav-modal'}
                        contentLabel = {'Menu'}
                        isOpen = {showMenu}
                        onRequestClose = {onCloseHandler}
                        overlayClassName = {modalOverlayClasses}
                        parentSelector = {modalParentSelector}
                        shouldCloseOnEsc = {true}
                    >
                        <div
                            className = {innerNavClasses}
                            data-test-id = {`${dataTestId}-expanded`}
                        >
                            {navLeft(true)}
                            {navRight(true)}
                        </div>
                        <div
                            className = {'nav-menu page-content'}
                            data-test-id = {'nav-menu'}
                        >
                            {children}
                        </div>
                        <Footer
                            className = {'nav-footer'}
                            dataTestId = {'nav-footer'}
                        >
                            <div className = {'nav-footer-content'}>{footerContent}</div>
                        </Footer>
                    </Modal>
                </div>
            </div>
        </nav>
    );
}
