import {
    useCallback,
    useLayoutEffect,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {
    Link,
    Nav,
    Variant,
} from '../../../denim';
import {useTitle} from '../../common';
import {
    GridContainer,
    LandingPageFooter,
    LegalFooterSection,
    StaticContent,
} from '../../components';

const PAGE_CONTENT = [
    {
        paragraphs: ['one', 'two', 'three', 'four'],
        title: 'aboutOurWebsiteTerms',
    },
    {
        paragraphs: ['one'],
        title: 'aboutUs',
    },
    {
        paragraphs: ['one', 'two', 'three'],
        title: 'usingTheSite',
    },
    {
        paragraphs: ['one', 'two'],
        title: 'unacceptableUse',
    },
    {
        paragraphs: ['one', 'two', 'three', 'four'],
        title: 'registrationAndPasswordSecurity',
    },
    {
        paragraphs: ['one', 'two'],
        title: 'infringingContent',
    },
    {
        paragraphs: ['one', 'two'],
        title: 'privacyAndPersonalInformation',
    },
    {
        paragraphs: ['one', 'two', 'three', 'four', 'five'],
        title: 'intellectualPropertyRights',
    },
    {
        paragraphs: ['one', 'two'],
        title: 'submittingInformation',
    },
    {
        paragraphs: ['one', 'two', 'three', 'four'],
        title: 'accuracyOfInformationAndAvailability',
    },
    {
        paragraphs: ['one', 'two'],
        title: 'thirdPartySites',
    },
    {
        paragraphs: ['one'],
        title: 'limitationOnOurLiability',
    },
    {
        paragraphs: ['one'],
        title: 'eventsBeyondOurControl',
    },
    {
        paragraphs: ['one'],
        title: 'rightsOfThirdParties',
    },
    {
        paragraphs: ['one'],
        title: 'changingTheseTerms',
    },
    {
        paragraphs: ['one', 'two'],
        title: 'disputes',
    },
];

export function WebsiteTermsAndConditions() {
    const history = useHistory();
    const intl = useIntl();

    useLayoutEffect(() => {
        // When someone comes in via a link, they probably want to start at the top of the page, not halfway down...
        window.scrollTo(0, 0);
    }, []);

    useTitle(intl.formatMessage({id: 'websiteTermsAndConditions.documentTitle'}));

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        history.goBack();
    }, []);

    return (
        <>
            <header
                aria-label = {'Page header'}
                className = {'page-header'}
                role = {'banner'}
            >
                <Nav
                    closeLinkAriaLabel = {intl.formatMessage({id: 'common.close.label'})}
                    footerContent = {<LegalFooterSection />}
                    openLinkAriaLabel = {intl.formatMessage({id: 'common.open.label'})}
                    variant = {Variant.LIGHT}
                >
                    <ul role = {'menu'}>
                        <li
                            className = {'after icon launch'}
                            role = {'menuitem'}
                        >
                            <Link
                                target = {'_blank'}
                                url = {'/frequently-asked-questions'}
                            >
                                <FormattedMessage
                                    id = {'report.navigation.links.geneticCounseling.text'}
                                />
                            </Link>
                        </li>
                    </ul>
                </Nav>
            </header>
            <section
                className = {'page-content static-content'}
                role = {'main'}
            >
                <GridContainer containerClassName = {'content-section'}>
                    <div className = {'page-title'}>
                        <Link
                            before = {true}
                            icon = {'chevron--left'}
                            onClick = {onBackClickHandler}
                        >
                            <FormattedMessage id = {'staticPages.common.link.back.text'} />
                        </Link>
                        <h1>
                            <FormattedMessage id = {'websiteTermsAndConditions.pageTitle.text'} />
                        </h1>
                    </div>
                </GridContainer>
                <GridContainer
                    containerClassName = {'content-section'}
                    variant = {Variant.LIGHT}
                >
                    <StaticContent
                        page = {'websiteTermsAndConditions'}
                        schema = {PAGE_CONTENT}
                    />
                </GridContainer>
            </section>
            <LandingPageFooter />
        </>
    );
}
