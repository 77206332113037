export class Variant {
    static NONE = 'variant-none';

    static PRIMARY = 'variant-primary';

    static SECONDARY = 'variant-secondary';

    static LIGHT = 'variant-light';

    static DARK = 'variant-dark';
}
