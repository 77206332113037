import {useAuth0} from '@auth0/auth0-react';
import {APP_NAME} from './localStorageKeys';

/**
 * Get namespaced localStorage key, that is namespaced on the app name
 * and a unique user ID
 *
 * @param {String} key
 * @param {Object} user. The Auth0 user object
 *
 * @returns {String} with the shape <APP_NAME>.<USER_ID>.<KEY>
 */
export const getStorageKey = (key, user) => `${APP_NAME}:${user.sub}:${key}`;

export function useStore() {
    const {user} = useAuth0();

    const get = (key) => localStorage.getItem(getStorageKey(key, user));

    const set = (key, value) => localStorage.setItem(getStorageKey(key, user), value);

    const remove = (key) => localStorage.removeItem(getStorageKey(key, user));

    return {
        get,
        remove,
        set,
    };
}
