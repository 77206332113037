import classNames from 'classnames';

export function Footer(props) {
    const {
        ariaRole = true,
        children,
        className,
        dataTestId,
    } = props;

    const classes = classNames('footer page-footer', className);

    const ariaAttributes = {};
    if (ariaRole) {
        ariaAttributes.role = 'contentinfo';
    }

    return (
        <footer
            className = {classes}
            data-test-id = {dataTestId}
            {...ariaAttributes}
        >
            {children}
        </footer>
    );
}
