import {
    useCallback,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    useHistory,
    useRouteMatch,
} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import {
    Button,
    ErrorCard,
    Link,
    TextInput,
} from '../../../../denim';
import {useApi} from '../../../auth0';
import {action} from '../../../common';
import {Divider} from '../../../components';
import {useMinervaConfig} from '../../../config';

const HTTP_CONFLICT = 409;
const HTTP_NOT_FOUND = 404;
const UPDATE_SAMPLE_ID = 'UPDATE_SAMPLE_ID';
const SET_SAMPLE_ID_INPUT_ERROR_MESSAGE = 'SET_SAMPLE_ID_INPUT_ERROR_MESSAGE';
const SET_IS_LOADING = 'SET_IS_LOADING';
const SET_ERROR = 'SET_ERROR';

const errorTypes = {
    ALREADY_REGISTERED: 'ALREADY_REGISTERED',
    INVALID_SAMPLE_ID: 'INVALID_SAMPLE_ID',
    SAMPLE_ID_VALIDATION: 'SAMPLE_ID_VALIDATION',
};

const init = (sampleId) => ({
    errorType: null,
    isLoading: false,
    sampleId: sampleId,
    sampleIdInputErrorMessage: '',
});

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_SAMPLE_ID:
            return {
                ...state,
                sampleId: payload,
            };

        case SET_SAMPLE_ID_INPUT_ERROR_MESSAGE:
            return {
                ...state,
                sampleIdInputErrorMessage: payload,
            };

        case SET_ERROR:
            return {
                ...state,
                errorType: payload,
                isLoading: false,
            };

        case SET_IS_LOADING:
            return {
                ...state,
                errorType: null,
                isLoading: payload,
            };

        default:
            return state;
    }
};

const sampleIdFormatPattern = '^(FR|fr)?[0-9]{12}\\s*$';
const sampleIdFormatRegex = new RegExp(sampleIdFormatPattern);
export const sampleIdPrefix = 'FR';

export function SampleId(props) {
    const {kitRegistrationData: {sampleId: initialSampleId} = {}, updateKitRegistrationData} = props;

    const history = useHistory();
    const intl = useIntl();
    const {path} = useRouteMatch();

    const [authenticatedFetch] = useApi();
    const minervaConfig = useMinervaConfig();

    const [{sampleId, sampleIdInputErrorMessage, isLoading, errorType}, dispatch] = useReducer(
        reducer,
        initialSampleId,
        init
    );

    const validateSampleId = useCallback((value) => {
        let errorMessage = '';
        if (!value) {
            errorMessage = intl.formatMessage({id: 'registration.common.error.required.text'});
        }
        if (value && !sampleIdFormatRegex.test(value)) {
            errorMessage = intl.formatMessage({id: 'dashboard.registerYourKit.error.format.text'});
        }

        dispatch(action(SET_SAMPLE_ID_INPUT_ERROR_MESSAGE, errorMessage));
    }, []);

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        history.push(path, {registering: false});
    });

    const onChangeHandler = useCallback(
        (value) => {
            dispatch(action(UPDATE_SAMPLE_ID, value));
            updateKitRegistrationData({sampleId: value});

            if (sampleIdInputErrorMessage) {
                validateSampleId(value);
            }
        },
        [sampleIdInputErrorMessage]
    );

    const onSampleIdBlurHandler = useCallback((value) => {
        validateSampleId(value);
    }, []);

    const onSubmitHandler = useCallback(
        async (event) => {
            event.preventDefault();

            const form = event.target;
            const formValid = form.checkValidity();

            if (formValid) {
                dispatch(action(SET_IS_LOADING, true));

                try {
                    const DATE_PORTION_LENGTH = 10;
                    const [numericPart] = sampleId.match(/\d+/);

                    const response = await authenticatedFetch(
                        `${minervaConfig.servicesBackend}/validation/sample-id`,
                        {
                            body: JSON.stringify({
                                // eslint-disable-next-line camelcase
                                sample_collected: new Date()
                                    .toISOString()
                                    .slice(0, DATE_PORTION_LENGTH),
                                // eslint-disable-next-line camelcase
                                sample_id: `${sampleIdPrefix}${numericPart}`,
                            }),
                            method: 'POST',
                        }
                    );

                    if (response.ok) {
                        history.push(path, {
                            currentStep: 2,
                            registering: true,
                        });
                    }
                    else if (response.status === HTTP_NOT_FOUND) {
                        dispatch(action(SET_ERROR, errorTypes.INVALID_SAMPLE_ID));
                    }
                    else if (response.status === HTTP_CONFLICT) {
                        dispatch(action(SET_ERROR, errorTypes.ALREADY_REGISTERED));
                    }
                    else {
                        dispatch(action(SET_ERROR, errorTypes.SAMPLE_ID_VALIDATION));
                    }
                }
                catch (error) {
                    if (process.env.NODE_ENV === 'development') {
                        // eslint-disable-next-line no-console
                        console.log(error);
                    }
                    dispatch(action(SET_ERROR, errorTypes.SAMPLE_ID_VALIDATION));
                }
            }
            else {
                const {patternMismatch, valueMissing} = form.sampleId.validity;
                let errorMessage = '';

                if (patternMismatch) {
                    errorMessage = intl.formatMessage({id: 'dashboard.registerYourKit.error.format.text'});
                }
                else if (valueMissing) {
                    errorMessage = intl.formatMessage({id: 'registration.common.error.required.text'});
                }

                dispatch(action(SET_SAMPLE_ID_INPUT_ERROR_MESSAGE, errorMessage));
            }
        },
        [sampleId]
    );

    return (
        <>
            <div
                className = {'content'}
                data-test-id = {'register-your-kit'}
            >
                <Link
                    className = {'cancel-link icon before chevron--left'}
                    dataTestId = {'register-your-kit-cancel-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'dashboard.registerYourKit.link.back.text'} />
                </Link>
                <p
                    className = {'is-eyebrow is-caption no-margin'}
                    data-test-id = {'current-step-text'}
                >
                    <FormattedMessage
                        id = {'dashboard.registerYourKit.progress.text'}
                        values = {{
                            currentStep: 1,
                            totalSteps: 2,
                        }}
                    />
                </p>
                <h2 data-test-id = {'register-your-kit-default-content'}>
                    <FormattedMessage id = {'dashboard.registerYourKit.sampleId.title.text'} />
                </h2>
                <p>
                    <FormattedMessage id = {'dashboard.registerYourKit.sampleId.description.text'} />
                </p>
                <Divider />
                {errorType && (
                    <ErrorCard
                        dataTestId = {'register-your-kit-communication-error'}
                        title = {intl.formatMessage({id: `dashboard.registerYourKit.error.${errorType}.title`})}
                    >
                        <p>
                            <FormattedMessage
                                id = {`dashboard.registerYourKit.error.${errorType}.description`}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id = {`dashboard.registerYourKit.error.${errorType}.support`}
                                values = {{
                                    link: (chunks) => (
                                        <Link
                                            rel = {'noreferrer'}
                                            target = {'_blank'}
                                            url = {intl.formatMessage({id: 'support.email'})}
                                        >
                                            {chunks}
                                        </Link>
                                    ),
                                }}
                            />
                        </p>
                    </ErrorCard>
                )}
                <div className = {'text-based-input'}>
                    <form
                        noValidate = {true}
                        onSubmit = {onSubmitHandler}
                    >
                        <TextInput
                            dataTestId = {'register-your-kit-sample-id'}
                            errorMessage = {sampleIdInputErrorMessage}
                            id = {'sampleId'}
                            invalid = {sampleIdInputErrorMessage}
                            label = {intl.formatMessage({id: 'dashboard.registerYourKit.sampleId.label'})}
                            name = {'sampleId'}
                            onBlur = {onSampleIdBlurHandler}
                            onChange = {onChangeHandler}
                            pattern = {sampleIdFormatPattern}
                            placeholder = {intl.formatMessage({id: 'dashboard.registerYourKit.placeholder.text'})}
                            required = {true}
                            value = {sampleId}
                        />
                        <Button
                            dataTestId = {'register-your-kit-continue-button'}
                            isLoading = {isLoading}
                            submit = {true}
                        >
                            <FormattedMessage id = {'registration.common.button.continue.text'} />
                        </Button>
                        <HashLink
                            className = {'link is-heavy is-help-link'}
                            to = {'/frequently-asked-questions#why-register'}
                        >
                            <span>
                                <FormattedMessage id = {'dashboard.usingYourKit.link.text'} />
                            </span>
                        </HashLink>
                    </form>
                </div>
            </div>
            <div className = {'graphic'}>
                <img
                    alt = {intl.formatMessage({id: 'dashboard.registerYourKit.image.altText'})}
                    src = {'/images/test-kit-locate-sample-id.png'}
                />
            </div>
        </>
    );
}
