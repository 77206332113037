import {Redirect} from 'react-router';
import {PolicyholderState} from '../common';
import {Loading} from '../components';
import {usePolicyholderContext} from '../context';

export const getUserStatePath = (state) => {
    switch (state) {
        case PolicyholderState.REGISTERED:
            return {path: '/registration/study-consent'};

        case PolicyholderState.CONSENT_SIGNED:
            return {path: '/registration/about-you'};

        case PolicyholderState.DETAILS_SUBMITTED:
            return {
                path: '/registration/summary',
                step: 2,
            };

        case PolicyholderState.TEST_REQUESTED:
        // falls through

        case PolicyholderState.TEST_APPROVED:
            return {path: '/dashboard/physician-review'};

        case PolicyholderState.KIT_ORDERED:
        // falls through

        case PolicyholderState.KIT_SHIPPED:
            return {path: '/dashboard/kit-tracking'};

        case PolicyholderState.KIT_REGISTERED:
            return {path: '/dashboard/kit-registered'};

        case PolicyholderState.CAP_RETRIEVED:
            return {path: '/report'};

        // User's should always have a state matching one of the previous cases.
        default:
            return {path: '/contact-support'};
    }
};

export const userHasValidState =
    (Component, validStates, config = {}) =>
        (props) => {
            const {skipUserStateCheck, withPageLayout} = config;

            const {state: {policyholder, error} = {}} = usePolicyholderContext();

            const userState = policyholder.state;
            const userStatus = policyholder.status;

            if (skipUserStateCheck === true) {
                return <Component {...props} />;
            }

            if (error) {
                return (
                    <Redirect
                        push = {true}
                        to = {'/contact-support'}
                    />
                );
            }

            if (!userState || !userStatus) {
                return <Loading withPageLayout = {withPageLayout} />;
            }

            if (userStatus === 'INELIGIBLE') {
                return (
                    <Redirect
                        push = {true}
                        to = {{
                            pathname: '/registration/summary',
                            state: {
                                skipUserStateCheck: true,
                                step: 2,
                            },
                        }}
                    />
                );
            }

            if (validStates.includes(userState)) {
                return <Component {...props} />;
            }

            const {path, step} = getUserStatePath(userState);

            const redirectProps = {
                pathname: path,
                state: {
                    skipUserStateCheck: true,
                    step: step,
                },
            };

            return (
                <Redirect
                    push = {true}
                    to = {redirectProps}
                />
            );
        };
