import {
    useCallback,
    useState,
    useReducer,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Button,
    Checkbox,
    Checkboxes,
    Link,
} from '../../../../denim';
import {
    action,
    UPDATE_VALUE,
} from '../../../common';
import {Divider} from '../../../components';
import {useRegistrationContext} from '../RegistrationContext';

const reducer = (state, {type, payload}) => {
    switch (type) {
        case UPDATE_VALUE:
            return {
                ...state,
                ...payload,
            };

        default:
            return state;
    }
};

export function HeartCondition() {
    const intl = useIntl();
    const {
        nextStep,
        previousStep,
        state: {data: {medicalHistory} = {}} = {},
        updateMedicalHistoryField,
    } = useRegistrationContext();

    const [heartCondition, dispatch] = useReducer(reducer, medicalHistory.heartCondition);

    const {
        angioplasty,
        coronaryArteryBypassSurgery,
        coronaryArteryDisease,
        heartAttack,
        ldl,
        noneOfTheAbove,
        preferNotToSay,
        stent,
        stroke,
        unknown,
    } = heartCondition;

    const [errorMessage, setErrorMessage] = useState(null);

    const onBackClickHandler = useCallback((event) => {
        event.preventDefault();
        previousStep();
    }, []);

    const onChangeHandler = useCallback(
        (value, name) => {
            const checkboxValue = Boolean(value);
            let payload = {};

            if (
                [
                    'angioplasty',
                    'coronaryArteryBypassSurgery',
                    'coronaryArteryDisease',
                    'heartAttack',
                    'ldl',
                    'stent',
                    'stroke',
                ].includes(name)
            ) {
                payload = {
                    ...heartCondition,
                    [name]: checkboxValue,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    unknown: false,
                };
            }
            else {
                const initialValues = {
                    angioplasty: false,
                    coronaryArteryBypassSurgery: false,
                    coronaryArteryDisease: false,
                    heartAttack: false,
                    ldl: false,
                    noneOfTheAbove: false,
                    preferNotToSay: false,
                    stent: false,
                    stroke: false,
                    unknown: false,
                };

                payload = {
                    ...initialValues,
                    [name]: checkboxValue,
                };
            }
            dispatch(action(UPDATE_VALUE, payload));

            setErrorMessage(null);
        },
        [heartCondition]
    );

    const onSubmitHandler = useCallback(
        (event) => {
            event.preventDefault();

            // Check validity cause an invalid event to be triggered on anything that is invalid, which will cause them
            // to display their error state.
            const form = event.target;
            const formValid = form.checkValidity();

            // Check at least one checkbox has been selected.
            const isChecked = document.querySelectorAll('input[type=checkbox]:checked').length > 0;

            // If the form if valid, transition to the next step...
            if (formValid && isChecked) {
                updateMedicalHistoryField('heartCondition', heartCondition);
                nextStep();
            }
            else {
                setErrorMessage(
                    intl.formatMessage({id: 'registration.common.error.choose-at-least-one.text'})
                );
            }
        },
        [heartCondition]
    );

    const conditionalRevealPreviouslyHad = intl.formatMessage({id: 'registration.medicalHistory.heartCondition.previouslyHad.conditionalReveal.text'});
    const conditionalRevealPreferNotToSay = intl.formatMessage({id: 'registration.medicalHistory.heartCondition.preferNotToSay.conditionalReveal.text'});

    return (
        <div className = {'content'}>
            <div className = {'question-container'}>
                <Link
                    className = {'back-link icon before chevron--left'}
                    dataTestId = {'back-link'}
                    onClick = {onBackClickHandler}
                    url = {'#'}
                >
                    <FormattedMessage id = {'registration.common.link.back.text'} />
                </Link>
                <h2 className = {'question'}>
                    <FormattedMessage id = {'registration.medicalHistory.heartCondition.title.text'} />
                </h2>
            </div>
            <Divider />
            <div className = {'question-options'}>
                <form
                    noValidate = {true}
                    onSubmit = {onSubmitHandler}
                >
                    <Checkboxes
                        errorMessage = {errorMessage}
                        label = {intl.formatMessage({id: 'registration.common.multiOption.instruction.text'})}
                    >
                        <Checkbox
                            checked = {heartAttack}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'heart-condition-heart-attack'}
                            id = {'heart-condition-checkbox-heart-attack'}
                            key = {'heart-condition-checkbox-heart-attack'}
                            label = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.heartAttack.text'})}
                            name = {'heartAttack'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {stroke}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'heart-condition-stroke'}
                            id = {'heart-condition-checkbox-stroke'}
                            key = {'heart-condition-checkbox-stroke'}
                            label = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.stroke.text'})}
                            name = {'stroke'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {ldl}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'heart-condition-untreated-ldl'}
                            description = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.ldl.description.text'})}
                            id = {'heart-condition-checkbox-untreated-ldl'}
                            key = {'heart-condition-checkbox-untreated-ldl'}
                            label = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.ldl.text'})}
                            name = {'ldl'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {coronaryArteryDisease}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'heart-condition-coronary-artery-disease'}
                            description = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.coronaryArteryDisease.description.text'})}
                            id = {'heart-condition-checkbox-coronary-artery-disease'}
                            key = {'heart-condition-checkbox-coronary-artery-disease'}
                            label = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.coronaryArteryDisease.text'})}
                            name = {'coronaryArteryDisease'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {coronaryArteryBypassSurgery}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'heart-condition-coronary-artery-bypass-surgery'}
                            description = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.coronaryArteryBypassSurgery.description.text'})}
                            id = {'heart-condition-checkbox-coronary-artery-bypass-surgery'}
                            key = {'heart-condition-checkbox-coronary-artery-bypass-surgery'}
                            label = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.coronaryArteryBypassSurgery.text'})}
                            name = {'coronaryArteryBypassSurgery'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {stent}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'heart-condition-stent'}
                            description = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.stent.description.text'})}
                            id = {'heart-condition-checkbox-stent'}
                            key = {'heart-condition-checkbox-stent'}
                            label = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.stent.text'})}
                            name = {'stent'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {angioplasty}
                            conditionalReveal = {conditionalRevealPreviouslyHad}
                            dataTestId = {'heart-condition-angioplasty'}
                            description = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.angioplasty.description.text'})}
                            id = {'heart-condition-checkbox-angioplasty'}
                            key = {'heart-condition-checkbox-angioplasty'}
                            label = {intl.formatMessage({id: 'registration.medicalHistory.heartCondition.checkbox.angioplasty.text'})}
                            name = {'angioplasty'}
                            onChange = {onChangeHandler}
                        />
                        <div className = {'is-caption option-divider'}>
                            <FormattedMessage id = {'common.or.text'} />
                        </div>
                        <Checkbox
                            checked = {noneOfTheAbove}
                            dataTestId = {'heart-condition-none-of-the-above'}
                            id = {'heart-condition-checkbox-none-of-the-above'}
                            key = {'heart-condition-checkbox-none-of-the-above'}
                            label = {intl.formatMessage({id: 'registration.common.option.noneOfTheAbove.text'})}
                            name = {'noneOfTheAbove'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {unknown}
                            dataTestId = {'heart-condition-dont-know'}
                            id = {'heart-condition-checkbox-unknown'}
                            key = {'heart-condition-checkbox-unknown'}
                            label = {intl.formatMessage({id: 'registration.common.option.unknown.text'})}
                            name = {'unknown'}
                            onChange = {onChangeHandler}
                        />
                        <Checkbox
                            checked = {preferNotToSay}
                            conditionalReveal = {conditionalRevealPreferNotToSay}
                            dataTestId = {'heart-condition-prefer-not-to-say'}
                            id = {'heart-condition-checkbox-prefer-not-to-say'}
                            key = {'heart-condition-checkbox-prefer-not-to-say'}
                            label = {intl.formatMessage({id: 'registration.common.option.prefer-not-to-say.text'})}
                            name = {'preferNotToSay'}
                            onChange = {onChangeHandler}
                        />
                    </Checkboxes>

                    <Button
                        dataTestId = {'continue-button'}
                        submit = {true}
                    >
                        <FormattedMessage id = {'registration.common.button.continue.text'} />
                    </Button>
                </form>
            </div>
        </div>
    );
}
