import classNames from 'classnames';
import {useCallback} from 'react';
import {Spinner} from './Spinner';

export function Button(props) {
    const {
        before = false,
        children,
        className,
        dataTestId,
        disabled = false,
        icon,
        isLoading = false,
        onClick,
        primary = true,
        reset = false,
        submit = false,
    } = props;

    const classes = classNames('button', 'is-heavy', {
        after: !isLoading && icon ? !before : null,
        before: !isLoading && icon ? before : null,
        [className]: className,
        icon: !isLoading && icon,
        [icon]: !isLoading && icon,
        'is-loading': isLoading,
        primary: primary,
    });

    const onClickHandler = useCallback(
        (event) => {
            if (!isLoading && onClick) {
                onClick(event);
            }
        },
        [isLoading, onClick]
    );

    return (
        <button
            className = {classes}
            data-test-id = {dataTestId}
            disabled = {disabled}
            onClick = {onClickHandler}
            type = {submit ? 'submit' : reset ? 'reset' : 'button'}
        >
            {isLoading ? (
                <>
                    <span className = {'label'}>{children}</span>
                    <Spinner primary = {primary} />
                </>
            ) : (
                children
            )}
        </button>
    );
}
