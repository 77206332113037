/**
 * Given attributes for an input element, returns the constraint validations that are applicable to it.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/Guide/HTML/HTML5/Constraint_validation
 */
function getConstraintValidationsForType(props) {
    const {type = 'text', ...otherProps} = props ?? {};

    const constraintValidations = ['badInput'];

    // Semantic input types
    if (['email', 'url'].includes(type)) {
        constraintValidations.push('typeMismatch');
    }

    // Validation-related attributes
    const propKeys = Object.keys(otherProps);

    if (['text', 'search', 'url', 'tel', 'email', 'password'].includes(type)) {
        if (propKeys.includes('pattern')) {
            constraintValidations.push('patternMismatch');
        }

        if (propKeys.includes('minLength')) {
            constraintValidations.push('tooShort');
        }

        if (propKeys.includes('maxLength')) {
            constraintValidations.push('tooLong');
        }
    }

    if (['range', 'number', 'date', 'month', 'week,', 'datetime-local', 'time'].includes(type)) {
        if (propKeys.includes('min')) {
            constraintValidations.push('rangeUnderflow');
        }

        if (propKeys.includes('max')) {
            constraintValidations.push('rangeOverflow');
        }
    }

    if (
        [
            'text',
            'search',
            'url',
            'tel',
            'email',
            'password',
            'date',
            'datetime-local',
            'month',
            'week',
            'time',
            'number',
            'checkbox',
            'radio',
            'file',
        ].includes(type) &&
        propKeys.includes('required')
    ) {
        constraintValidations.push('valueMissing');
    }

    if (
        ['date', 'month', 'week', 'datetime-local', 'time', 'range', 'number'].includes(type) &&
        propKeys.includes('step')
    ) {
        constraintValidations.push('stepMismatch');
    }

    return constraintValidations;
}

const commonAttributes = [
    'autoFocus',
    'className',
    'disabled',
    'form',
    'id',
    'inputMode',
    'name',
    'readOnly',
    'required',
    'tabIndex',
    'title',
    'type',
    'value',
];

const attributesForType = {
    checkbox: [...commonAttributes, 'checked'],
    email: [...commonAttributes, 'autoComplete', 'list', 'multiple', 'size'],
    password: [
        ...commonAttributes,
        'autoComplete',
        'maxLength',
        'minLength',
        'pattern',
        'placeholder',
        'size',
    ],
    radio: [...commonAttributes, 'checked'],
    search: [
        ...commonAttributes,
        'autoComplete',
        'dirname',
        'list',
        'maxLength',
        'minLength',
        'placeholder',
    ],
    tel: [
        ...commonAttributes,
        'autoComplete',
        'list',
        'maxLength',
        'minLength',
        'pattern',
        'placeholder',
        'size',
    ],
    text: [
        ...commonAttributes,
        'autoComplete',
        'dirname',
        'list',
        'maxLength',
        'minLength',
        'pattern',
        'placeholder',
        'size',
    ],
    url: [...commonAttributes, 'autoComplete', 'list', 'maxLength', 'minLength', 'placeholder'],
};

const defaultPropsForType = {
    checkbox: {inputMode: 'none'},
    email: {
        autoComplete: 'email',
        inputMode: 'email',
    },
    password: {
        autoComplete: 'current-password',
        inputMode: 'text',
    },
    radio: {inputMode: 'none'},
    search: {
        autoComplete: 'on',
        inputMode: 'search',
    },
    tel: {
        autoComplete: 'tel',
        inputMode: 'tel',
    },
    text: {
        autoComplete: 'on',
        inputMode: 'text',
    },
    url: {
        autoComplete: 'url',
        inputMode: 'url',
    },
};

/**
 * Weeds out superfluous props from text based input fields. Only selects the valid attributes listed on MDN for each
 * of the supported types.
 */
function filterInputPropsForType(props) {
    const {type = 'text', ...otherProps} = props;

    if (!['text', 'email', 'password', 'search', 'tel', 'url', 'checkbox', 'radio'].includes(type)) {
        throw new Error(`Unsupported type - ${type}`);
    }

    const filteredProps = Object.keys(otherProps)
        .filter((key) => attributesForType[type].includes(key))
        .reduce(
            (memo, key) => ({
                ...memo,
                [key]: otherProps[key],
            }),
            {}
        );

    return {
        ...defaultPropsForType[type],
        ...filteredProps,
        type,
    };
}

export {filterInputPropsForType, getConstraintValidationsForType};
