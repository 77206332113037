import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    Link,
    Nav,
    Variant,
} from '../../../denim';
import {useTitle} from '../../common';
import {
    LandingPageFooter,
    LegalFooterSection,
} from '../../components';
import {StudyClosedHero} from './StudyClosedHero';

export function LandingPage() {
    const intl = useIntl();

    useTitle(intl.formatMessage({id: `landingPage.documentTitle`}));

    return (
        <>
            <div
                className = {'page-header'}
                role = {'banner'}
            >
                <Nav
                    closeLinkAriaLabel = {intl.formatMessage({id: 'common.close.label'})}
                    footerContent = {<LegalFooterSection />}
                    openLinkAriaLabel = {intl.formatMessage({id: 'common.open.label'})}
                    variant = {Variant.LIGHT}
                >
                    <ul role = {'menu'}>
                        <li
                            className = {'after icon launch'}
                            role = {'menuitem'}
                        >
                            <Link
                                target = {'_blank'}
                                url = {'/frequently-asked-questions'}
                            >
                                <FormattedMessage
                                    id = {'report.navigation.links.geneticCounseling.text'}
                                />
                            </Link>
                        </li>
                    </ul>
                </Nav>
            </div>
            <div
                className = {'page-content landing-page'}
                role = {'main'}
            >
                <StudyClosedHero />
            </div>
            <LandingPageFooter />
        </>
    );
}
