import classNames from 'classnames';
import React from 'react';
import {Link} from 'react-router-dom';
import {Variant} from '../../constants';

export function NavDesktop(props) {
    const {
        children,
        className,
        variant = Variant.NONE,
        dataTestId,
    } = props;

    let menuContent = children;
    let subNavContent = null;

    let sanitisedVariant = variant;
    if (![Variant.LIGHT, Variant.DARK, Variant.NONE].includes(variant)) {
        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error('Nav only supports Variant.LIGHT, Variant.DARK or Variant.NONE.');
        }
        sanitisedVariant = Variant.NONE;
    }

    const outerNavClasses = classNames('nav-header', sanitisedVariant, className);
    const innerNavClasses = classNames('nav');

    const wordmark =
        variant === Variant.LIGHT ? 'genomics-wordmark.svg' : 'genomics-wordmark-white.svg';

    if (React.Children.count(children) > 1) {
        [subNavContent, menuContent] = children;
    }

    return (
        <nav
            className = {outerNavClasses}
            data-test-id = {`${dataTestId}-outer`}
            role = {'navigation'}
        >
            <div className = {'navbar'}>
                <div className = {'nav-width-controller'}>
                    <div
                        className = {innerNavClasses}
                        data-test-id = {dataTestId}
                    >
                        <div className = {'nav-left'}>
                            <Link
                                className = {'link'}
                                data-test-id = {'nav-home-link'}
                                to = {'/'}
                            >
                                <span>
                                    {/* ::TODO:: Move alt message to messages bundle */}
                                    <img
                                        alt = {'Genomics'} // FIX TIHS
                                        data-test-id = {'nav-home-link-image'}
                                        src = {`/images/${wordmark}`}
                                    />
                                </span>
                            </Link>
                        </div>
                        <div
                            className = {'nav-right'}
                            data-test-id = {'nav-menu'}
                        >
                            {menuContent}
                        </div>
                    </div>
                </div>
            </div>
            {subNavContent && (
                <div className = {'navbar'}>
                    <div className = {'nav-width-controller sub-nav'}>
                        <div className = {'nav'}>{subNavContent}</div>
                    </div>
                </div>
            )}
        </nav>
    );
}
