import {Component} from 'react';
import {ErrorNotice} from '../../components';

export class ErrorBoundary extends Component {
    state = {hasError: false};

    static getDerivedStateFromError(_error) {
        // Update the local state so that the fallback UI is displayed on the next render.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        if (process.env.NODE_ENV === 'development') {
            /* eslint-disable no-console */
            console.error(error);
            console.dir(errorInfo);
            /* eslint-enable no-console */
        }
    }

    render() {
        const {children} = this.props;
        const {hasError} = this.state;

        if (hasError) {
            return (
                <div className = {'page-layout'}>
                    <ErrorNotice fullPage = {true} />
                </div>
            );
        }

        return children;
    }
}
